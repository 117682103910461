

.allFriends{

width: 93%;
border-style: none;
margin: auto;
display: grid;
grid-template-columns: repeat(auto-fit, minmax(200px, 1fr) );
grid-template-rows: 1fr ;
grid-column-gap: 50px;
grid-row-gap: 50px;
margin-top: 20px;

}



.searchBar_contact{
width: 80%;
height: 40px;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
margin: auto;
display: flex;
align-items: center;
}

.searchIcon{
margin-left: 15px;
font-size: 25px;
color: grey;
}
