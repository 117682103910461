

.oneFriend{


width: 100%;
height: 120px;
border-style: none;
display: flex;
align-items: center;
margin-top: 30px;
}


.friendImage{

width:100px;
height:100px; 
margin: auto;
border-radius: 50%;
float: left;
border-style: none;
}

.InfoBox{
height: 100%;
width: 100%;
color: grey;
border-style: none;
margin-left: 30px;
}

.fullName{


}


.addContactButton{
color: white;
background-color: var(--themeColor);
border-style: none;
border-radius: 3px;
padding: 3px;
cursor: pointer;
}


