.IntroductionBlockParent {
  border-style: none;
}

.websiteContent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  row-gap: 30px;
  column-gap: 30px;
  border-style: none;

  /* border: 1px solid red; */
}

.detailContainer {
  display: grid;
  /* flex-direction: column; */
  /* align-items: flex-end; */
  /* justify-content: start; */
  order: 2;

}

.HomeImageContainer1 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  
}

.HomeImageContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  object-fit: cover;
  border-style: none;
  width: 45%;
  height: 100%;
  margin-right: 100px;
  /* aspect-ratio: 1/1; */
}

.btnContainer {
  margin-right: 40px;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 20px;
}

.btn1 {
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.btn1:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.btn2 {
  margin-top: 16px;
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.btn2:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.btn3 {
  margin-top: 16px;
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
  white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.btn3:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.btn4 {
  font-weight: bold;
  margin-top: 16px;
  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.btn4:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.detailContainer1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: end; */
  /* position: absolute; */
}

.odisaMap {
  width: 100%;
}

.textHeading1 {
  padding-top: 90px;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 100px;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase; */
  background-color: transparent;
  fill: var(--themeColor);
}

.textHeading2 {
  width: 78%;
  /* color: var(--themeColor); */
  color: #544a4d;
  margin-top: 50px;
  /* font-weight: bold; */
  line-height: 55px;
  font-size: 35px;
  padding: 10px;
  border-radius: 10px;
  
  /* text-align: center; */
  /* margin-right: 100px; */
}
.textHeadingTitle{
  /* font-size: 35px; */
  color: #0cc0df;
  font-size: 40px;
  font-weight: 700;
  
}
.textDetails {
  visibility: hidden;
  margin-top: 30px;
  color: var(--darkText);
  font-size: 18px;
  text-align: justify;
  line-height: 34px;
  font-weight: bolder;
  background-color: #e7efeb;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media (max-width: 1300px) {
  .websiteContent{
    grid-template-rows: 1fr  ;
    /* gap: 100px; */
    /* padding-left: 60px;  */
  }
  
  .IntroductionBlockParent {
    width: 100%;
  }
  .textHeading1 {
    font-size: 20;
    height: 80px;
    width: 80px;
  }
  .textHeading2 {
    width: 90%;
    /* margin-top: 200px; */
    font-size: 30px;
    line-height: 40px;
    margin-right: 0;
  }
  .detailContainer{
    /* margin-left: 80px; */
  }

  .textDetails {
    margin-top: 10px;
    font-size: 18px;
    line-height: 30px;
  }
  .HomeImageContainer1 {
    /* margin-top: 200px; */
    /* padding-left: 40px; */
  }

  .btnContainer {
    width: 100%;
    margin: 0;
  }

  .btn1 {
    font-size: 12px;
  }
  .btn2 {
    font-size: 12px;
  }
  .btn3 {
    font-size: 12px;
  }
  .btn4 {
    font-size: 12px;
  }
}

@media (max-width: 700px){
  .HomeImageContainer1 {
    /* margin-top: 200px; */
    justify-content: center;
    /* padding-left: 20px; */
    
  }
  .textHeading2 {
    width: 90%;
    /* margin-top: 200px; */
    font-size: 30px;
    line-height: 40px;
  }
  .websiteContent{
    grid-template-rows: 1fr 1fr; 

  }
  .websiteContent > div:first-child {
    grid-row: 2; 
  }
  .textHeading2{
    margin-top: 0;
  }
  .detailContainer{
    height: 30%;
    /* margin-top: 50px; */
    /* margin-left: 0; */
  }
  .detailContainer1{
    justify-content: center;
    align-items: start;
    padding-left: 45px;
    margin-top: 30px;
  }
  .websiteContent{
    height: 700px;
    gap: 0;
  }
  .HomeImageContainer{
    margin-right: 0;
    /* width: 70%; */
    
  }
  
}


@media (max-width: 450px){

  .HomeImageContainer{
    width: 70%;
  }
  .websiteContent{
    height: 750px;
  }

}