

.userProfileContentGrades{
width: 100%;
height: calc( 100% - calc( 0.6 * var(--headerHeight) ) );

}


.userProfileContentGrades i{

font-style: normal;
color: grey;
margin-top: 100px;
font-size: 20px;

}


.friendsContainer{
width: 100%;
height: 200px;
background-color: red;
}

