.websiteContent2 {
  margin-top: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  row-gap: 10px;
  column-gap: 20px;
}
.titleBlog{
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0cc0df;
  font-size: 24px;
  font-weight: bold;
  /* font-weight: 700; */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.HomeImageContainer2 {
  width: 100%;
  height: 100%;
  /* background-color: green; */
  border-radius: 10px;
  object-fit: cover;

}

.detailContainer2 {
  width: 100%;
}

.aboutHeading1 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.aboutHeading2 {
  /* margin-top: 20px; */
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.aboutDetails {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}


.rightImgLeftTxt{
  width: 100%;
  display: flex;
  gap: 30px;
}

.txtDiv{
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  text-align: justify;
}
.imageContainer{
  width: 30%;
  height: 640px;
}

.heading{
  font-weight: bold;
}

.list{
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.title{
  text-align: center;
  margin-top: 70px;
  color: #0cc0df;
  font-size: 30px;
  font-weight: bolder;
}
@media (max-width: 1300px){
  .rightImgLeftTxt{
    flex-direction: column-reverse;
  }

  .txtDiv{
    width: 96%;
    padding-left: 10px;
    /* padding-right: 10px; */
  }
  .imageContainer{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .HomeImageContainer2{
    width: 60%;
  }
}

@media (max-width: 1300px) {
  .detailContainer2 {
  }
  .aboutHeading1 {
    font-size: 20;
  }
  .aboutHeading2 {
    margin-top: 10px;
    font-size: 30px;
    line-height: 40px;
  }

  .aboutDetails {
    margin-top: 10px;
    font-size: 18px;
    line-height: 30px;
  }
  .websiteContent2{
    /* padding: 15px; */
    
  }
}







@media (max-width: 700px){
  .detailContainer2{
    width: 95%;
    padding-left: 15px;
  }
}


@media (max-width: 600px){
  .HomeImageContainer2{
    width: 90%;
  }
}

@media (max-width: 600px){
  .HomeImageContainer2{
    width: 100%;
  }
  .imageContainer{
    width: 100%;
  }
}