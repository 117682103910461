.rulesAndRegulationsParent {
  margin-top: 40px;

  width: 100%;
}

.serchContainer {
  background-color: var(--bgColor);
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
}

.serchbox {
  margin-left: 10px;
  width: 90%;

  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: none;
  border: 1px solid var(--cardBorderColorLight);
}

.serchbox:focus {
  border-style: solid;
  background-color: white;
  border-width: 2px;
  outline: none !important;
  border-color: var(--themeColor);
}

.btnContainer {
  width: 100px;
  margin-left: 20px;
  height: 28px;
  background-color: var(--themeColor);
  border: none;
  border-radius: 8px;
  color: white;
  margin-right: 10px;
}

.btnContainer:hover {
  cursor: pointer;
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
}

.parentDiv {
  margin-top: 20px;
  display: flex;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, auto));
  row-gap: 20px;
  column-gap: 20px;
}

.leftBox {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.topHeadingSection {
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: var(--bgColor);
  border-bottom: 1px solid var(--cardBorderColor);
}

.iconContainer {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
}

.title {
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
}

.rightBoxContainer {
}

.topBox {
  width: 100%;

  border: 1px solid var(--cardBorderColor);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.midBox {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.bottomBox {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.mainContainer {
}

.mainIcon {
}

.noticeIcon {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.regIcon {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ruleIcon {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actIcon {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rulesAndRegulationsParent {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  row-gap: 20px;
  column-gap: 20px;
}

.reraData {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  row-gap: 20px;
  column-gap: 20px;
}

.Block1 {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.Title {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--themeColor);
  background-color: var(--themeColor);
  font-weight: bold;
  color: white;
}

.mainContainer {
  padding-top: 10px;
  display: flex;
  padding-right: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--cardBorderColorLight);
}

.mainContainer:hover {
  background-color: var(--bgColor);
  cursor: pointer;
}

.nu {
  display: flex;
  justify-content: center;
  width: 15%;
  font-size: 14px;
}

.details {
  width: 85%;
  font-size: 14px;
  line-height: 24px;
}

.Block2 {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.Block3 {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.Block4 {
  height: 500px;
  border: 1px solid var(--themeColor);
}


.mainInnerDiv{
  width: 100%;
  margin-bottom: 30px;
   margin-top: 40px;
   
}
.mainSecndInnerDiv{
  width: 100%;
  display: flex;
  min-height: 700px;
  height: auto;
  gap: 30px;
  background-color: #eff6ff;
  /* padding: 15px; */
  /* padding-right: 20px; */
  padding-top: 0;
  padding-bottom: 0;
  
  /* grid-template-columns: 0.8fr 1fr;  */
  /* gap: 20px; */
}

.mainThirdDiv{
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;
  gap: 10px;
}
.mainFourthDiv{
  height: auto;
}
.bottomTxt{
  height: 100px;
  background-color: #eff6ff;
  display: flex ;
  flex-direction: column;
  font-size: 21px;
  font-weight: 800;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.titleBottom{
  font-size: 22px;
  margin-bottom: 20px;
}
.bottomBtn{
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: auto;
  margin-top: 10px;
  /* margin-bottom: 10px; */
  font-weight: bold;
}
.btnSchedule{
  height: 70px;
  border-style: none;
  color: white;
  width: 350px;
  border-radius: 10px;
  background-color: #0cc0df;
  font-size: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.btnSchedule:hover{
  background-color: #fff;
  border:1px solid #0cc0df;
  color: #0cc0df;
}
.titleBottomTxt{
  font-size: 16px;
  font-weight: 500;
}

.firstDiv ,.secondDiv ,.thirdDiv ,.fourthDiv{
  /* height: 30%; */
  text-align: justify;
  display: grid;
  width: 96%;
  

  
}

.titleTxt{
  font-size: 20px;
  font-weight: bolder;
}

.rightDiv{
  width: 70%;
  display: grid;
 
}


.titleDiv{
  
  height: 100px;
  background-color: #eff6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold
}
.contentDiv{
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
}
.contentDivText{
  font-size: 19px;
  font-weight: 900;
}
.contentDivTextBottom{
  font-size: 18px;
  font-weight: 500;
}

.surgeryImgContainer{
  width: 40%;
  /* height: 100%; */
}
.surgeryImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 13px; */
}
.titleTxt1{
  font-size: 21px;
  font-weight: 600;
}
@media (max-width: 1300px) {
  .rightDiv{
    width: 60%;
  }
  .surgeryImgContainer{
    margin-left: 10px;
  }

  .reraData{

    width: 100%;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  row-gap: 20px;
  column-gap: 20px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

  .Block1{
width: 94%;

  }

  .Block2{
    width: 94%;

  }

  .Block3{
    width: 94%;

  }

  .Block4{
    width: 94%;

  }
  .bottomBtn{
    /* margin-top: 30px; */
    /* margin-bottom: 30px; */
  }
}


.inconstructionMessage{
margin-top: 40px;
color: red;
font-size: 25px;
border-style: solid;
padding: 20px;
border-radius: 10px;
}

@media (max-width: 1000px){
  .surgeryImgContainer5{
    width: 50%;
  }
  .bottomTxt{
    padding-right: 20px;
    padding-left: 20px;
  }
}


@media (max-width: 700px){
  .mainSecndInnerDiv{
    flex-direction: column;
  }
  .surgeryImgContainer{
    width: 100%;
    margin: 0;
  }
  .rightDiv{
    width: 97%;
    padding-left: 15px;
  }
  .contentDiv{
    height: auto;
    margin-bottom: 23px;
    margin-top: 30px;padding: 5px;
  }
  .mainThirdDiv{
    height: auto;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .bottomTxt{
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .fourthDiv{
    margin-bottom: 20px;
  }

  .bottomBtn{
    /* height: 200px; */
    /* margin-top: 20px; */
    /* margin-bottom: 20px; */
  }
  .btnSchedule{
    /* height: 20%; */
    /* margin-bottom: 20px; */
  }
}






