.centerImageLeftRightGrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  padding-top: 3px;
  /* color: blue; */
  font-weight: bolder;
  color: #0cc0df;
  font-size: 30px;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  /* text-transform: uppercase; */
  display: flex;
  align-items: end;
}

.subTitle {
  /* margin-top: 20px; */
  /* font-weight: bold; */
  color: #544a4d;
  /* line-height: 55px; */
  font-size: 30px;
  display: flex;
  align-items: end;
}

.titleContainer{
  display: flex;
  gap: 10px;
  margin-top: 55px;
}

.details {
  margin-top: 40px;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--lightText);
  text-align: justify;
  margin-bottom: 30px;
  padding-right: 10px;
  padding-left: 10px;

}
.knowMoreBtn{
  color: #0cc0df;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.knowMoreBtnoptions{
  color: #0cc0df;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.serviceSection {
  margin-top: 20px;
  padding-left: 10px;
  background-color: #eff6ff;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  row-gap: 10px;
  column-gap: 10px;
  padding-bottom: 30px;
}

.leftSideContent {
  width: 100%;

}
.justifyTxt{
  text-align: justify;
  padding-right: 10px;
}

.treatment{
  color: #0cc0df;
  font-size: 30px;
  font-weight: bolder;
}
.treatmentHeading{
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  gap: 8px;
  color: #544a4d;
  margin-top: 20px;
}
.imagesTextContainer{
  display: flex;
  gap: 30px;
  width: 100%;
}
.imagesContainer{
  width: 45%;
  padding-left: 15px;
}
.txtContainer{
  width: 50%;
}
.topImg ,.bottomImg{
  width: 100%;
  display: flex;
  gap: 8px;
  /* flex-direction: column; */
}

.firstImgDiv ,.secondImgDiv ,.thirdImgDiv ,.fourthImgDiv{
  width: 50%;
}

.HomeImageContainer{
  width: 100%;
}






.features {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--themeColor);
  font-weight: bold;
}

/* .a1box {
  max-height: 2.4em;
  overflow: hidden;
  transition: max-height 0.3s ease; 
}

.a1box:hover {
  max-height: none; 
} */

.serviceImage {
  height: 60px;
  width: 60px;
  background-color: white;
  visibility: hidden;
}

.a1box {
  height: auto;
  margin: 12px;
  padding: 8px;
  border: 1px solid var(--cardBorderColor);
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  overflow: hidden;
}

.serviceHeading1 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  font-weight: bold;
  color: #08203d;
}
.serviceHeading3{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  font-weight: bold;
  color: #08203d;

}

.serviceHeading2 {
  margin-top: 6px;
  font-size: 14px;
  color: var(--lightText);
  line-height: 23px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  transition: max-height 0.3s ease;

}

.serviceHeading2:hover {
  height: auto;
  max-height: none;
  cursor: pointer;
}


.a1box:hover .serviceHeading2{
  -webkit-line-clamp: 8;
  transition: max-height 0.3s ease;
}

.centerImage {
  margin-top: 35px;
  width: 100%;
  height: 620px;
  min-height: 620px;
  max-height: 620px;
  background-color: white;
  object-fit: cover;
  border-style: none;
  border-radius: 10px;
  display: flex;
  margin-bottom: 40px;


}

.rightSideContent {
  width: 100%;

}
li{
  margin-bottom: 7px;
}
.middleImgContainer{
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
@media (max-width: 1300px) {
  

  .subTitle {
    margin-top: 10px;
    font-size: 30px;
  }
  .a1box {
    width: auto;
    padding: 10px;
    margin-top: 10px;
  }

  .centerImage {
    width: 96%;
    display: flex;
    justify-self: center;
    align-self: center;
    
  }

  .serviceSection {
    margin-top: 20px;
    width: auto;

    /* display: grid; */
    /* grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); */
    /* grid-auto-rows: auto; */
    row-gap: 10px;
    column-gap: 10px;
  }
}

@media (max-width: 700px){
  .centerImage{
    margin: 0;
    width: 50%;
  }
  .imagesTextContainer{
    flex-direction: column;

  }
  .imagesContainer ,.txtContainer{
    width: 98%;
  }
  .imagesContainer{
    padding: 0;
  }
  .txtContainer{
    padding-left: 7px;
  }
  .serviceSection{
    padding-left: 4px;
  }
}

@media (max-width: 530px){
    .centerImage{
      width: 80%;
    }
}
