.about {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  font-size: 30px;
}

.mainTitle {
  width: 100%;
  display: flex;
  height: 100px;
  background-color: #eff6ff;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  /* color: var(--themeColor); */
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* text-transform: uppercase; */
}

.topTitle {
  width: 100%;
  margin-top: 30px;
  line-height: 30px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 29px;
  font-weight: 800;
  color: var(--darkText);
  
}

.aboutbox {
  margin-top: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  column-gap: 30px;
  row-gap: 20px;
}

.aboutbox2 {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  column-gap: 30px;
  row-gap: 20px;
  background-color: #eff6ff;
  padding: 20px;
}

.leftBox {
  width: 100%;
  /* height: 600px; */
  display: flex;
    justify-content: center;
    align-items: flex-start;
}

.OfficeImageContainer {

  border-radius: 10px;
  object-fit: cover;
  width: 100%;
}

.container{
  position: relative;
  display: inline-block; 
}
.OfficeImageContainer2{
  width: 100%;
  /* height: 99%; */
  /* height: 490px; */
  border-radius: 10px;
  object-fit: cover;
}

.RightBox {
  width: 100%;
  padding-top: 50px;
}

.p1 {
  font-size: 16px;
  color: var(--lightText);
  line-height: 1.5;
  text-align: justify;
}

.p2 {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
  line-height: 1.5;
  text-align: justify;
}

.p3 {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
  line-height: 1.5;
  text-align: justify;
}

.getintouchParentDiv {
  visibility: hidden;
  margin-top: 20px;
  border-radius: 6px;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: var(--themeColorText);
  width: fit-content;
  border: 1px solid var(--themeColorText);
  background-color: white;
}

.getintouchParentDiv:hover {
  background-color: var(--themeColorText);
  color: white;
  cursor: pointer;
}

.RightBox1 {
  width: 100%;
  display: grid;
}
.objectiveImgContainer{
  width: 50%;
  /* height: 800px; */
}
.objective{
  width: 100%;
  height: 100%;
  /* height: 800px; */
  /* aspect-ratio: 1/1; */
  border-radius: 13px;
  object-fit: cover;
}
.objectiveContent{
  width: 70%;
  display: grid;
  gap: 56px;
}
.objectiveContentInner{
  /* height: 0%; */
}

.leftBox1 {
  width: 100%;
  height: 100%;
}

.whyChooseUsTitle {
  width: 100%;
  margin-top: 100px;

  line-height: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;

  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.whyChooseUsDescription {
  width: 100%;
  margin-top: 20px;

  line-height: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: var(--lightText);
}

.whyChooseUsContainer {
  margin-top: 40px;
  gap: 55px;
  height: 600px;
  display: flex;
  /* grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); */
  /* grid-auto-rows: auto; */
  /* column-gap: 20px; */
  /* row-gap: 20px; */
}

.titleObj{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 14px
}

.contentObj{
  font-size: 16px;
  text-align: justify;
}
.block1st {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.box1 {
  margin-top: 30px;
  background-color: var(--cardBorderColorLight);
  padding: 16px;
  border-radius: 10px;
  height: 200px;
}

.boxImg {
  margin-top: -30px;
  height: 40px;
  width: 40px;
  padding: 6px;
  background-color: white;
  border: 2px solid var(--cardBorderColorLight);
  border-radius: 10px;
}

.boxTitle {
  margin-top: 10px;
  color: var(--darkText);
  font-size: 16px;
  font-weight: bold;
}

.boxDesc {
  margin-top: 10px;
  color: var(--lightText);
  font-size: 16px;
  line-height: 22px;
}

.block2st {
  width: 100%;
}

.process {
  margin-top: 40px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  column-gap: 20px;
  row-gap: 20px;
}

.processBox1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.processTitle {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.processDesc {
  width: 100%;
  margin-top: 20px;
  color: var(--lightText);
  line-height: 24px;
  text-align: justify;
}

.buttonProcess {
  visibility: hidden;
  margin-top: 20px;
  border-radius: 6px;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: var(--themeColorText);
  width: fit-content;
  border: 1px solid var(--themeColorText);
  background-color: white;
}

.buttonProcess:hover {
  background-color: var(--themeColorText);
  color: white;
  cursor: pointer;
}

.processImage {
  /* background-color: azure; */
  width: 100%;
  height: 690px;
  border-radius: 10px;
  object-fit: cover;
  /* aspect-ratio: calc(387 / 600 * 100%); */
}

.processBox2 {
  width: 100%;
}

.processCard {
  margin-top: 20px;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
}

.cardIcon {
  height: 50px;
  width: 50px;
  max-width: 50px;
  max-height: 50px;
  background-color: transparent;
  border-radius: 20%;
}

.processContent {
  width: 90%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.title {
  font-size: 18px;
  color: var(--darkText);
  font-weight: bold;
}

.processDesc {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
}

.aboutLastSection {
  width: 100%;
  margin-top: 100px;
  background-color: var(--cardBorderColorLight);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lastSectionTitle1 {
  margin-top: 40px;
  font-size: 30px;
  font-weight: bold;
  color: var(--darkText);
}

.lastSectionTitle2 {
  margin-top: 10px;
  font-size: 30px;
  font-weight: bold;
  color: var(--darkText);
}

.lastSectiondesc1 {
  width: 50%;
  margin-top: 40px;
  font-size: 16px;
  color: var(--lightText);
  text-align: center;
}

.lastSectionBtn {
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 6px;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: var(--themeColorText);
  width: fit-content;
  border: 1px solid var(--themeColorText);
  background-color: white;
}

.lastSectionBtn:hover {
  background-color: var(--themeColorText);
  color: white;
  cursor: pointer;
}



@media (max-width: 1300px) {
.about{
 padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
}
@media (max-width: 1000px){
  .objectiveContent{
    gap: 0;
  }
  .whyChooseUsContainer{
    gap: 10px;
    /* padding: 10px; */
  }
}


@media (max-width: 700px){
  .whyChooseUsContainer{
    height: auto;
    flex-direction: column;
  }
  .objectiveContent{
    width: 100%;
    gap: 20px;
  }
  .contentObj{
    margin-bottom: 15px;
  }
.objectiveImgContainer{
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.objective{
  object-fit: cover;
  width: 80%;
}





}

@media (max-width: 540px){
  .objectiveImgContainer{
    width: 100%;
  }
  .objective{
    width: 100%;
  }
}