.homeParent {
  width: auto;
}

.topBar {
  width: 100%;
  height: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.National {

  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.State {

  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.newsBlock {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  row-gap: 10px;
  column-gap: 10px;
}

.latest {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.latestTitle {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.latestsubTitle {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.panImageBkg {
  background-color: #e7efeb;
  background-repeat: no-repeat;
  height: auto;
  background-image: url("https://images.unsplash.com/photo-1523192193543-6e7296d960e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}

.newsBlock{
width: 100%;
}

.stateNewsBlock{
  width: 100%;
}
.belowFooter{
  width: 100%;
  height: 180px;
  background-color: #0cc0df;
  margin-top: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.contentTxt{
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
  padding-top: 40px;

   
}
.contentTxtTitle{
  font-size: 19px;
  font-weight: 600;
}

.btnTxtContainer{
  width: 290px;
  height: 55px;
   
}

.btnTxt{
  width: 100%;
  background-color: #fff;
  /* color: black; */
  height: 100%;
  border-radius: 5px;
  border-style: none;
  cursor: pointer;
  /* color: white; */
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1300px) {
  .latestTitle {
    font-size: 20px;
  }

  .latestsubTitle {
    margin-top: 10px;
    font-size: 30px;
  }
 
}



@media (max-width: 500px){

.belowFooter{
  height: 270px;
}
}