


.createdocForm{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;


}



.createFormInner{




position: relative;
width: 80vw;
height:80vh;
max-width:800px;
min-width:370px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
overflow-y: scroll;

}

.closeButtonDiv{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.closeFormButton{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.closeButtonIcon{

font-size: 25px;

}


.createFormDiv{

width: 80%;
margin-top:40px;
border-style: none;
height: 80%;
margin: auto;
}


.buttonDiv{

width: 80%;
margin: auto;
border-style: none;
display: flex;
}



.createButton{

padding: 10px;
margin: auto;
margin-top: 100px;
width: 150px;
border-style: none;
background-color: var(--themeColor);
border-radius: 10px;
color: white;
cursor: pointer;
}






