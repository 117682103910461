


.DocumentNames{

width: 250px;
border-style: none;
border-width: 1px;
border-color: grey;
margin-top: 30px;
}


.docnameButton{
width: 100%;
height: 40px;
cursor: pointer;
display: flex;
justify-content: flex-start;
padding-left: 10px;
align-items: center;
overflow:hidden;
font-weight: bold;
background-color: white;
margin-top:5px;
border-style: solid;
border-width: 1px;
border-radius: 5px;
}
