


.profileImageDiv{

position:relative;
height:60%;
padding:10%;
background-color:white;
display: flex;
flex-direction: column;
border-style: none;
border-radius: var(--cardBorderRadius);
}




.userImage{
position: relative;
width: 100%;
height: 95%;
border-radius: var(--cardBorderRadius);
}

.changePicButton{
border-style: none;
height: 30px;
border-radius: var(--cardBorderRadius);
}


.changePicButton:hover{
color: white;
background-color:var(--themeColor);
cursor: pointer;

}







.imageUpload{

height: 5%;
width: 100%;
border-style: none;
cursor:pointer;
background-color: red;
}
.image_field{
background-color: green;
width:100%;
height: 100%;
text-decoration: none;
}


.submit_button{
width: 100%;
color: white;
background-color:var(--themeColor);
border-style: none;
margin-top:20px;
height:30px;
border-radius: var(--cardBorderRadius);
}

.submit_button:hover{
cursor: pointer;


}


