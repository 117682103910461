@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);



/*
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
*/

body {
  margin: 0;
  font-family: 'Roboto',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow-y: scroll;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}





















html{
   
     /*Core color codes------global color codes #224C98 */
     --themeColor: #0cc0df;/*#207ef5;/*#207ef5;*/
    /* #224C98;/*#207ef5;/* #224C98;/*#263c63;/* #1b3d7a;#4285F4*/
     --deepDarkThemeTextColor: #1b1e24;/*#29303d;/*#132545;/*#0e1c36;*/
     --greyTextColor: #5e5d5d;
     --cardBorderColor: #D3D3D3;
     --cardBorderColorLight : #E8E8E8;
     --greenColor1: #65c394;
     --redColor1: #ff726f;
     --chatCommentBkgColor:#E3EFFE ;

     
     --lightThemeColor1: #edf3fc;
     --lightThemeColor2: #f2f4f7;
     --lightThemeColor3: #dae0eb;
     --lightThemeColor4: #e9edf5;
     --tintGreyColor1: #5e5d5d;
     --tintGreyColor2: #cbd0d6;
     --tintGreyColor3: #dee0e3;
     --tintGreyColor4: #f0f2f5;
     --lightGreyTintTextColor: var(--tintGreyColor1);
     

     /*Header Colors*/
     --headerBkgColor: white;
     --headerIconColor: #8496b5;/*#9fbaed;*/
     --headerRightIconsColor: var(--deepDarkThemeTextColor);/* #9fbaed;/*#99add1;/* #9fbaed;*/
     --headerIconColorOnhover: white;
     --headerExpConBtnBkgColor: white;/*#1e458a;*/

     /*Side Nav Bar Colors*/
     --matchingTextColor1: #444655;/*use this color for all text in place of black*/
     --sideNavBarBtnhoverColor: lightgrey;/* var(--lightThemeColor3);*/

     --sideNavBarButtonTextColor: var(--deepDarkThemeTextColor);
     --sideNavBarIconColor: var(--deepDarkThemeTextColor);

     --sideNavBarBkgColor: white;/*var(--lightThemeColor3);/* #505050;*/

     --sideToolBarBkgColor: white;/*var(--lightThemeColor4);*/
     --sideToolBarBkgColor2: var(--sideNavBarBkgColor);

     /* Dashboard side nav Bar colors */
     --sideNavBarDashBoardBkgColor: var(--sideToolBarBkgColor);/* #f0f3fa;*/
     --sideNavBarDashBoardBtnColor: var(--deepDarkThemeTextColor);/*var(--darkThemeTextColor);*/
     --sideNavBarDashBoardBtnBkgColor: #f0f3fa;/*#dae0eb;*/
     --sideNavBarDashBoardOnClickBtnColor: white;
     --sideNavBarDashBoardOnClickBtnBkgColor: var(--themeColor);



     --userProfileInfoBoxBkgColor: white;   
     --mainAppContainerBkgColor: #E8E8E8;
     --ticketTextColor: #364a61;
     --TextColorDarkBlue1: #364a61;
     --clickBtnColorDash:read;/* #ff5349;*/
     --clickBtnColorDashBackground: #ffd4d1;

     /*body background color*/
     --bodyBkgColor: #f2f2f2;

     /*height, width, size settings*/
     --headerHeight:3rem;/* max( 2.5rem, calc( 0.065 * 100vh ) );*/
     --headerFontSize: calc( 0.35 * var(--headerHeight) );
     --headerLogoWidth: calc( 0.80 * var(--headerHeight));
     --headerLogoWidth: calc( 0.80 * var(--headerHeight)); 
     --headerTopMargin: 10px;
     --headerLeftMargin: 0.5%;
     --headerRightMargin: 0.5%;
     --headerTopMargin: calc( 0.17 * var(--headerHeight) ); 



     --sideNavBarWidth: calc( 4.0 * var(--headerHeight)) ;
     --sideNavBarWidthOnContract: 0px;
     --sideNavBarWidthMobile: 3.4rem;
     --sideNavBarFontSize: calc( 0.30 * var(--headerHeight) );
     --sideNavBarIconSize: calc( 0.35 * var(--headerHeight) );


     --sideToolBarHeight: calc(0.77 * var(--headerHeight));
     --topInfoBarInstructorWidth:calc( 7.0 * var(--headerHeight)); 
     --sideBarHidePoint: 850px;

      /*Top tool bar common style parameters inside the course */

      --topRightButtonWidth_Course: 6vw;
      --topRightButtonBkgColorInActive_Course: white;
      --topRightButtonTextColorInActive_Course: var(--deepDarkThemeTextColor);
      --topRightButtonBkgColorActive_Course:lightgrey;
      --topRightButtonTextColorActive_Course:var(--themeColor);
      --topRightButtonIconColorInActive:grey;
      --topRightButtonIconColorActive:var(--themeColor);
      --topRightButtonUnderlineColorActive: var(--bodyBkgColor);
      --topRightButtonUnderlineColorInActive: var(--bodyBkgColor);
      --topRightButtonTextFontSize_Course: calc(  1vw );/*calc( 0.25 * var(--headerHeight) );*/
      --topRightButtonIconFontSize_Course: calc( 1vw );/*calc( 0.30 * var(--headerHeight) );*/
      --topInnerBarWidth:80%;
      --topBarLeftTitleFontSize: calc( 0.4 * var(--headerHeight) );

      /*Top bar common style parameters in the dashboard/home*/
      --toolButtonDashBkgColorInActive: white;/*var(--lightThemeColor2);*/
      --toolButtonDashIconColorInActive: var(--deepDarkThemeTextColor);
      --toolButtonDashTextColorInActive: var(--deepDarkThemeTextColor);
      --toolButtonDashBorderColorInActive:lightgrey; 
      --toolButtonDashBoxShadowInActive:rgba(0, 0, 0, 0) 0px 0px 0px;

      --toolButtonDashBkgColorActive: white;
      --toolButtonDashIconColorActive: var(--deepDarkThemeTextColor);
      --toolButtonDashTextColorActive: var(--deepDarkThemeTextColor);
      --toolButtonDashBorderColorActive:grey; 
      --toolButtonDashBoxShadowActive:rgba(0, 0, 0, 0.16) 0px 1px 4px;
      --topRightButtonWidth_home: var(--topRightButtonWidth_Course);
      --topRightButtonTextFontSize_home: var(--topRightButtonTextFontSize_Course);
      --topRightButtonIconFontSize_home: var(--topRightButtonIconFontSize_Course);
      --toolButtonDashUnderlineColorInActive: var(--bodyBkgColor);/*var(--lightThemeColor2);*/
      --toolButtonDashUnderlineColorActive: var(--greyTextColor);

      --standardBoxShadow1: rgba(0, 0, 0, 0) 0px 0px 0px;
      --standardBoxShadow2: rgba(0, 0, 0, 0.16) 0px 1px 4px;

      /*Card specifications*/
      --cardBorderRadius:calc( 0.2 * var(--headerHeight) );


}




/*
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');

*/

/*
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Quicksand&display=swap");
*/

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}




.edrapp{

     width:100%;
     background-color: var(--lightThemeColor2);
     
     -ms-overflow-style: none; 
     scrollbar-width: none;
}

.edrapp::-webkit-scrollbar {
    display: none;
}



@media screen and (max-width: 1000px) {
html{
   --topRightButtonTextFontSize_Course:calc( 0.25 * var(--headerHeight) );
   --topRightButtonIconFontSize_Course: calc( 0.25 * var(--headerHeight) );
   --topRightButtonWidth_Course: 15vw;
 }

}



@media screen  and (max-width: 1400px)  {
html{
--headerHeight: 2.6rem; 

}


}





input::file-selector-button {
                 background-color: var(--themeColor);
                 background-position-x: 0%;
                 background-size: 100%;
                 border: 0;
                 border-radius: 8px;
                 color: #fff;
                 padding: 10px;

}








.Header_styleDivHeader__O22e7{
height: 0px;/*calc( 0.5 * var(--headerTopMargin) );*/
width:96%;
background-color: var(--headerRightIconsColor);
margin-left: 2%;
margin-top: calc( 0.5 * 0.17 * var(--headerHeight) );
position: fixed;
border-radius:  var(--headerTopMargin)  var(--headerTopMargin) 0px 0px;
}





.Header_header__1VCKf{

     background-color: var(--headerBkgColor);
     height: var(--headerHeight);
     text-align: center;
     display: flex;
     justify-content: space-between;
     margin: auto;
     line-height:var(--headerHeight);
     font-size: var(--headerFontSize);
     box-shadow:none;
     position: fixed;
     width: 100%;
     z-index:2;
     /*border-radius: var(--headerTopMargin);
     margin-left: 0.5%;
     margin-right: 0.5%;
     top:  var(--headerTopMargin);*/
     border-style: none;
     border-color: lightgrey;
     border-width: 1px;
}








.HeaderLeft_headerLeft__20zk0{

    text-align: center;
    padding-left: 20px;
    display:flex;
    justify-content: flex-start;
    align-items: center; 
    overflow: hidden;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: var(--cardBorderColor);
    }


.HeaderLeft_logo__2KFAj{/*
    width: var(--headerLogoWidth);
    height: var(--headerLogoWidth);
    border-radius: 50%;
    position: relative;
    top: 50%;
    -webkit-transform: perspective(1px) translateY(-50%);
    -ms-transform: perspective(1px) translateY(-50%);
    transform: perspective(1px) translateY(-50%);
    */}


.HeaderLeft_logoText__12JCx{
color: var(--themeColor);
font-size: calc( 0.40 * var(--headerHeight) );
margin: auto;
border-style: none;
display: flex;
justify-content: space-between;
align-items: center;
margin-left: 20px;
background-color: var(--themeColor);
color: white;
height: calc( 0.40 * var(--headerHeight) );
border-radius: calc( 0.1 * var(--headerHeight) );
padding: calc( 0.16 * var(--headerHeight) );
position: relative;
}   


.HeaderLeft_styleBar__38ffT{
width: 5px;
height: 3px;
background-color: var(--themeColor);
position: absolute;
top:18px;
}



.HeaderLeft_logoText__12JCx span{
margin-left: 10px;
}


@media screen and (max-width: 1000px) {
.HeaderLeft_logoText__12JCx span{
display: none;
}

}







@media only screen and (max-width: 700px) {

.HeaderLeft_logo__2KFAj{

display:none;
}

}









.HeaderLeft_courseNameButton__1tloe{
    margin-left: 20px;
    text-decoration: none;
    color: white;
    font-weight: lighter !important;
    font-style: normal;
    background-color: var(--headerBkgColor);
    /*border-style: solid;*/
    overflow: hidden;
    border-style: none;
    line-height: var(--headerHeight);
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 20px;
    }

.HeaderLeft_courseNameButton__1tloe i{
font-size: calc( 0.30 * var(--headerHeight) );
font-style: normal;
font-weight: lighter !important;
font-family: Roboto;
color: var(--deepDarkThemeTextColor);
}




.HeaderLeft_superScript__2b4Ov{
    position: relative;	
    vertical-align: top; 
    font-size: calc( 0.23 * var(--headerHeight) );
    top: -0.8em;
    font-family: Roboto; 
    }



.HeaderLeft_ExpConButton__2AUww{
height: calc( 0.7 * var(--headerHeight));
border-style: none;
background-color: var(--headerExpConBtnBkgColor);/* #2f4975;/*#294473;/*var(--headerExpConBtnBkgColor);*/ 
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
margin-left: 20px;
float: left;
border-radius: calc( 0.1 * var(--headerHeight) );
}


.HeaderLeft_ExpConButton__2AUww:hover{
background-color: lightgrey;
}


.HeaderLeft_ExpConIcon__amM7G{

font-size: calc( 0.35 * var(--headerHeight));
color: var(--deepDarkThemeTextColor);

}



.HeaderLeft_clickDash__h515Q{
color: var(--deepDarkThemeTextColor);

}

.HeaderLeft_clickDash__h515Q:hover{
cursor:pointer;
}




.HeaderLeft_backButton__3jRS_{
background-color: var(--headerBkgColor);
border-style: none;
color: var(--deepDarkThemeTextColor);
cursor: pointer;
padding-right: 20px;
}


.HeaderLeft_BackIcon__3nsU9{
font-size:calc( 0.5 * var(--headerHeight) );
}



.CourseDropDown_DropDown__3i8Bo{

border-radius: 3px;
position: absolute;
top: var(--headerHeight);
left: 150px;
border-style: none;
box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
align-items: center;
vertical-align: middle;
z-index: 5;
width:250px;
background-color: white;
transition: height 1s;
padding-bottom: 50px;
/*max-height: 50px;
overflow-y: auto;*/
margin:auto;
padding:20px;

/*overflow-x: hidden;
overflow-y:auto;*/
}


.CourseDropDown_DropDown__3i8Bo::before {
  content: "";
  position: absolute;
  width: 0; 
  height: 0; 
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ; 
  z-index: 9999;
  left: 50px;
}








.CourseDropDown_DropDown__3i8Bo i{
display: block;
background-color: #f1f1f1;
font-weight: lighter;
font-style: normal;
text-align: left;
font-size: 1.2rem;
border-style: none;
height: 3rem;
vertical-align: middle;
line-height: 3rem;
width: 100%;
}



.CourseDropDown_DropDown__3i8Bo i:hover{
display: block;
border-style: none;
background-color: #ddd;
font-style: normal;
font-weight: lighter;
}

.CourseDropDown_courseSelectText__1O_TE{
width: 100%;
height: 50px;
font-size: calc( 0.3 * var(--headerHeight) );
background: white;
color: var(--themeColor);
}


.CourseDropDown_courseBox__11mja{
width: 90%;
height: 50px;
background: #b8d1ff;
border-radius: 4px;
border-style: none;
border-width: 1px;
border-color: #b8d1ff;
color: var(--themeColor);
display: flex;
flex-direction: column;
align-items: center;
margin: auto;
justify-content: center;
margin-bottom: 10px;
}


.CourseDropDown_courseNameBox__2v845{
width: 70%;
text-overflow: ellipsis;
white-space: nowrap;
overflow:hidden;
}




.CourseDropDown_courseBox__11mja:hover{
color: #ff5349;	
background: #ffd4d1;
border-style: none;
cursor: pointer;
}




.CourseDropDown_topGapBox__3Jxfk{
height: 50px;
width: 100%;
background-color: white;
color: grey;
font-weight: bold;
margin-bottom: 10px;

}



.HeaderRight_headerRight__3DGSn{

overflow: hidden;
text-align: center;
padding-right: 60px;
display: flex;
align-items: center;
border-style: none;
}



.HeaderRight_tmpBtn__3zkdf{
height: calc( 0.7 * var(--headerHeight));
width: calc( 0.7 * var(--headerHeight));
border-radius: 50%;
margin-right: 20px;
background-color: var(--themeColor);
border-style: solid;
border-color: white;
border-width: initial;
}

.HeaderRight_tmpIcon__pKKD-{
font-size: calc( 0.30 * var(--headerHeight) );
color: white;
margin-left: auto;
margin-right: auto;
display: block !important;
}



.ActionItemsHead_actionItemsParentDiv__30Qjh{ 
 background-color: var(--headerBkgColor);
 border-style: none;
 z-index: 9999;
}


.ActionItemsHead_test__2tvY1{
position: absolute;
background-color: red;
top: 10px;
height:200px;
z-index: 99999;
width: 100px;
}



.ActionItemsHead_UsersIcon__3L1Ze{
    font-size: calc(  0.40 * var(--headerHeight) );
    color: var(--headerRightIconsColor);
    vertical-align:middle;
    border-style: none;
    flex-shrink: 0;
    }


.ActionItemsHead_UsersIcon__3L1Ze:hover{
   color: var(--themeColor)

}    




.ActionItemsHead_ActionItemsButton__3OKFT{
    position: relative;
    height: calc( 0.7 * var(--headerHeight) );
    width: calc( 0.7 * var(--headerHeight) );
    border-style: none ;
    border-color: var(--headerRightIconsColor);
    border-width:calc( 0.06 * var(--headerHeight) );
    border-radius: 50%;
    background-color: var(--headerBkgColor);
    vertical-align: middle;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    }


@media only screen and (max-width: 700px) {

.ActionItemsHead_ActionItemsButton__3OKFT{

border-style:none;
}

}






.ActionItemsHead_ActionItemsButton__3OKFT:hover{

background-color:var(--headerRightIconsColor);

}



.ActionItemsHead_alertMessage__2cW8j{
position: absolute;
top: calc( -0.15 * var(--headerHeight) );
left:calc( 0.3 * var(--headerHeight) );
min-width: calc( 0.244 * var(--headerHeight) );
height: calc( 0.244 * var(--headerHeight) );
font-size: calc( 0.244 * var(--headerHeight) );
background-color: #E34234;
border-radius: 50%;
border-color: var(--headerRightIconsColor);
color: white;
padding: 3px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
font-style: normal;
padding: 4px;
}











.CourseEnrollmentRequest_courseEnrollmentRequest__3aKUr{

background-color: white;
position: absolute;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding: 20px;
z-index: 99999000;
width: 200px;
top: var(--headerHeight);
border-radius: 4px;
height: 300px;
/*overflow-y: auto;
overflow-x: hidden;*/
line-height: normal;
}

.CourseEnrollmentRequest_courseEnrollmentRequest__3aKUr::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ;
  z-index: 9999999;
  left: 7px;
}


.CourseEnrollmentRequest_allRequests__17jFD{
border-style: none;
height: 100%;
overflow-y: auto;
overflow-x: hidden;
}






.CourseEnrollmentRequest_ApproveRejectBtnDiv__Ek-tB{
display: flex;
flex-direction: column;
border-style: none;
justify-content: space-between;
height: 70px;
}



.CourseEnrollmentRequest_approveButton__1Hbz5{
border-style: none;
background-color: #65c394;
border-radius: 4px;
color: white;
padding:5px;
cursor: pointer;
}

.CourseEnrollmentRequest_approveButton__1Hbz5:hover{
background-color: #45966e;
}



.CourseEnrollmentRequest_rejectButton__3N9Kx{
border-style: none;
background-color: #ff726f;
border-radius: 4px;
color: white;
padding:5px;
cursor: pointer;
}



.CourseEnrollmentRequest_rejectButton__3N9Kx:hover{
background-color: #ad4442;
}

.CourseEnrollmentRequest_oneRequest__3IFKb{
border-style: solid;
border-width: 1px;
border-color: lightgrey;
margin-bottom: 20px;
padding: 20px;
line-height: normal;
}


.CourseEnrollmentRequest_textBox1__1566w{
font-size: 1rem;
color: grey;
}


.CourseEnrollmentRequest_textBox2__15SAP{
font-size: 1rem;
color: grey;
}


.CourseEnrollmentRequest_requesterName__2VWLD{
color: var(--themeColor);
}



.NotificationsHead_notificationItemsParentDiv__2Rmzv{
 background-color: var(--headerBkgColor);
 border-style: none;
 z-index: 9999;
 margin-left: 20px;
}






.NotificationsHead_UsersIcon__2Si8K{

    font-size: calc( 0.40 * var(--headerHeight) );
    color: var(--headerRightIconsColor);
    vertical-align:middle;
    flex-shrink: 0;
    }


.NotificationsHead_UsersIcon__2Si8K:hover{
   color: var(--themeColor)

}



.NotificationsHead_ActionItemsButton__3cXsB{
    position: relative;
    height: calc( 0.70 * var(--headerHeight) );
    width: calc( 0.70 * var(--headerHeight) );
    border-style: none;
    border-width:calc( 0.06 * var(--headerHeight) );
    border-color: var(--headerRightIconsColor);
    border-radius: 50%;
    background-color: var(--themeColor);
    vertical-align: middle;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center; 
    }



@media only screen and (max-width: 700px) {

.NotificationsHead_ActionItemsButton__3cXsB{

border-style:none;
}

}





.NotificationsHead_ActionItemsButton__3cXsB:hover{

background-color:white;

}

.NotificationsHead_alertMessage__Grz51{
position: absolute;
top: calc( -0.15 * var(--headerHeight) );
left:calc( 0.3 * var(--headerHeight) );
min-width: calc( 0.244 * var(--headerHeight) );
height: calc( 0.244 * var(--headerHeight) );
font-size: calc( 0.244 * var(--headerHeight) );
background-color: #E34234;
border-radius: 50%;
border-color: var(--headerRightIconsColor);
color: white;
padding: 3px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
font-style: normal;
padding: 4px;
}




.NotificationContainer_notificationContainer__3PcPy{
background-color: white;
position: absolute;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding: 20px;
z-index: 99999000;
width: 200px;
top: var(--headerHeight);
border-radius: 4px;
height: 150px;
/*overflow-y: auto;
overflow-x: hidden;*/
line-height: normal;
}



.NotificationContainer_notificationContainer__3PcPy::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ;
  z-index: 9999999;
  left: 30px;
}







.NotificationContainer_oneNotification__He2Fl{



}

.MessagesHead_messagesItemsParentDiv__2p96F{
 background-color: var(--headerBkgColor);
 border-style: none;
 z-index: 9999;
 margin-left: 20px;
}



.MessagesHead_UsersIcon__LHnmt{

    font-size: calc( 0.40 * var(--headerHeight) );
    color: red;
    color: var(--headerRightIconsColor);
    vertical-align:middle;
    flex-shrink: 0;
    }


.MessagesHead_UsersIcon__LHnmt:hover{
   color: var(--themeColor)

}



.MessagesHead_ActionItemsButton__2rGcB{
    position: relative;
    height: calc( 0.7 * var(--headerHeight) );
    width: calc( 0.7 * var(--headerHeight) );
    border-style: none;
    border-width:calc( 0.06 * var(--headerHeight) );
    border-color: var(--headerRightIconsColor);
    border-radius: 50%;
    background-color: var(--themeColor);
    vertical-align: middle;
    cursor:pointer;
    display:flex;
    align-items: center;
    justify-content: center;
    }



@media only screen and (max-width: 700px) {

.MessagesHead_ActionItemsButton__2rGcB{

border-style:none;
}

}









.MessagesHead_ActionItemsButton__2rGcB:hover{

background-color:white;

}



.MessagesHead_alertMessage__15OZj{
position: absolute;
top: calc( -0.15 * var(--headerHeight) );
left:calc( 0.3 * var(--headerHeight) );
min-width: calc( 0.244 * var(--headerHeight) );
height: calc( 0.244 * var(--headerHeight) );
font-size: calc( 0.244 * var(--headerHeight) );
background-color: #E34234;
border-radius: 50%;
border-color: var(--headerRightIconsColor);
color: white;
padding: 3px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
font-style: normal;
padding: 4px;
}






.MessagesContainer_notificationContainer__2EXAW{
background-color: white;
position: absolute;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding: 20px;
z-index: 99999000;
width: 200px;
top: var(--headerHeight);
border-radius: 4px;
height: 150px;
/*overflow-y: auto;
overflow-x: hidden;*/
line-height: normal;
}



.MessagesContainer_notificationContainer__2EXAW::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ;
  z-index: 9999999;
  left: 30px;
}







.MessagesContainer_oneNotification__2XbNk{



}

.UserHead_UserHeadDiv__3keSx{

 background-color:var(--headerBkgColor);
 display: flex;
 justify-content: space-between;
 align-items: center;
}



.UserHead_UsersIconHead__1r0zC{

    font-size: calc( 0.40 * var(--headerHeight) );
    color:white; 
    vertical-align:middle;
    }


.UserHead_UsersIconHead__1r0zC:hover{

}    



.UserHead_UserHeadButton__3ZRmg{

    /*height: calc( 0.2 * var(--headerHeight) );
    width: calc( 0.2 * var(--headerHeight) );*/
    position: relative;	
    border-style: none ;
    border-color: var(--headerRightIconsColor);
    border-width:medium;
    background-color: var(--headerBkgColor); 
    vertical-align: middle;
    cursor:pointer;
    margin-left: 40px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: space-between;
    font-family: Roboto;
    color: var(--deepDarkThemeTextColor);
    }

.UserHead_UserHeadButton__3ZRmg:hover{


}


.UserHead_userImage__DomD8{

    width: calc( 0.7 *  var(--headerLogoWidth) );
    height: calc( 0.7 * var(--headerLogoWidth) );
    border-radius: 50%;
    position: relative;
    /*following lines are to align the image vertically in the middle*/
    /*top: 50%;
    -webkit-transform: perspective(1px) translateY(-50%);
    -ms-transform: perspective(1px) translateY(-50%);
    transform: perspective(1px) translateY(-50%);
    */



}


.UserHead_UserFirstName__1kaB4{

font-style: normal;
font-weight: 400;
color: var(--deepDarkThemeTextColor);
font-size:calc( 0.7 * var(--headerFontSize) );
overflow: hidden;
}



.UserHead_userType__3V-ST{
position: absolute;
width: calc( 0.16 * var(--headerHeight) ) ;
height: calc( 0.16 * var(--headerHeight) );
top: 0px;
right:  calc( -0.13 * var(--headerHeight) );
font-size: calc( 0.23 * var(--headerHeight) );
background-color: var(--headerBkgColor);
border-radius: 50%;
color: var(--deepDarkThemeTextColor);
padding: calc( 0.05 * var(--headerHeight) );
border-style: solid;
border-width: calc( 0.04 * var(--headerHeight) );
display: flex;
align-items: center;
justify-content: center;
}


.UserHead_userType__3V-ST i{
font-style: normal;
}





@media screen and (max-width: 1000px) {

.UserHead_UserFirstName__1kaB4{
display: none;
}
}









.UserHeadDropDown_DropDown__2Z6rE{

border-radius: 3px;
position: absolute;
top: var(--headerHeight);
right: 100px;
border-style: none;
box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
align-items: center;
vertical-align: middle;
}


.UserHeadDropDown_DropDown__2Z6rE::before {
  content: "";
  position: absolute;
  width: 0; 
  height: 0; 
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid grey ; 
  z-index: 9999;
  left: 130px;
}








.UserHeadDropDown_DropDown__2Z6rE i{
display: block;
background-color: #f1f1f1;
font-weight: lighter;
font-style: normal;
text-align: left;
font-size: 1.2rem;
border-style: none;
height: 3rem;
vertical-align: middle;
line-height: 3rem;
width: 170px;
}



.UserHeadDropDown_DropDown__2Z6rE i:hover{
display: block;
border-style: none;
background-color: #ddd;
font-style: normal;
font-weight: lighter;
cursor: pointer;
}


.UserHeadDropDown_profile__1f15e{

background: linear-gradient(to top, #f1f1f1 90%, grey 0%);

}


.UserHeadDropDown_profile__1f15e:hover{
background: linear-gradient(to top, #ddd 90%, grey 0%);

}

.UserHeadDropDown_icons__2RNDE{

font-size: 1rem;
padding-left: 0.5rem;
padding-right: 0.5rem;
}









.SideBarDashboard_sideBarDashboard__2LEOr{
      height: calc( 100vh - var(--headerHeight) - var(--sideToolBarHeight)  );
      width: var(--sideNavBarWidth);
      position: fixed;
      z-index: 1;
      top:calc( var(--headerHeight) );
      left: 0.5%;
      background-color: var(--sideNavBarDashBoardBkgColor);/*var(--sideNavBarDashBoardBkgColor);*/
      /*#c2c3c4;*/
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: 0px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-y: hidden;
      padding-top: 20px;
}


.SideBarDashboard_sideBarDashboard__2LEOr:hover{

overflow-y:auto;
}




/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
*/

.SideNavBarButtonDashboard_mainButtonContainer__2Eu-B{


border-style: none;

}

.SideNavBarButtonDashboard_subOptions__3r1Zh{

position: absolute;
left: 100px;
border-style: solid;
width:300px;
z-index:999999999;
}






 .SideNavBarButtonDashboard_sideNavBarButton__M-FQB {
      padding: calc( 0.16 * var(--headerHeight) );
      text-decoration: none;
      font-size: var(--sideNavBarFontSize);
      color: var(--sideNavBarIconColor);
      /*background-color:#c2c3c4;*/
      border-style: none;
      border-color:#757575;
      border-width: thin;
      display: block;
      transition: 0.3s;
      font-weight: 100;
      margin:calc( 0.2 * var(--headerHeight) );
      text-align: left;
      cursor: pointer;
      position:relative;
      align-items: center;
      border-style: none;
      background: red;
      width:100%;
      width: calc( var(--sideNavBarWidth) - calc( 0.3 * var(--headerHeight) )  );
      height:100%;
      padding-top: calc( 0.2 * var(--headerHeight) );
      padding-bottom: calc( 0.2 * var(--headerHeight) );
      padding-left: calc( 0.2 * var(--headerHeight) );
      font-family: Roboto;
    }


.SideNavBarButtonDashboard_sideNavBarButton__M-FQB:hover{

/*background: linear-gradient(to right, var(--sideNavBarBtnhoverColor) 90%, var(--themeColor) 0%) !important;*/

}


/*
.sideNavBarButton i {
      font-style: normal;
      font-family: sans-serif;
      padding-left: 0px;
      font-size: calc( 0.9 * var(--sideNavBarFontSize) );
    }
   
*/

.SideNavBarButtonDashboard_buttonText__3fHlp{
color: var(--themeColor);
vertical-align: middle;
}


.SideNavBarButtonDashboard_dashIcon__AN3Hr{
vertical-align: middle;
padding-right: 5px;
font-size:var(--sideNavBarIconSize);
color: var(--themeColor);
margin-left: calc( 0.14 * var(--headerHeight) );
}





.QuickMeetingPage_quickMeetingPage__3dwIv{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1);
display: flex;
align-items: center;
justify-content: center;
}


.QuickMeetingPage_meetingInfoPage__2hetE{

position: relative;
width: 40vw;
height:60vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
overflow: hidden;


}

.QuickMeetingPage_closeButtonDiv__4_wBW{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.QuickMeetingPage_closeFormButton__17nm7{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.QuickMeetingPage_closeButtonIcon__35lRH{

font-size: 25px;

}

.QuickMeetingPage_meetingLink__3GcYj{

color: var(--themeColor);
text-decoration: underline;
background-color:var(--lightThemeColor1);
padding-left: 20px;
padding-right: 20px;
border-radius: 5px;
border-style: none;
margin-top: 20px;
font-size: 17px;
padding: 20px;
cursor: pointer;

white-space: pre-wrap; /* CSS3 */    
white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
white-space: -pre-wrap; /* Opera 4-6 */    
white-space: -o-pre-wrap; /* Opera 7 */    
word-wrap: break-word; /* Internet Explorer 5.5+ */
width: 80%;

}



.QuickMeetingPage_copyButton__24EGR{

margin-left: 10px;
border-style: none;
background-color: lightgrey;
padding: 5px;
border-radius: 4px;
cursor: pointer;
}


.QuickMeetingPage_startMeetingNowButton__1_yRf{
margin-top: 50px;
border-style: none;
padding: 10px;
color: white;
background-color: var(--themeColor);
font-weight: bold;
border-radius: 5px;
cursor: pointer;
}










/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
*/

.SideNavBarButtonQMeeting_mainButtonContainer__1OwD2{


border-style: none;

}

.SideNavBarButtonQMeeting_subOptions__2EDqG{

position: absolute;
left: 100px;
border-style: solid;
width:300px;
z-index:999999999;
}






 .SideNavBarButtonQMeeting_sideNavBarButton__1mTQZ {
      padding: calc( 0.20 * var(--headerHeight) );
      text-decoration: none;
      font-size: var(--sideNavBarFontSize);;
      color: var(--sideNavBarIconColor);
      background-color:#c2c3c4;
      border-style: solid;
      border-color:#757575;
      border-width: thin;
      display: flex;
      flex-direction:column;
      transition: 0.3s;
      font-weight: 100;
      margin:5px;
      width: var(--sideNavBarWidth);
      text-align: left;
      cursor: pointer;
      position:relative;
      align-items: center;
      font-family: Roboto;
      border-style: solid;
      background: red;
      /*top: 150px;*/
      width:90%;
      margin: auto;
      height:100%;
      border-radius: 10px;
      margin-bottom:10px;
      margin-top: 10px;
    }


.SideNavBarButtonQMeeting_sideNavBarButton__1mTQZ:hover{

/*background: linear-gradient(to right, var(--sideNavBarBtnhoverColor) 90%, var(--themeColor) 0%) !important;*/

}



.SideNavBarButtonQMeeting_sideNavBarButton__1mTQZ i {
      font-family: sans-serif;
      padding-left: 0px;
      font-size: calc( 0.9 * var(--sideNavBarFontSize) );
    }
    


.SideNavBarButtonQMeeting_buttonText__30D19{
color: var(--themeColor);
vertical-align: middle;
font-size: var(--sideNavBarFontSize);
font-family: Roboto;
}


.SideNavBarButtonQMeeting_dashIcon__1b7oe{
vertical-align: middle;
padding-right: 5px;
font-size:var(--sideNavBarFontSize);
color: var(--themeColor);
}














.MainAppContainer_mainAppContainer2__2tu_5{
position: relative;
width: calc( 100% - var(--sideNavBarWidth) );
top:  calc( 1 * var(--headerHeight) );
left: var(--sideNavBarWidth);
display: grid;
grid-template-columns: 1fr;
grid-template-rows: calc(0.5 * var(--headerHeight)) calc(0.9 * var(--headerHeight)) 1fr;
grid-row-gap: 15px;
row-gap: 15px;
grid-column-gap: 15px;
column-gap: 15px;
transition: 0.5s;
border-style: solid;
}



.MainAppContainer_mainAppContainer__1_LfW{
position: relative;
/*background-color: red;#E8E8E8;*/
width: calc( 99% - var(--sideNavBarWidth)  );
top: var(--headerHeight);/* calc( var(--headerHeight) + var(--headerTopMargin) );*/
left: calc( var(--sideNavBarWidth) + 0.5% );
/*display: grid;
grid-template-columns: 1fr;
grid-template-rows: calc(0.5 * var(--headerHeight)) calc(0.9 * var(--headerHeight)) 1fr;
grid-row-gap: calc( 0.20 * var(--headerHeight) );*/
transition: 0.5s;
border-style: none;
height: calc( 100vh - var(--headerHeight) );
border-style: none;
border-color: cyan;
border-width: 1px;
overflow-x: hidden;
overflow-y: auto;
background-color: var(--bodyBkgColor);
}


.TopInfoBarUserProfile_topInfoBar__1ytIM{
background-color: #c2c3c4;
box-shadow: none;
font-family:'Roboto','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-size: large;
text-align: left;
background-color: #c2c3c4;
display: flex;
height:calc( 0.5 * var(--headerHeight) );
align-items: center;
}









.TopInfoBarUserProfile_topInfoBar__instructor__-FMIH{
position: relative;
left: calc( 1.5 * 0.50 * 0.18 * 0.5 * var(--headerHeight) );
border-bottom: calc( 0.82 * 0.5 * var(--headerHeight) ) solid var(--themeColor);
border-right: 50px solid transparent;
height: 0;
width: 300px;
color: #c2c3c4;
}



.TopInfoBarUserProfile_topInfoBar__instructor__-FMIH i{
font-style: normal;
margin-left: 50px;
font-size: calc( 0.65 * 0.82 * 0.5 * var(--headerHeight) );
}



.TopInfoBarButton_topInfoBar__profilebtn__3u_Vq{

display: flex;
position:relative;
left: calc(0.05 * 0.5 * var(--headerHeight));
min-width: calc( 2.77 * var(--headerHeight) );
text-align: center;
color: var(--sideNavBarBtnhoverColor);
border-radius: 0 50px 0 50px;
background-color:#E8E8E8;
border-style: solid;
border-width: thin;
height:80%;
font-weight: bold;
border-color: grey;
align-items: center;
justify-content: center;
font-size: calc( 0.65 * 0.82 * 0.5 * var(--headerHeight) )
}

.TopInfoBarButton_topInfoBar__profilebtn__3u_Vq:hover{

cursor: pointer;
}





.TopInfoBarInstructor_topInfoBar__instructorr__2QzU3{

 position: relative;
 height: calc( 0.82 * 0.5 * var(--headerHeight) );
 left: calc( 0.50 * 0.18 * 0.5 * var(--headerHeight) );
 width: var(--topInfoBarInstructorWidth);
 text-align: center;
 color: #c2c3c4;
 vertical-align: middle;
 background-color:var(--themeColor);
 display:flex;
 border-radius: 0 50px 0 0;
 border-style: solid;
 border-width: thin;
 border-color: var(--themeColor);
 align-items: center;
}




.TopInfoBarInstructor_topInfoBar__instructor__2jayx{
position: relative;	
left: calc( 1.5 * 0.50 * 0.18 * 0.5 * var(--headerHeight) );	
border-bottom: calc( 0.82 * 0.5 * var(--headerHeight) ) solid var(--themeColor);
border-right: 50px solid transparent;
height: 0;
width: 500px;
color: #c2c3c4;
}



.TopInfoBarInstructor_topInfoBar__instructorName__3kuz_{
overflow: hidden;
font-style: normal;
margin-left: 50px;
font-size: calc( 0.65 * 0.82 * 0.5 * var(--headerHeight) );
}

@media only screen and (max-width: 600px) {

.TopInfoBarInstructor_topInfoBar__instructor__2jayx{
display: none;
}

}








/*
.topInfoBar__instructor i{
font-style: normal;
margin-left: 50px;
font-size: calc( 0.65 * 0.82 * 0.5 * var(--headerHeight) );
}
*/



.TopTitleBar_topTitleBar__3Bu2c{

color: var(--greyTextColor);
box-shadow: none;
font-family:'Roboto','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-size: calc(0.45 * var(--headerHeight));
vertical-align: middle;
/*margin-left: 30px;*/
display: flex;
align-items: center;
height: calc( 1.2 * var(--headerHeight) );
border-style: none;
border-color: red;
}

.TopTitleBar_topTitleBar__Text__16Wv7{

margin-left: 30px;
font-style: normal;
font-family: Roboto;
}


.TopTitleBar_backButton__1F3Qs{

margin-right: 20px;
height: 20px;
border-style: none;
font-weight: bold;
color: var(--themeColor);
background-color: lightgrey;
}


.TopTitleBar_backButton__1F3Qs:hover{

cursor: pointer;
}



.TopTitleBar_backIcon__2TcRR{

font-size: 30px;

}



.ContentDivUserProfile_contentDivUserProfile__2w5IQ{
display: flex;
justify-content: space-between;
border-style: none;
border-color: orange;
padding-bottom: 20px;
/*
display: grid;
grid-template-columns: 1fr 2.3fr;
grid-template-rows: 1fr 1fr ;
grid-gap: 10px;
padding-bottom: 50px;
padding-right:20px;
*/




}



@media only screen and (max-width: 1100px) {
 .ContentDivUserProfile_contentDivUserProfile__2w5IQ{
     display: flex;
     justify-content: space-between;     
     flex-direction: column;
 }
}






.ProfileImageSec_profileImageSec__3DM82{


  display: flex;
  justify-content: flex-start;
  margin-left: 30px;
  margin-right: 0px;
  /*border-radius:3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
  border-style: none;
  border-color: orange;
  border-width: 1px;
  width: 30%;
  flex-direction: column;
  /*grid-row-start:1;
  grid-row-end:2;*/
  

}


.ProfileImageSec_unitColumn__2udlL{

width: 100%;
border-style: solid;
aspect-ratio: 1 / 1.8;
margin-bottom: 30px;
background-color: white;
border-color:var(--cardBorderColor);
border-width: 1px;
border-radius: var(--cardBorderRadius);
}


.ProfileImageSec_unitColumn__2udlL:before {/*
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(5/3 * 100%);
  background-color:var(--themeColor);
*/}






@media only screen and (max-width: 1100px) {

.ProfileImageSec_profileImageSec__3DM82{

width: 100%;
margin-right: 20px;

}


}









.ProfileImageSec_profileImageSec__3DM82:before {/*
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(5/3 * 100%);
  background-color:var(--themeColor);
*/}


.ProfileImageSec_profileImageSec__pseudo__3v-Ga{

  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: var(--cardBorderRadius);
  display: flex;
  flex-direction: column;
}






.ProfileImageSec_innerColumn__1RKjr{

height: 80%;
width: 80%;
border-style: solid;
margin: auto;
margin-top: 10%;
border-width: 1px;
border-color: lightgrey;
}

.ProfileImageSec_ProfileInfo2_titleBar__2mVpb{


width: 100%;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
}

.ProfileImageSec_ProfileInfo2_titleBar__2mVpb i{
font-style: normal;
color: grey;
}



.ProfileImageSec_yourlinks__1ou3s{

width: 100%;
display: flex;
justify-content:center;
}

.ProfileImageSec_youtubeLink__1aga3{

color: red;
padding-right: 20px;
font-size: 25px;
}

.ProfileImageSec_linkedInLink__2Mp0g{
padding-right: 20px;
font-size: 25px;
color: #0072b1;
}


.ProfileImageSec_twitterLink__dPWZX{
font-size: 25px;
color: #00acee;
}









.ProfileImage_profileImageDiv__3pizm{

position:relative;
height:60%;
padding:10%;
background-color:white;
display: flex;
flex-direction: column;
border-style: none;
border-radius: var(--cardBorderRadius);
}




.ProfileImage_userImage__1yoyd{
position: relative;
width: 100%;
height: 95%;
border-radius: var(--cardBorderRadius);
}

.ProfileImage_changePicButton__1yxCV{
border-style: none;
height: 30px;
border-radius: var(--cardBorderRadius);
}


.ProfileImage_changePicButton__1yxCV:hover{
color: white;
background-color:var(--themeColor);
cursor: pointer;

}







.ProfileImage_imageUpload__3vHio{

height: 5%;
width: 100%;
border-style: none;
cursor:pointer;
background-color: red;
}
.ProfileImage_image_field__3Rffp{
background-color: green;
width:100%;
height: 100%;
text-decoration: none;
}


.ProfileImage_submit_button__3Duir{
width: 100%;
color: white;
background-color:var(--themeColor);
border-style: none;
margin-top:20px;
height:30px;
border-radius: var(--cardBorderRadius);
}

.ProfileImage_submit_button__3Duir:hover{
cursor: pointer;


}




.ProfileImageInfo_profileImageInfo__1PcDZ{
height: 40%;
padding:10%;
padding-top: 0px;
background-color: white;
text-align: center;
color: black;
display: flex;
flex-direction: column;
border-style: none;
border-radius: var(--cardBorderRadius);
}

.ProfileImageInfo_nameDiv__2QAq1{
height: 40%;
background-color: white;
}

.ProfileImageInfo_name_i__3SEsE{

font-style: normal;
color:var(--greyTextColor)
}

.ProfileImageInfo_profileImageInfo_position__2k4-Q{

height:40px;
background:linear-gradient(to top, white 98%, lightgrey 0%);
display: flex;
justify-content: space-between;
align-items: center;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}

.ProfileImageInfo_profileImageInfo_position__2k4-Q i{

font-style: normal;
position:relative;
left: 10px;
width: 100px;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}

.ProfileImageInfo_roleTxt__1clIE{

float: left;
}



.ProfileImageInfo_profileImageInfo_joiningDate__2lG62{

height:40px;
background:linear-gradient(to top, white 98%, lightgrey 0%);
display: flex;
justify-content: space-between;
align-items: center;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}


.ProfileImageInfo_profileImageInfo_joiningDate__2lG62 i{
font-style: normal;
position:relative;
left: 10px;
width: 100px;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}


.ProfileImageInfo_joinTxt__2R5YO{

float: left;

}




.ProfileImageInfo_profileImageInfo_status__18e3V{
height:40px;
background:linear-gradient(to top, white 98%, lightgrey 0%);
display: flex;
justify-content: space-between;
align-items: center;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}

.ProfileImageInfo_profileImageInfo_status__18e3V i{

font-style: normal;
color: white;
background-color: #4CBB17;
padding: 2px;
position:relative;
width: 100px;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}





.ProfileUnitBlock_profileImageSec__18YpO{


  background: white;
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  border-radius:var(--cardBorderRadius);
  /*box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
  width: 70%;
  margin-right: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--cardBorderColor);

}




@media only screen and (max-width: 1100px) {

.ProfileUnitBlock_profileImageSec__18YpO{

width: 100%
}


}









.ProfileUnitBlock_profileImageSec__18YpO:before {/*
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(5/3 * 100%);
  background-color:var(--themeColor);
*/}


.ProfileUnitBlock_profileImageSec__pseudo___9Z-Q{
/*
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
*/
}




.ProfileInformationAndSetting_profileInformationAndSetting__3nQOM{

  background: white;
  /*display: flex;
  justify-content: space-between;
  margin-left: 30px;
  border-radius:3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  */
  /*grid-row-start:1;
  grid-row-end:4;*/
  border-style:none;
  width: 100%;
  border-radius: var(--cardBorderRadius);
}



.UserProfileSwitchBar_userProfileSwitchBar__1P_L5{
height: calc( 0.6 * var(--headerHeight) );
background-color: white;
display: flex;
justify-content:flex-start;
flex-direction: row;
border-radius:var(--cardBorderRadius);
border-bottom-style: solid;
border-width: 2px;
border-color: var(--bodyBkgColor);
}








.UserProfileSwitchBarButton_userProfileSwitchBarButton__1osG1{

height: 100%;
width: 100%;
color: var(--themeColor);
border-style: none;
border-right: solid;
cursor: pointer;
border-width: 2px;
display: flex;
justify-content: center;
align-items: center;
font-size: calc( 0.29 * var(--headerHeight) );
border-radius: var(--cardBorderRadius) var(--cardBorderRadius) 0px 0px;
border-color: var(--cardBorderColor);
}









.UserProfileContentBasic_userProfileContentBasic__2DEOg{
width: 100%;
height: calc( 100% - calc( 0.6 * var(--headerHeight) ) );
border-style: none;
border-radius: var(--cardBorderRadius);
}




.AboutBasic_about_Basic__1JInm{


width: 100%;
background-color: white;
display: flex;
align-items: center;
flex-direction: column;
border-style: none;
border-color: red;
border-radius:var(--cardBorderRadius);

}


.AboutBasic_aboutInfoContainer__3z9MS{

width:93%;
display: flex;
align-items: center;
background-color: #E8E8E8;
background-color:var(--userProfileInfoBoxBkgColor);
border-style: none;
border-width: 1px;
border-color: #E8E8E8;
border-color:green;
border-radius:3px;

}



.AboutBasic_aboutInfoContainerEd__2qK-Z{

width:93%;
display: flex;
flex-direction: column;
align-items: center;
background-color: #E8E8E8;
background-color:var(--userProfileInfoBoxBkgColor);
border-style: none;
border-width: 1px;
border-color: #E8E8E8;
border-color:green;
border-radius:3px;

}












@media only screen and (max-width: 1100px) {
 .AboutBasic_aboutInfoContainer__3z9MS{
     display: flex;
     justify-content: space-between;
     flex-direction: column;
     width: 100%;
     border-style: solid;
     border-color: cyan;
     background-color: cyan;
 }



}








.AboutBasic_InfoBoxLeft__xaS49{
width: 100%;
border-style: none;
background-color: white;
}


.AboutBasic_InfoBoxRight__2ZW9f{
width: 100%;
border-style: none;
background-color: white;
}











.UnitBar_unitBar__342j0{

width: 93%;
height: calc( 0.8 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
border-color: grey;

}


.UnitBarFirstName_unitBarFirstName__lvdHB{

width: 93%;
height: calc( 0.5 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
border-color: grey;
display: flex;
align-items: center;
margin-top:10px;
margin-bottom: 10px;
}


.UnitBarFirstName_firstNameTitle__412C8{

width:30%;
font-size:calc( 0.25 * var(--headerHeight) );
border-style: none;
border-width:1px;
height:100%;
display: flex;
align-items: center;
border-color: #E8E8E8;
border-color:lightgrey;
position: relative;
padding-left: 20px;
color: grey;
font-weight:bold;
}

.UnitBarFirstName_firstName__1P_Xf{
width:70%;
height: 100%;
font-size:calc( 0.25 * var(--headerHeight) );
text-align: left;
display: flex;
align-items: center;
background-color: var(--userProfileInfoBoxBkgColor);
border-style: none;
border-color:lightgrey;
border-width:1px;
position: relative;
padding-left:10px;
}



.UnitBarGenDOB_unitBarGenDOB__gK5fn{

width: 93%;
height: calc( 0.7 * var(--headerHeight) );
background-color:#E8E8E8;
background-color:var(--userProfileInfoBoxBkgColor);
border-style: none;
border-width: 1px;
border-color: grey;
display: flex;
align-items: center;
margin-top:10px;
margin-bottom: 10px;
justify-content: space-between;
}


.UnitBarGenDOB_genderDiv__2XT16{

width:45%;
font-size:calc( 0.25 * var(--headerHeight) );
border-style: none;
border-width:1px;
height:100%;
display: flex;
align-items: center;
border-color:#E8E8E8;
border-color:lightgrey;
position: relative;
padding-left: 20px;
color: grey;
background-color: white;
}

.UnitBarGenDOB_genderDiv__2XT16 i{

font-style: normal;
padding-left: 20px;
}



.UnitBarGenDOB_dobDiv__2542v{
width:45%;
height: 100%;
font-size:calc( 0.25 * var(--headerHeight) );
text-align: left;
display: flex;
align-items: center;
background-color: white;
border-style: solid;
border-color:#E8E8E8;
border-color:lightgrey;
border-width:1px;
color: #454444;
padding-left: 20px;
}

.UnitBarGenDOB_dobDiv__2542v i{
font-style: normal;
padding-left: 20px;
overflow: auto;

}










.UnitAboutIcon_unitAboutIcon__1aZSo{

width: 93%;
height: calc( 0.8 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
display: flex;
align-items: center;
font-size: calc( 0.6 * var(--headerHeight) );
justify-content: space-between;

}

.UnitAboutIcon_unitAboutIcon__1aZSo div{
display: flex;
align-items: center;
}


.UnitAboutIcon_aboutIcon__2qK4s{
left: calc( 0.3 * var(--headerHeight) );

}


.UnitAboutIcon_unitAboutIcon__1aZSo i{
font-size: calc( 0.3 * var(--headerHeight) );
font-style: normal;
margin-left: 10px;
}

.UnitAboutIcon_editButtonAbout__1sZ4l{
left: calc( 0.3 * var(--headerHeight) );
background-color: white;
border-style: solid;
border-width: 1px;
border-radius: 5px;
padding:5px;
padding-left: 10px;
padding-right: 10px;
cursor: pointer;
color: var(--themeColor);
border-color: var(--cardBorderColor);
}


.UnitAboutIcon_editButtonAbout__1sZ4l:hover{
color:white;
background-color:var(--themeColor);

}












.AboutEditForm_aboutEditFormDivParent__1s23T{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;
}

.AboutEditForm_aboutEditForm__15AlT{

position: relative;
width: 40vw;
height:80vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
overflow: auto;
}















.AboutEditForm_closeButtonDiv__BwRpY{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.AboutEditForm_closeFormButton__aT539{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.AboutEditForm_closeButtonIcon__obepP{

font-size: 25px;

}


.AboutEditForm_logoAndTitleContainer__3C0V1{


height:150px;
width:80%;
background-color: white;
display: flex;
flex-direction: column;

}

.AboutEditForm_bwLogo__2ljgi{

height: 75px;
width: 75px;

}

.AboutEditForm_titleDiv__vULxh{
width: 100%;
display:flex;
justify-content:flex-start;
color: #585858;
}

.AboutEditForm_titleDiv__vULxh i{

font-size: 30px;
}


.AboutEditForm_name_div__kod4J{

display: flex;
flex-direction: column;
width:80%;
align-items: center;
justify-content: center;
margin: 10px;
}

.AboutEditForm_name_txt__2ewTD{
width: 100%;
position: relative;
background-color:white;
font-size: 23px;
color: grey;
font-weight: thin;
}

.AboutEditForm_name_inputDiv__1p0EX{
width: 100%;
}




.AboutEditForm_genpos_div__3BiMu{

display: flex;
flex-direction: row;
width:80%;
align-items: center;
justify-content: space-between;
margin: 20px;
}


.AboutEditForm_gender_div__2Ec8R{
display: flex;
flex-direction: column;
width: 45%;
background-color: red;
border-radius: 5px;
}


.AboutEditForm_position_div__wAw30{
display: flex;
flex-direction: column;
width: 45%;
background-color: green;
border-radius: 5px;
border-style: none;
}



.AboutEditForm_genpos_field__1d_ze{
width:100%;
height: 40px;
font-size: 23px;
padding: 5px;
}


.AboutEditForm_dob_div__3vnx5{
width: 80%;
height: 90px;
display: flex;
flex-direction: column;
background-color: white;
justify-content: flex-start;
align-items: flex-start;
}

.AboutEditForm_dob_txt__kk4Z8{
width: 100%;
position: relative;
background-color:white;
font-size: 23px;
color: grey;
font-weight: thin;

}

.AboutEditForm_dob_innerDiv__2Eo-d{
width: 100%;
position: relative;

}

.AboutEditForm_dob_innerDiv2__3wJvh{

position: relative;
width: 45%;

}


.AboutEditForm_dateofbirth_field__1LUyE{
position: relative;
width: 100%;
padding: 5px;
height: 30px;
font-size:23px;
min-width:200px;
}

.AboutEditForm_firstname_field__3QmwF , .AboutEditForm_lastname_field__aWN3y,.AboutEditForm_gender_field__l51CI,.AboutEditForm_position_field__1KRWz {
position: relative;
width: 100%;
padding: 5px;
height: 30px;
font-size:23px;
}


.AboutEditForm_submitButtonDiv__2AXNc{
width: 80%;	
display: flex;
justify-content: center;
background-color: white;
padding-top: 50px;
}


.AboutEditForm_submit_button__3skn3{
width: 30%;
border-style: none;
background-color: var(--themeColor);
color: white;
height:30px;
width:100px;
border-radius: 10px;
cursor: pointer;
margin-bottom: 50px;
}






.EduDegreeForm_createTicketFormDivParent__1k6Vi{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;
}

.EduDegreeForm_createTicketForm__350E5{

position: relative;
width: 100%;
max-width:700px;
max-height: 90vh;
background-color: white;
border-radius: var(--cardBorderRadius);
border-style: none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
align-items: center;
overflow-y: scroll;
}


.EduDegreeForm_input_field__3MGoj{
height: 40px;
margin:auto;
margin-left: 50px;
}




.EduDegreeForm_dateFields__2mYFt{
display: flex;
width: 75%;
margin: auto;
}




.EduDegreeForm_createTicketFormLoading__1NLSI{

position: relative;
width: 60vw;
height: 80vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style: none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}


.EduDegreeForm_submittingDiv__21a77{
padding-top: 100px;	
color: var(--themeColor);

}









.EduDegreeForm_closeButtonDiv__14-9F{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.EduDegreeForm_closeFormButton__2pK_u{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.EduDegreeForm_closeButtonIcon__1uBM0{

font-size: 25px;

}


.EduDegreeForm_logoAndTitleContainer__2tCSr{


height:150px;
width:80%;
background-color: white;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin: auto;
}

.EduDegreeForm_bwLogo__30hRQ{

height: 75px;
width: 75px;

}

.EduDegreeForm_formTitleDiv__ZwbNi{
width: 100%;
display:flex;
justify-content:flex-start;
color: #585858;
font-size: 30px;
margin: auto;
}

.EduDegreeForm_formTitleDiv__ZwbNi i{

font-size: 25px;
font-style: normal;
}



.EduDegreeForm_submitButtonDiv__1w4F3{
width: 80%;	
display: flex;
justify-content: center;
background-color: white;
padding-top: 50px;
justify-content: center;
border-style: none;
margin: auto;
margin-bottom: 100px;
}


.EduDegreeForm_submit_button__2cmX5{
width: 40%;
border-style: none;
background-color: var(--themeColor);
color: white;
height:40px;
width:200px;
border-radius: 10px;
cursor: pointer;
}


.EduDegreeForm_emptyDiv__13Q1p{
width: 80%;
border-style: solid;
height: 100px;
color: white;

}






.FormInputObjects_name_div__lxQPN{

display: flex;
flex-direction: column;
width:80%;
align-items: center;
justify-content: center;
margin: 10px;
}

.FormInputObjects_name_txt__26qTD{
width: 100%;
position: relative;
background-color:white;
font-size: 23px;
color: grey;
font-weight: thin;
}

.FormInputObjects_name_inputDiv__JQ0xn{
width: 100%;
}

.FormInputObjects_input_field__26Tit  {
position: relative;
width: 100%;
height: 30px;
font-size:20px;
box-sizing: border-box;
}



.FormInputObjects_input_field__26Tit:focus {
    border-style: none;   	
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}









.FormInputObjects_input_field_date__1LR2s  {
position: relative;
width: 100%;
max-width:300px;
height: 30px;
font-size:20px;
box-sizing: border-box;
}


.FormInputObjects_input_field_date__1LR2s:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}






.FormInputObjects_inputText_field__3SUOc  {
position: relative;
width: 100%;
height: 200px;
font-size:20px;
box-sizing: border-box;
}



.FormInputObjects_inputText_field__3SUOc:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}












.FormInputObjects_name_div__wP-BI{

display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin: auto;
margin-top: 10px;
position: relative;
}

.FormInputObjects_name_txt__27Fqg{
width: 100%;
position: relative;
background-color:white;
font-size: 18px;
color: grey;
font-weight: thin;
}

.FormInputObjects_name_inputDiv__LY6xv{
width: 100%;
border-style: none;
padding: 10px;
}

.FormInputObjects_input_field__F5cnn  {
position: relative;
width: 100%;
height: 40px;
font-size:20px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
}



.FormInputObjects_input_field__F5cnn:focus {
    border-style: solid;
    border-width: 2px;    
    outline: none !important;
    border-color: #719ECE;
    border-radius:5px;
}



.FormInputObjects_input_field_textarea__3WBbl{
position: relative;
width: 100%;
height: 500px;
font-size:20px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;

}

.FormInputObjects_input_field_textarea__3WBbl:focus{
border-style: solid;
    border-width: 2px;
    outline: none !important;
    border-color: #719ECE;
    border-radius:5px;
}





.FormInputObjects_input_field_paragraph__1kinM{
position: relative;
width: 100%;
height: 150px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding: 10px;
font-size: 20px;
font-style: normal;
font-family: Roboto;
}

.FormInputObjects_input_field_paragraph__1kinM:focus{
border-style: solid;
border-width: 2px;
outline: none !important;
border-color: #719ECE;
border-radius:5px;

}









.FormInputObjects_input_field_date__1UYIJ  {
position: relative;
width: 100%;
max-width:300px;
height: 30px;
font-size:20px;
box-sizing: border-box;
}


.FormInputObjects_input_field_date__1UYIJ:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}






.FormInputObjects_inputText_field__3B11j  {
position: relative;
width: 100%;
height: 200px;
font-size:20px;
box-sizing: border-box;
}



.FormInputObjects_inputText_field__3B11j:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}





.FormInputObjects_name_inputDivTimeField__297Az{
border-style: none;
display: flex;
width: 100%;
margin-top: 10px;
}



.FormInputObjects_name_inputDivDayOptions__2MWCp{
border-style: none;
display: flex;
width: 100%;
margin-top: 10px;
}


.FormInputObjects_searchDropDown__3g_cX{
display: flex;
flex-direction: column;
position: absolute;
border-style: none;
border-width: 1px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
width: 100%;
top: 75px;
background-color: white;
z-index:2;
border-radius: 5px;
}


.FormInputObjects_oneSearchOptionButton__3c91-{
width: 100%;
background-color: white;
height: 40px;
border-style: none;
display: flex;
justify-content: flex-start;
align-items: center;
cursor: pointer;
}


.FormInputObjects_oneSearchOptionButton__3c91-:hover{

background-color: var(--cardBorderColor);

}

.FormInputObjects_input_field_searchnInsert__AU05u{
position: relative;
width: 100%;
height: 40px;
font-size:20px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
}


.FormInputObjects_input_field_searchnInsert__AU05u:focus{
    border-style: solid;
    border-width: 2px;
    outline: none !important;
    border-color: #719ECE;
    border-radius:5px;



}











.Logo_logoText__2Te4W{
color: var(--themeColor);
font-size: calc( 0.40 * var(--headerHeight) );
border-style: none;
display: flex;
justify-content: space-between;
align-items: center;
background-color: var(--themeColor);
color: white;
height: calc( 0.40 * var(--headerHeight) );
border-radius: calc( 0.1 * var(--headerHeight) );
padding: calc( 0.16 * var(--headerHeight) );
} 


.Logo_styleBar__3avSA{
width: calc( 0.20 * var(--headerHeight) );
height: calc( 0.07 * var(--headerHeight) );
background-color: var(--themeColor);
position: absolute;
}


.UnitBarInstDegree_unitBarInstDegree__3RKJ5{
width: 100%;

border-style: none;
display: flex;
margin-top: 20px;
}


.UnitBarInstDegree_InstLogo__izn43{

width: 80px;
height: 80px;
margin-left: 30px;
}

.UnitBarInstDegree_InstInfo__2UQWX{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0px;

}


.UnitBarInstDegree_InstInfo__degree__1Pv_b{
  color: #4e4e52;
}

.UnitBarInstDegree_InstInfo__duration__gS49J{
 color: grey;
}


.UnitBarInstDegree_InstInfo__location__36cQ-{
  color: grey;
}



.UnitBarInstDegree_logoImage__27it5{

width:60px;
height: 60px;

}





.UnitBarInstDegree_unitBarInstDegree__1UfVW{
width: 100%;
border-style: none;
display: flex;
margin-top: 20px;
flex-direction: row;
justify-content: space-between;
}

.UnitBarInstDegree_degreeInfoall__1iicU{

display: flex;
}



.UnitBarInstDegree_InstLogo__er9Mh{

width: 80px;
height: 80px;
margin-left: 30px;
}

.UnitBarInstDegree_InstInfo__2pIoz{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0px;

}


.UnitBarInstDegree_InstInfo__degree__3V_lL{
  color: #4e4e52;
}

.UnitBarInstDegree_InstInfo__duration__OVj2c{
 color: grey;
}


.UnitBarInstDegree_InstInfo__location__n2anI{
  color: grey;
}



.UnitBarInstDegree_logoImage__1YzeM{

width:60px;
height: 60px;

}



.UnitBarInstDegree_addressType__1rewm{
width: 150px;
margin-left: 30px;

}


.UnitBarInstDegree_deleteDegree__20qny{
font-size: 25px;
color: grey;
cursor: pointer;
height: 30px;
border-style: none;
}


.UnitBarInstDegree_addMainContainer__25aJ3{

display: flex;

}





.UnitAboutIcon_unitAboutIcon__29QqN{

width: 93%;
height: calc( 0.8 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
display: flex;
align-items: center;
font-size: calc( 0.6 * var(--headerHeight) );
justify-content: space-between;

}

.UnitAboutIcon_unitAboutIcon__29QqN div{
display: flex;
align-items: center;
}


.UnitAboutIcon_aboutIcon__32OPT{
left: calc( 0.3 * var(--headerHeight) );

}


.UnitAboutIcon_unitAboutIcon__29QqN i{
font-size: calc( 0.3 * var(--headerHeight) );
font-style: normal;
margin-left: 10px;
}

.UnitAboutIcon_editButtonAbout__1GR8B{
left: calc( 0.3 * var(--headerHeight) );
background-color: white;
border-style: solid;
border-radius: 5px;
border-width: 1px;
padding:5px;
padding-left: 10px;
padding-right: 10px;
cursor: pointer;
color: var(--themeColor);
border-color: var(--cardBorderColor);
}


.UnitAboutIcon_editButtonAbout__1GR8B:hover{
background-color: var(--themeColor);
color: white;

}









.UserProfileContentBasic_userProfileContentBasic__3aH8r{
background-color: white;
width: 100%;
height: calc( 100% - calc( 0.6 * var(--headerHeight) ) );
border-style: none;
}




.AboutBasic_about_Basic__3bb7C{


width: 100%;
background-color: white;
display: flex;
align-items: center;
flex-direction: column;
border-style: none;
border-color: red;
}


.AboutBasic_aboutInfoContainer__dNpYZ{

width:93%;
display: flex;
align-items: center;
background-color: #E8E8E8;
background-color:var(--userProfileInfoBoxBkgColor);
border-style: none;
border-width: 1px;
border-color: #E8E8E8;
border-color:green;
border-radius:3px;

}



.AboutBasic_aboutInfoContainerEd__Ry0OC{

width:93%;
display: flex;
flex-direction: column;
align-items: center;
background-color: #E8E8E8;
background-color:var(--userProfileInfoBoxBkgColor);
border-style: solid;
border-width: 1px;
border-color: #E8E8E8;
border-color:green;
border-radius:3px;

}












@media only screen and (max-width: 1100px) {
 .AboutBasic_aboutInfoContainer__dNpYZ{
     display: flex;
     justify-content: space-between;
     flex-direction: column;
     width: 100%;
     border-style: solid;
     border-color: cyan;
     background-color: cyan;
 }



}








.AboutBasic_InfoBoxLeft__11GvO{
width: 100%;
border-style: none;
background-color: white;
}


.AboutBasic_InfoBoxRight__9PaCi{
width: 100%;
border-style: none;
background-color: white;
}











.UnitBar_unitBar__1sF_N{

width: 93%;
height: calc( 0.8 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
border-color: grey;

}


.UnitBarFirstName_unitBarFirstName__2Ho6w{

width: 93%;
height: calc( 0.5 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
border-color: grey;
display: flex;
align-items: center;
margin-top:10px;
margin-bottom: 10px;
}


.UnitBarFirstName_firstNameTitle__DENE5{

width:30%;
font-size:calc( 0.25 * var(--headerHeight) );
border-style: none;
border-width:1px;
height:100%;
display: flex;
align-items: center;
border-color: #E8E8E8;
border-color:lightgrey;
position: relative;
padding-left: 20px;
color: grey;
font-weight:bold;
}

.UnitBarFirstName_firstName__3r0AV{
width:70%;
height: 100%;
font-size:calc( 0.25 * var(--headerHeight) );
text-align: left;
display: flex;
align-items: center;
background-color: var(--userProfileInfoBoxBkgColor);
border-style: none;
border-color:lightgrey;
border-width:1px;
position: relative;
padding-left:10px;
}



.UnitBarFirstName_openNewTabL__sVFyS{

margin-left: 20px;
color: grey;
font-size: 20px;
font-weight: bold;
}



.UnitBarFirstName_uploadButton_iddoc__3goXE{

margin-right: 20px;


}



.UnitBarFirstName_image_field__2EEIi{

width: 83px;
margin-right: 20px;
}

.UnitBarFirstName_submit_button__2aRyO{

background-color: var(--themeColor);
color: white;
border-style: none;
padding: 3px;
padding-left: 5px;
padding-right: 5px;
cursor: pointer;
border-radius: 3px;
}






.UnitBarGenDOB_unitBarGenDOB__1nXKD{

width: 93%;
height: calc( 0.7 * var(--headerHeight) );
background-color:#E8E8E8;
background-color:var(--userProfileInfoBoxBkgColor);
border-style: none;
border-width: 1px;
border-color: grey;
display: flex;
align-items: center;
margin-top:10px;
margin-bottom: 10px;
justify-content: space-between;
}


.UnitBarGenDOB_genderDiv__3vaRr{

width:45%;
font-size:calc( 0.25 * var(--headerHeight) );
border-style: none;
border-width:1px;
height:100%;
display: flex;
align-items: center;
border-color:#E8E8E8;
border-color:lightgrey;
position: relative;
padding-left: 20px;
color: grey;
background-color: white;
}

.UnitBarGenDOB_genderDiv__3vaRr i{

font-style: normal;
padding-left: 20px;
}



.UnitBarGenDOB_dobDiv__2Nq7s{
width:45%;
height: 100%;
font-size:calc( 0.25 * var(--headerHeight) );
text-align: left;
display: flex;
align-items: center;
background-color: white;
border-style: solid;
border-color:#E8E8E8;
border-color:lightgrey;
border-width:1px;
color: #454444;
padding-left: 20px;
}

.UnitBarGenDOB_dobDiv__2Nq7s i{
font-style: normal;
padding-left: 20px;
overflow: auto;

}












.AboutEditForm_aboutEditFormDivParent__3R0al{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;
}

.AboutEditForm_aboutEditForm__1RMfr{

position: relative;
width: 40vw;
height:80vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
overflow: auto;
}















.AboutEditForm_closeButtonDiv__1T4Pr{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.AboutEditForm_closeFormButton__1yKLe{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.AboutEditForm_closeButtonIcon__1Hdcv{

font-size: 25px;

}


.AboutEditForm_logoAndTitleContainer__3omu8{


height:150px;
width:80%;
background-color: white;
display: flex;
flex-direction: column;

}

.AboutEditForm_bwLogo__EZ0ZS{

height: 75px;
width: 75px;

}

.AboutEditForm_titleDiv__ZlQX4{
width: 100%;
display:flex;
justify-content:flex-start;
color: #585858;
}

.AboutEditForm_titleDiv__ZlQX4 i{

font-size: 30px;
}


.AboutEditForm_name_div__1O-VN{

display: flex;
flex-direction: column;
width:80%;
align-items: center;
justify-content: center;
margin: 10px;
}

.AboutEditForm_name_txt__18ryL{
width: 100%;
position: relative;
background-color:white;
font-size: 23px;
color: grey;
font-weight: thin;
}

.AboutEditForm_name_inputDiv__1kj17{
width: 100%;
}




.AboutEditForm_genpos_div__2vkcD{

display: flex;
flex-direction: row;
width:80%;
align-items: center;
justify-content: space-between;
margin: 20px;
}


.AboutEditForm_gender_div__1SYT-{
display: flex;
flex-direction: column;
width: 45%;
background-color: red;
border-radius: 5px;
}


.AboutEditForm_position_div__1gJMR{
display: flex;
flex-direction: column;
width: 45%;
background-color: green;
border-radius: 5px;
}



.AboutEditForm_genpos_field__2rKZo{
width:100%;
height: 40px;
font-size: 23px;
padding: 5px;
}


.AboutEditForm_dob_div__2yLNn{
width: 80%;
height: 90px;
display: flex;
flex-direction: column;
background-color: white;
justify-content: flex-start;
align-items: flex-start;
}

.AboutEditForm_dob_txt__36eqq{
width: 100%;
position: relative;
background-color:white;
font-size: 23px;
color: grey;
font-weight: thin;

}

.AboutEditForm_dob_innerDiv__1suzX{
width: 100%;
position: relative;

}

.AboutEditForm_dob_innerDiv2__1xtQT{

position: relative;
width: 45%;

}


.AboutEditForm_dateofbirth_field__3RfUK{
position: relative;
width: 100%;
padding: 5px;
height: 30px;
font-size:23px;
min-width:200px;
}

.AboutEditForm_firstname_field__3Gv7Y , .AboutEditForm_lastname_field__1_srL,.AboutEditForm_gender_field__Pc_5k,.AboutEditForm_position_field__2sGbN {
position: relative;
width: 100%;
padding: 5px;
height: 30px;
font-size:23px;
}


.AboutEditForm_submitButtonDiv__1uqDu{
width: 80%;	
display: flex;
justify-content: center;
background-color: white;
padding-top: 50px;
}


.AboutEditForm_submit_button__3AGq4{
width: 30%;
border-style: none;
background-color: var(--themeColor);
color: white;
height:30px;
width:100px;
border-radius: 10px;
cursor: pointer;
margin-bottom: 50px;
}






.UserProfileContentGrades_userProfileContentGrades__-ykBh{
background-color: white;
width: 100%;
height: calc( 100% - calc( 0.6 * var(--headerHeight) ) );
display: flex;
justify-content: center;

}


.UserProfileContentGrades_userProfileContentGrades__-ykBh i{

font-style: normal;
color: grey;
margin-top: 100px;
font-size: 20px;

}




.UserProfileContentFriends_userProfileContentGrades__3uicL{
width: 100%;
height: calc( 100% - calc( 0.6 * var(--headerHeight) ) );

}


.UserProfileContentFriends_userProfileContentGrades__3uicL i{

font-style: normal;
color: grey;
margin-top: 100px;
font-size: 20px;

}


.UserProfileContentFriends_friendsContainer__TJw_M{
width: 100%;
height: 200px;
background-color: red;
}


.UnitFriendsIcon_unitAboutIcon__3XZyj{

width: 93%;
height: calc( 0.8 * var(--headerHeight) );
border-style: none;
border-width: 1px;
display: flex;
align-items: center;
font-size: calc( 0.6 * var(--headerHeight) );
justify-content: space-between;
margin: auto;

}



.UnitFriendsIcon_FriendsIconDiv__7PQ8T{
display: flex;
align-items: center;
border-style: none;
height: 100%;
justify-content: space-between;
}


.UnitFriendsIcon_FriendsIconDiv__7PQ8T span{
font-size: calc( 0.3 * var(--headerHeight) );
font-style: normal;
margin-left: 10px;


}


.UnitFriendsIcon_aboutIcon__EiqTR{
left: calc( 0.3 * var(--headerHeight) );
border-style: none;
}


.UnitFriendsIcon_unitAboutIcon__3XZyj i{/*
font-size: calc( 0.3 * var(--headerHeight) );
font-style: normal;
margin-left: 10px;
border-style: solid;
*/
}




.UnitFriendsIcon_editButtonAbout__3pXBu{
left: calc( 0.3 * var(--headerHeight) );
background-color: white;
border-style: solid;
border-width: 1px;
border-radius: 5px;
padding:5px;
padding-left: 10px;
padding-right: 10px;
cursor: pointer;
color: var(--themeColor);
border-color: var(--cardBorderColor);
}





.AddContactForm_addContactForm__3Zeku{


position: fixed;
width: 90vw;
max-width:600px;
height:80vh;
background-color: grey;
z-index: 10000;
bottom:0;
background-color:white;
border-style: none;
box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
/*display: flex;
align-items: center;
justify-content: center;
*/
border-radius: 4px;
}


.AddContactForm_closeButtonDiv__3QeS6{
background-color: lightgrey;
display: flex;
height: 30px;
font-size: 20px;
justify-content: space-between;
align-items: center;
}


.AddContactForm_titleSec__P2tTe{
border-style: none;
color: white;
margin-left: 30px;
}

.AddContactForm_closeFormButton__3kBun{
border-style: none;	
font-size: 20px;
background-color: lightgrey;
cursor: pointer;
}

.AddContactForm_closeButtonIcon__1ripw{
font-size: 23px;
}


.AddContactForm_searchBarDiv__3z1Sm{
height: 100px;
width: 100%;
border-style: none;
display: flex;
justify-content: flex-start;
align-items: center;
}

.AddContactForm_searchIconDiv__2V0NW{

color: grey;
margin-left: 20px;
}

.AddContactForm_input_field__9UYqR{

height: 40px;
margin-left: 20px;
width: 70%;
font-size: 20px;

}


.AddContactForm_aboutEditForm__3bm_j{
border-style: none;
}

.AddContactForm_searchedUsersGrid__1E_GK{


width: 93%;
border-style: none;
margin: auto;
/*
display: grid;
grid-template-columns: repeat(auto-fit, 1fr );
grid-template-rows: 1fr ;
grid-column-gap: 50px;
grid-row-gap: 30px;
*/
margin-top: 20px;
overflow-y: scroll;
overflow-x: hidden;
height: 500px;
}


.AddContactForm_oneFriend__3unYt{
width: 150px;
height: 190px;
border-style: none;
display: flex;
align-items: center;
flex-direction: column;
background-color: red;
}








.OneFriend_oneFriend__VQFHA{


width: 100%;
height: 120px;
border-style: none;
display: flex;
align-items: center;
margin-top: 30px;
}


.OneFriend_friendImage__1M9jc{

width:100px;
height:100px; 
margin: auto;
border-radius: 50%;
float: left;
border-style: none;
}

.OneFriend_InfoBox__39IYl{
height: 100%;
width: 100%;
color: grey;
border-style: none;
margin-left: 30px;
}

.OneFriend_fullName__1lGud{


}


.OneFriend_addContactButton__3EE8p{
color: white;
background-color: var(--themeColor);
border-style: none;
border-radius: 3px;
padding: 3px;
cursor: pointer;
}





.AllFriends_allFriends__299Wz{

width: 93%;
border-style: none;
margin: auto;
display: grid;
grid-template-columns: repeat(auto-fit, minmax(200px, 1fr) );
grid-template-rows: 1fr ;
grid-column-gap: 50px;
grid-row-gap: 50px;
margin-top: 20px;

}



.AllFriends_searchBar_contact__eAyZr{
width: 80%;
height: 40px;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
margin: auto;
display: flex;
align-items: center;
}

.AllFriends_searchIcon__2DeNg{
margin-left: 15px;
font-size: 25px;
color: grey;
}



.OneFriend_oneFriend__1qxur{


width: 150px;
height: 190px;
border-style: solid;
display: flex;
align-items: center;
flex-direction: column;
background-color: white;
padding: 30px;
border-color: var(--cardBorderColor);
border-width: 1px;
border-radius: var(--cardBorderRadius);
}


.OneFriend_friendImage__2S223{

width:150px;
height:150px; 
margin: auto;
border-radius: var(--cardBorderRadius);
}

.OneFriend_InfoBox__1L6_T{
height: 40px;
color: grey;
border-style: none;
}


.MainAppContainer_mainAppContainer__2amSx{
position: relative;
/*background-color: red;#E8E8E8;*/
width: calc( 99% - var(--sideNavBarWidth)  );
top: var(--headerHeight);/* calc( var(--headerHeight) + var(--headerTopMargin) );*/
left: calc( var(--sideNavBarWidth) + 0.5% );
/*display: grid;
grid-template-columns: 1fr;
grid-template-rows: calc(0.5 * var(--headerHeight)) calc(0.9 * var(--headerHeight)) 1fr;
grid-row-gap: calc( 0.20 * var(--headerHeight) );*/
transition: 0.5s;
border-style: none;
height: calc( 100vh - var(--headerHeight) );
min-width: 400px;
border-style: none;
border-color: green;
border-width: 1px;
overflow-x: hidden;
overflow-y: hidden;
}




.GeneralContentDiv_contentDiv__3RQmf{
position: relative;
border-style: none;
border-width: 1px;
border-color: cyan;
width: 100% ;
height: 100%;
/*border-radius: 10px 0px 0px 0px;*/
overflow-x: hidden;
overflow-y: auto;
}


.GeneralContentDiv_createDocButton__KpwHW{

margin-top:30px;
margin-left: 30px;
padding: 10px;
border-radius: 5px;
border-style: solid;
border-color: var(--themeColor);
cursor: pointer;
}

.GeneralContentDiv_documentArea__1v7l3{


width:98%;
height:90%;
border-style: none;
margin: auto;
display: flex;
border-color: red;
}

.GeneralContentDiv_documentNames__2fTVZ{
width: 200px;
height: 100%;
border-style: solid;
border-color: grey;
}

.GeneralContentDiv_documentTextArea__1QzG3{

width: calc( 100% - 250px );
height: 100%;
border-style: none;
display: flex;
flex-direction: column;
}

.GeneralContentDiv_saveButton__3PihL{

height: 40px;
width: 150px;
background-color: var(--themeColor);
cursor:pointer;
}




.GeneralContentDiv_textAreaInput__2xbpA{
width: 100%;
height: 95%;
padding: 20px;
max-width: 1000px;
font-size: 20px;
}





.AppContentDiv_appContentDiv__1bhe2{
/*background-color: white; /*var(--mainAppContainerBkgColor);*/
border-style: none;
border-width: 2px;
border-color: red;
width: 100% ;
height: 100%;
position: relative;
background-color: var(--bodyBkgColor);/*var(--lightThemeColor2);*/
overflow-x: hidden;
overflow-y: hidden;
border-radius: var(--cardBorderRadius) 0px 0px 0px;
}

.AppContentDiv_pwdAppDirectory__2FivM{
display: flex;
align-items: center;
border-style: none;
height: calc( 0.7 * var(--headerHeight) );
}

.AppContentDiv_pwdAppDirectoryText__2cJTq{
font-style: normal;
margin-left: 50px;
color: grey;
font-size: 10px;
}




.createdocForm_createdocForm__24Of0{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;


}



.createdocForm_createFormInner__2B0y7{




position: relative;
width: 80vw;
height:80vh;
max-width:800px;
min-width:370px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
overflow-y: scroll;

}

.createdocForm_closeButtonDiv__11YMg{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.createdocForm_closeFormButton__1m4LY{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.createdocForm_closeButtonIcon__3ShN4{

font-size: 25px;

}


.createdocForm_createFormDiv__2Pyvj{

width: 80%;
margin-top:40px;
border-style: none;
height: 80%;
margin: auto;
}


.createdocForm_buttonDiv__2i8S0{

width: 80%;
margin: auto;
border-style: none;
display: flex;
}



.createdocForm_createButton__1z2at{

padding: 10px;
margin: auto;
margin-top: 100px;
width: 150px;
border-style: none;
background-color: var(--themeColor);
border-radius: 10px;
color: white;
cursor: pointer;
}










.DocumentNames_DocumentNames__MyH4w{

width: 250px;
border-style: none;
border-width: 1px;
border-color: grey;
margin-top: 30px;
}


.DocumentNames_docnameButton__FKlfa{
width: 100%;
height: 40px;
cursor: pointer;
display: flex;
justify-content: flex-start;
padding-left: 10px;
align-items: center;
overflow:hidden;
font-weight: bold;
background-color: white;
margin-top:5px;
border-style: solid;
border-width: 1px;
border-radius: 5px;
}


.Login_mainDiv__1hvpq {
  /* width: 100vw;
  height: 100%;

  width: 99%;
  height: 99%; */

  display: flex;
  justify-content: center;
  align-items: center;
  height: 99vh;
  width: 99vw;
  margin: auto;
  border-style: none;
}

.Login_subMainDiv__G_Vwp {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;


  width: 800px;
  aspect-ratio:3/2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;
  border-style: none;
}

.Login_image__804er {

  width: 100%;
  height: 100%;
}
.Login_coverImg__12skX {
  /* width: 450px;
  height: 550px; */
  width: 100%;
  height: 100%;
}

.Login_parentdiv__27AGB {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  /* width: 450px;
  height: 550px; */
  width: 100%;
  height: 100%;
  background-color: white;
  justify-content: space-between;
}

.Login_topBar__Tov5J {
  border-bottom: 1px solid;
  border-bottom-color: var(--cardBorderColorLight);

  /* height: 15%; */
  display: flex;
  justify-content: center;
  align-items: center;

  height: 12%;
}

.Login_logoContainer__6N-R6 {
  background-color: var(--themeColor);
  width: 50px;
  height: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.Login_logoContainer__6N-R6:hover {
  cursor: pointer;
  border: none;
}

.Login_textDI__1I-pF {
  font-size: 24px;
  color: white;
  font-weight: 800;
}

.Login_loginTitle__3GxDX {
  color: var(--greyTextColor);
  margin-left: 10px;
  font-size: 26px;
  font-weight: 400;
}

.Login_midContent__59rBg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}
.Login_loginTitleText__3rXcp {
  width: 60%;
  font-size: 18px;
  color: var(--deepDarkThemeTextColor);
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-decoration: underline; */
}

.Login_mainContainerA1__2HsnN{
  width: 60%;
  margin-top: 20px;
  display: flex;
  align-items: start;
  justify-content: start;
  /* border: 1px solid red; */
}

.Login_mainContainer__23-Yo {
  width: 60%;
  margin-top: 16px;
  display: flex;
  align-items: start;
  justify-content: center;
  position: relative;
  flex-direction: column;
  
}


.Login_fieldtype__3hT3U{
font-size: 7px;
margin-left:10px;
margin-top:5px;
color: red;
max-width: 70%;
}



.Login_infoAboutMobEmail__bX14f{
position: absolute;
top: 28px;
background-color: white;
color: grey;
z-index: 999;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
padding: 20px;
border-radius: 10px;
visibility: hidden;
height:200px;
}


.Login_infoLine__2Pcxy{

margin-top:10px;
}

.Login_mainContainer__23-Yo:hover .Login_infoAboutMobEmail__bX14f{

visibility: visible;
}





.Login_flagImg__1YxNN {
  height: 26px;
  width: 26px;
  border-radius: 2px;
}

.Login_countryCode__383up {
  margin-left: 10px;
  color: var(--greyTextColor);
  font-size: 14px;
  display: flex;
  border-style: none;
  height: 100%;
  align-items: center;
  padding-right:5px;
}
.Login_editmobileNuBox__LtNp9 {
  background-color: var(--bodyBkgColor);
  border-radius: 4px;
  /* background-color: #f0f2f5; */
  height: 28px;
  width: 100%;
  padding-left: 10px;
  border: 1px solid var(--cardBorderColorLight);
  font-size: 14px;
}

.Login_editmobileNuBox__LtNp9:focus {
  padding-left: 8px;
  padding-right: 8px;
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColorLight);
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_captcha__2Stx_ {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  /* border: 1px solid red; */
}
.Login_grecaptcha__BcVdo {

  /* height: 40px;
  width: 60%; */
  /* place-items: center; */
  display: flex;
  justify-content: center;
  /* align-self: center;
  transform: scale(0.69);
  -webkit-transform: scale(0.69);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0; */
  transform:scale(0.6);
  transform-origin:1 0;
}

.Login_sendOtpbtn__3l5P0 {
  margin-top: 20px;
  background-color: var(--themeColor);
  color: white;
  height: 30px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  border: none;
  /* border: 1px solid red; */
}

.Login_sendOtpbtn__3l5P0:hover {
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  cursor: pointer;
}



.Login_createBtn__12tlS {
  margin-top: 20px;
  background-color: var(--themeColor);
  color: white;
  height: 30px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  border: none;
  /* border: 1px solid red; */
}

.Login_createBtn__12tlS:hover {
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  cursor: pointer;
}




.Login_horizontalContainer__3aXFd {
  margin-top: 30px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.Login_horizontalContainer2__ltRVI{
  margin-top: 30px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login_leftLine__21AtR {
  width: 50%;
  border-bottom: 1px solid var(--cardBorderColorLight);
  border-bottom-color: var(--cardBorderColorLight);
}

.Login_orText__QD3S4 {
  font-size: 10px;
  padding: 4px;
  font-weight: 700;
  border: 1px solid var(--cardBorderColorLight);
  border-radius: 50%;
  color: var(--cardBorderColorLight);
  background-color: var(--themeColor);
}
.Login_rightLine__bz1PQ {
  width: 50%;
  border-bottom: 1px solid var(--cardBorderColorLight);
  border-bottom-color: var(--cardBorderColorLight);
}

.Login_signInContainer__3jSMf {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 60%;
  /* min-width: 300px; */
}

.Login_googleSignINContainer__2rGRq {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid var(--cardBorderColorLight);
  border-radius: 4px;
}

.Login_googleSignINContainer__2rGRq:hover {
  background-color: white;
  cursor: pointer;
}

.Login_googleICON__3YHqP {
  margin-left: 10px;
}

.Login_signInGoogleTitle__3Zgyp {
  font-size: 13px;
  color: var(--deepDarkThemeTextColor);
  margin-left: 10px;
}

.Login_facebookICON__3Br47 {
  margin-left: 10px;
  color: #3b5998;
}

.Login_facebookSignINContainer__1qsVF {
  height: 30px;

  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid var(--cardBorderColorLight);
  border-radius: 4px;
}

.Login_facebookSignINContainer__1qsVF:hover {
  background-color: white;
  cursor: pointer;
}

.Login_signInFacebookTitle__2Lu2R {
  font-size: 13px;
  color: var(--deepDarkThemeTextColor);
  margin-left: 10px;
}

.Login_linkedinICON__e_OqP {
  margin-left: 10px;
  color: #0a66c2;
}

.Login_linkSignINContainer__NE2zV {
  height: 30px;

  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid var(--cardBorderColorLight);
  border-radius: 4px;
}

.Login_linkSignINContainer__NE2zV:hover {
  background-color: white;
  cursor: pointer;
}

.Login_signInLinkTitle__2rKJ6 {
  font-size: 13px;
  color: var(--deepDarkThemeTextColor);
  margin-left: 10px;
}

.Login_textContainer__119bN {
  height: 50px;
  width: 400px;
  margin-top: 20px;
  font-size: 14px;
  color: var(--greyTextColor);
  border: 1px solid red;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
}

.Login_hintText__2KKvj {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Login_termTitle__KxXd_ {
  color: var(--themeColor);
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
}

.Login_privacyTitle__pJRyX {
  color: var(--themeColor);
  font-weight: 500;
  margin-left: 10px;
}

.Login_bottomBar__34lUE {
  border-top: 1px solid;
  border-top-color: var(--cardBorderColorLight);
  /* height: 15%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 12%;

}

.Login_bottomTopBar__2SKye {
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login_textTitleBottom__iSmC0 {
  margin-top: 20px;
  font-size: 12px;
  color: var(--greyTextColor);
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.Login_textTitleBottom1__2bYiD {
  /* margin-top: 20px; */
  font-size: 12px;
  color: var(--greyTextColor);
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.Login_contactUS__2q2rd {
  color: var(--greyTextColor);

  border: none;
  background-color: transparent;
}
.Login_contactUS__2q2rd:hover {
  color: var(--themeColor);
  cursor: pointer;
  font-weight: 500;
}

.Login_termOfService__3vgfZ {
  color: var(--greyTextColor);

  border: none;
  background-color: transparent;
}
.Login_termOfService__3vgfZ:hover {
  color: var(--themeColor);
  cursor: pointer;
  font-weight: 500;
}

.Login_privacyText__2Ewny {
  color: var(--greyTextColor);

  border: none;
  background-color: transparent;
}

.Login_privacyText__2Ewny:hover {
  color: var(--themeColor);
  cursor: pointer;
  font-weight: 500;
}

.Login_texttitle__dkV_f {
  font-size: 14px;
  color: var(--deepDarkThemeTextColor);
  margin-right: 10px;
}

.Login_create__1wBkM {
  margin-left: 6px;
  font-size: 14px;
  font-weight: 600;
  color: var(--themeColor);
  background-color: transparent;
  border: none;
}

.Login_create__1wBkM:hover {
  cursor: pointer; 
  border-radius: 4px;
  text-decoration: underline;
}


/* ============================================================================= */

/* ACCOUNT SUCCESSFULLY CREATED PAGE CSS */

.Login_mainContainerOpt__WD3TU {
  margin-top: 50px;
  display: flex;
  width: 300px;
  max-width: 300px;
  justify-content: space-around;
}

.Login_num1Box__1d8u8 {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num1Box__1d8u8:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num2Box__MWtNE {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num2Box__MWtNE:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num3Box__1f7Wm {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num3Box__1f7Wm:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num4Box__29JVl {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num4Box__29JVl:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num5Box__1-Rtr {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num5Box__1-Rtr:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_procedToLogin__Yd9vu {
  background-color: var(--themeColor);
  color: white;
  height: 30px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  border: none;
}


.Login_procedToLogin__Yd9vu:hover{
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  cursor: pointer;
}


.Login_successIconDiv__2YGEa {
  height: 50px;
  width: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Login_iconSuccess__29FCr {
  height: 40px;
  width: 40px;
  color: var(--themeColor);
}

/* ============================================================================= */


/* OTP PAGE CSS */

.Login_mainContainerOpt__WD3TU {
  margin-top: 50px;
  display: flex;
  width: 300px;
  max-width: 300px;
  justify-content: space-around;
}

.Login_num1Box__1d8u8 {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num1Box__1d8u8:focus {

  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num2Box__MWtNE {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num2Box__MWtNE:focus {

  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num3Box__1f7Wm {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num3Box__1f7Wm:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num4Box__29JVl {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num4Box__29JVl:focus {

  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num5Box__1-Rtr {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num5Box__1-Rtr:focus {

  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}


.Login_submitOtpbtn__1weCo{
  margin-top: 50px;
  background-color: var(--themeColor);
  color: white;
  height: 30px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  border: none;
}

.Login_submitOtpbtn__1weCo:hover {
  margin-top: 50px;
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  cursor: pointer;
}



.Login_userExistSymbol__3APnD{
color: green;
}


.Login_userExistSymbol2__ffgqh{
color: red;
}




.Login_sendOTPButton__3JHfi{

background-color: var(--themeColor);
padding: 10px;
border-style: none;
border-radius: 5px;
color: white;
}



.Login_diracAIButton__1HgUj{
border-style: none;
background-color: white;
cursor: pointer;
}
.Login_logologin__2zeBH{

background-color:#224C98;
border-radius: 5px;
color: white;
font-weight: bold;
padding: 5px;
margin-right: 5px;
}



/* =====================================================================*/
@media (max-width: 900px) {
  .Login_mainDiv__1hvpq {
    /* width: 100%; */
    /* aspect-ratio: 45/60; */
  }

  .Login_subMainDiv__G_Vwp {
    width: 90%;
    max-width: 400px;
    height: 90%;
    padding: 10px;

    border-style: none;
  }

  .Login_image__804er {
    display: none;
  }

  .Login_parentdiv__27AGB {
    width: 100%;
  }

  /* .image {
    width: 100%;
    height: 300px;
  }

  .parentdiv {
    width: 100%;
    height: 100%;
    max-width: 500px;
  }

  .topBar {
    height: 10%;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .midContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
  }

  .bottomBar {
    margin-top: 20px;
    padding-top: 20px;
    height: 10%;
  } */
}

@media (min-width: 1400px) {
  .Login_subMainDiv__G_Vwp {
    width: 900px;
  }

  .Login_grecaptcha__BcVdo{
    transform:scale(0.8);
  transform-origin:1 0;
  }
}

.OTPinputUnit_otpUnit__2hz5Y{

height: 40px;
width: 30px;
font-size: 20px;
border-style: solid;
border-width: 1px;
border-radius: 5px ;
border-color: var(--cardBorderColor);
display: flex;
justify-content: center;
padding:5px;
overflow: hidden;
}

.OTPinputUnit_otpUnit__2hz5Y:focus{
border-style: solid;
border-width: 1px;
outline: none !important;
border-color: var(--themeColor);
border-radius:5px;
align-items: center;
padding:5px;
}




.LoginHeader_loginHeader__37kTg{

height: var(--headerHeight);
width: 100%;
display: flex;
align-items: center;
border-bottom: none;
border-color: lightgrey;
border-width: 1px;
/*box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
*/

}

.LoginHeader_homeIcon__2arrj{

height: calc( 0.6 * var(--headerHeight) );
width: calc( 0.6 * var(--headerHeight) );
margin-left: 50px;
color: var(--themeColor);
}


.LoginHeader_loginHeader__37kTg i{
font-style: normal;
color: var(--themeColor);
margin-left: 30px;
text-decoration: none;
}

.LoginHeader_goBackHome__1EASl{
border-style: none;
background-color: var(--lightThemeColor1) ;
cursor: pointer;


}

.Register_registerParent__Vi08B{
border-style: none;
height:100vh;
width:100vw;
align-items: center;
background-color: var(--bodyBkgColor);
}


.Register_registerDiv__3lmzt{
width: 100%;
max-width: 500px;
border-style: solid;
height: 85vh;
border-radius: var(--cardBorderRadius);
margin: auto;
background-color: white;
display: flex;
flex-direction: column;
margin-bottom: 30px;
padding-bottom: 30px;
border-color: var(--cardBorderColor);
border-width: 1px;
}


.Register_header__2Xg5e{

border-style: solid;
width: 100%;
height: 150px;
display: flex;
flex-direction: column;
justify-content: center;
color: var(--themeColor);
}



.Register_titleDiv__2gQBo{
width: 80%;
height:100px;
margin-top:100px;
color: var(--deepDarkThemeTextColor);
border-style: none;
margin: auto;
display: flex;
flex-direction: row;
justify-content: center;
display: flex;
align-items: center;
}

.Register_switchMethodDiv__1DbNq{
width: 80%;
height:30px;
border-style: none;
margin: auto;
}

.Register_phoneNumberDiv__15pp-{
width: 80%;
height:115px;
border-style: none;
margin: auto;

}

.Register_phonetitle__1C9d9{
font-size: 18px;
height:30px;
}

.Register_enterPhoneDiv__2G-Hj{
height: 50px;
border-style: none;
display: flex;
justify-content: space-between;

}


.Register_enterOTPDiv__10EGo{

height: 50px;
border-style: none;
display: flex;
justify-content: space-between;


}


.Register_otpUnit__chrFq{

height: 40px;
width: 30px;
font-size: 25px;
border-style: solid;
border-radius: calc( 0.5 * var(--cardBorderRadius) ) ;
border-color: var(--cardBorderColor);

}









.Register_phonetnc__YWKMh{
font-size: 12px;
height: 25px;
color: grey;
border-style:none;
display:flex;
justify-content:center;
flex-direction:column;
}




.Register_usertypeDiv__2wCT5{
width: 80%;
height:100px;
border-style: none;
margin: auto;
padding-top: 50px;

}




.Register_countryCodes__1DMg4{

width:150px;
display: flex;
align-items: center;

}


.Register_countryCodes__1DMg4 i{

font-style: normal;
font-size: 20px;
color: grey;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


.Register_usernameInput__3eCJh{
width: 100%;
height: 100%;
font-size: 22px;
box-sizing: border-box;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
border-radius: var(--cardBorderRadius);
padding-left: 5%;
}

.Register_usernameInput__3eCJh:focus{
outline: none !important;
border-style: solid;
border-width: 2px;
border-color:var(--themeColor);

}





.Register_inflag__3tiMY{
width:60px;
height:40px;

}


.Register_chooseUserTypeTitle__gJbIW{
height:30px;
display:flex;
flex-direction: column;
justify-content: center;
border-style: none;
font-size: 18px;
}



.Register_userTypeOptions__8dLBz{

height:70px;
border-style: none;
}



.Register_userTypeOptions__8dLBz{
display: flex;
flex-direction: column;
justify-content: center;

}


.Register_userTypeOptionsInner__X6YdO{
display: flex;
justify-content:space-between;
color: var(--themeColor);
font-weight: bold;

}





.Register_submitButtonDiv__3xtvF{
width: 80%;
height:50px;
border-style: none;
margin: auto;
margin-top: 50px;
display: flex;
justify-content: center;
}


.Register_sendOTPButton__1UEyT{

height: 40px;
width:60%;
border-style: none;
cursor: pointer;
border-radius: var(--cardBorderRadius);
}

.Register_radioButton__38flq{
cursor: pointer;
height:20px;
width: 20px;
}


.Register_linkToAccountCreation__2lvWU{
border-style: none;
background-color: white;
text-decoration: underline;
color: var(--themeColor);
cursor: pointer;
}




.Website_website__3ORt4 {
  align-items: center;
}

.Website_innerDiv__1ZsT8 {
  width: 1200px;
  background-color: #fff;
  margin: 0 auto;
  border-style: none;
}

.Website_headrparent__2fKxK {
  border: 1px solid red;
}

.Website_backgroundPicDiv__3kmqw {
  border-style: solid;
}

@media (max-width: 1300px) {
  .Website_innerDiv__1ZsT8 {
    width: 100%;
  }
}

.Website_topbar__anUC0 {
  width: 100%;
  border: 1px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Website_rightContainer__2AE32 {
  display: flex;
}

.Website_firstTopContainer__2uY4n {
  display: flex;
}

.Website_emailIcon__19qs5 {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  background-color: #5e5d5d;
}

.Website_textEmail__16KuD {
  margin-left: 10px;
}

.Website_secTopContainer__2ET2l {
  margin-left: 20px;
  border: 1px solid green;
  display: flex;
}

.Website_mobileIcon__2O9WV {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  background-color: #5e5d5d;
}

.Website_mobileNumber__2w1sT {
  margin-left: 10px;
}

.Website_socialiconContainer__1h4ka {
  background-color: #207ef5;
  border: 1px solid red;
  display: flex;
  margin-right: 10px;
}

.Website_fbicon__3hwe_ {
  margin: 10px;
  background-color: grey;
  height: 26px;
  width: 26px;
}

.Website_topNavigationBar__3EW1U {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid red;
}

.Website_nFirstCotainer__2qiTx {
  display: flex;
  align-items: center;
}
.Website_logo__ynx4l {
  height: 50px;
  width: 100px;
  background-color: #207ef5;
}

.Website_Name__N44mm {
  margin-left: 10px;
  font-size: 26px;
  font-weight: bold;
}
.Website_navigationMenu__2OmLS {
  display: flex;
}

.Website_websiteContent2__WeWp- {
  margin-top: 50px;
  width: 100%;
  display: flex;
}

.Website_HomeImageContainer2__PqF-W {
  width: 50%;
  background-color: green;
}

.Website_detailContainer2__4wSGl {
  margin-left: 20px;
  width: 50%;
}

.Website_aboutHeading1__25fOC {
  color: var(--themeColor);
  font-size: 20px;
}

.Website_aboutHeading2__3EP-9 {
  margin-top: 20px;
  color: var(--darkText);
  font-size: 40px;
}

.Website_aboutDetails__11A4r {
  margin-top: 20px;
  width: 280px;
  font-size: 16px;
  color: var(--lightText);
}

.Website_footer__33Njg {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #207ef5;
  padding: 10px;
}

.Website_block1__12h2g {
  width: 30%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Website_logotextContainer__1JupW {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Website_logopic__21Cll {
  width: 100px;
  background-color: white;
  height: 50px;
}

.Website_websiteNametext__1SvRE {
  margin-left: 10px;
  font-size: 20px;
}

.Website_websiteDetail__1dq_v {
  margin-top: 10px;
  color: white;
  font-size: 14px;
}

.Website_block2__3nH00 {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Website_cont1__2uIM7 {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Website_cont1Icon__w33_1 {
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
}

.Website_cont1Text__I0T9g {
  margin-left: 10px;
  color: white;
}

.Website_block3__HGa8- {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Website_block4__2SeYR {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Website_cont4__2wGL3 {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Website_cont4Icon__3GNZb {
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 30px;
}

.Website_cont4textContainer__2x7AE {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.Website_backgroundImage__yo5OG {
/*   

  background-image: url("https://media.istockphoto.com/id/941562950/photo/hand-holding-another-hand.jpg?s=1024x1024&w=is&k=20&c=2olan6rtEiTXh8DskRG1QeZHRxijJJv_iYLAbfTXnRc=");

  background-image: url("https://media.istockphoto.com/id/625736338/photo/stack-of-hands-showing-unity.jpg?s=2048x2048&w=is&k=20&c=m0q5TaMHzRZEzojxhGIFsFMCwWdHdkFwqtAAi1Bf67Q="); */

  /*background-image: url("heart.png");*/
  background-color: #d4d9d6;
  background-color: #e8ebe9;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Optional: This property ensures that the background image remains fixed when scrolling */
  border-style: solid;
  border-color: var(--themeColor);
}

/* .backgroundImage::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 128, 0, 0.1); 

  } */
/* ================================================ */
/* .website {
  position: relative;

} */

.Website_carousel-content__3ReBK{
  transition-timing-function: ease-out;
  
}

.Website_mainContainer__3iHi- {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Website_arrow__1fjXT {
  top: 50%;
  font-size: 24px;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  transform: translateY(-50%);
}

.Website_left-arrow__3YI_Q {
  visibility: hidden;

}

.Website_right-arrow__1OKf5 {
  visibility: hidden;

}

.Header_topbar__dXW4p {
  width: 100%;
  background-color: var(--themeColor);
  display: flex;
  justify-content: center;
}

.Header_parentFooter__2QReY {
  padding-top: 20px;
  width: 80%;
}

.Header_inner_topbar__3UBnv {
  width: 1200px;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  justify-self: center;
  align-self: center;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  background-color: var(--themeColor);
}

.Header_topNavigationBar__axKlu {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-style: none;
  border-color: red;
  align-items: center;
  /* background-color: var(--themeColor); */
  border-radius: 10px;
  background-color: #e7efeb;
  /* border: 1px solid var(--themeColor); */

  /* margin-top: 25px; */
}

/* .inner_topbar {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  background-color: var(--themeColor);
  margin: auto;  
} */

.Header_rightContainer__1d4DV {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_firstTopContainer__3ccc- {
  width: 100%;
  display: flex;

  /* justify-content: flex-end;
  align-items: center; */
}

.Header_emailIcon__34qRn {
  margin-left: 10px;
  color: var(--lightThemeColor1);
}

.Header_textEmail__1mS9H {
  margin-left: 10px;
  color: var(--lightThemeColor1);
}

.Header_secTopContainer__2fOrM {
  margin-left: 20px;
  display: flex;
}

.Header_mobileIcon__39cAi {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  background-color: #5e5d5d;
}

.Header_mobileNumber__3tb6w {
  margin-left: 10px;
}

.Header_socialiconContainer__1xMNK {
  display: flex;
  justify-content: end;
  align-items: end;
  margin-right: 10px;
}

.Header_fbicon__Ol6I8 {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  border-style: none;
  background-color: var(--lightThemeColor1);
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.Header_parentFooter__2QReY {
  width: 100%;
}

.Header_nFirstCotainer__ola8h {
  display: flex;
  align-items: center;
  border-style: none;
  background-color: transparent;
}

.Header_nFirstCotainer__ola8h{
  cursor: pointer;
}

.Header_navigationMenu__1eXfn {
  display: flex;
  border-style: none;
}

@media (max-width: 800px) {
  .Header_inner_topbar__3UBnv {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: space-between;
  }

  .Header_topNavigationBar__axKlu {
    width: auto;
  }

  .Header_socialiconContainer__1xMNK {
  }

  .Header_navigationMenu__1eXfn {
    flex-direction: column;
  }
}

.Header_logo__2kbWP {
  height: 50px;
  width: 60px;
}

.Header_Name__2MHD5 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: var(--themeColor);
}

.Header_headerButtonDiv__1Ts3n {
  margin-left: 30px;
  border-style: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.Header_headerButton__10qwG {
  font-family: Roboto;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-style: none;
  cursor: pointer;
  background-color: transparent;
}

.Header_headerButton__10qwG:hover {
  color: var(--themeColor);
}

.Header_underlineDiv__2COYu {
  height: 3px;
  width: 80%;
  border-radius: 10px;
  margin-top: 3px;
}

.Header_headerButtonText__1tl9I {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--themeColor);
  font-weight: bold;
}

.Header_dropdownOne__2kQgZ {
  height: 130px;
  width: 170px;
  border-style: solid;
  position: absolute;
  padding: 5px;
}

.Header_csslogo__2NidG {
  font-size: 30px;
  font-weight: bold;
  background-color: var(--themeColor);
  color: white;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /*background-image: linear-gradient(to right, var(--themeColor), #d7e7fc);*/
}

.Header_styleDiv1__QXdpT {
  width: 10px;
  color: red;
  height: 4px;
  position: absolute;
  border-style: none;
  top: 21px;
  left: 10px;
  z-index: 9999;
  border-radius: 0px 5px 5px 0px;
  background: transparent;
  background-color: var(--themeColor);
}

.Header_styleDiv2__2WZ6R {
  width: 10px;
  color: red;
  height: 4px;
  position: absolute;
  border-style: none;
  top: 13px;
  left: 10px;
  z-index: 9999;
  border-radius: 0px 5px 5px 0px;
  background-color: var(--themeColor);
}

.Header_fbicon1__1NWLA {
visibility: hidden;
}

@media (max-width: 1300px) {
  .Header_topNavigationBar__axKlu {
    width: auto;
  }

  .Header_inner_topbar__3UBnv {
    margin: auto;
    width: 80%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Header_textEmail__1mS9H {
    font-size: 14px;
  }

  .Header_fbicon__Ol6I8 {
    width: 20px;
    height: 20px;
    margin: 3px;
  }
  .Header_rightContainer__1d4DV {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Header_emailIcon__34qRn {
    height: 15px;
    width: 15px;
  }

  .Header_firstTopContainer__3ccc- {
    display: flex;
    justify-content: start;
    align-items: center;
  }
}



.Header_loginButton__pGizD{

  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  border-style: none;
  background-color: var(--lightThemeColor1);
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  width: 100px;


}












@media (max-width: 800px) {
  .Header_socialiconContainer__1xMNK {
    visibility: hidden;
  }
}

.Header_HomeText__kKiR3 {
  font-weight: bold;
  color: var(--themeColor);
}



.DropDownOne_dropdownOne__fwXHo{
width: 200px;
border-style: none;
position: absolute;
padding: 5px;
background-color: white;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
padding: 15px;
border-radius: 10px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}



.DropDownOne_dropdownTwoDiv__1Q_Op{
width: 150px;
border-style: none;
position: absolute;
background-color: white;
display: flex;
flex-direction: column;
justify-content: flex-start;
visibility: hidden;
left: 50px;
padding: 20px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
border-radius: 10px;
}




.DropDownOne_dropdownOneDiv3__2a6zQ:hover .DropDownOne_dropdownTwoDiv__1Q_Op{

visibility:visible;	

}







.DropDownOne_dropDownTwoButton__FYxL5{

 border-style: none;
 background-color: white;
 font-size: 16px;
 padding: 10px;
 cursor: pointer;
 height: 40px;
 display: flex;
 justify-content: flex-start;
}


.DropDownOne_dropDownTwoButton__FYxL5:hover{

color: var(--themeColor);	
}



.Footer_footer__2Vjt9 {
  /* margin-top: 40px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  background-color: #eff6ff;
  padding-top: 30px;
  padding-bottom: 30px;
  color: black;

  /* margin-top: 50px; */
}

.Footer_mainFooterContainer__1PWNn {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, auto));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 0px;
  column-gap: 0px;

  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.Footer_cont44__38c6R{
  visibility: hidden;


}

@media (max-width: 1300px) {
  .Footer_mainFooterContainer__1PWNn {
    width: 80%;
  }
}

.Footer_csslogo__35LMF {
  font-size: 35px;
  font-weight: bold;
  background-color: var(--themeColor);
  color: white;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /*background-image: linear-gradient(to right, var(--themeColor), #d7e7fc);*/
}

.Footer_styleDiv1__31YEl {
  width: 10px;
  color: red;
  height: 4px;
  position: absolute;
  border-style: none;
  top: 21px;
  left: 10px;
  z-index: 9999;
  border-radius: 0px 5px 5px 0px;
  background: transparent;
  background-color: var(--themeColor);
}

.Footer_otherLink__32TVP {
  color: black;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

.Footer_block1__NO3vh {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Footer_logotextContainer__3OmHh {
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: white; */
  height: 70px;
}

.Footer_logopic__GzFFz {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer_websiteNametext__2BHZ7 {
  margin-left: 10px;
  font-size: 28px;
}

.Footer_websiteDetail__fY4xX {
  margin-top: 10px;
  color: var(--footerText);
  /* color: white; */
  font-size: 15px;
  text-align: justify;
  width: 80%;
  /* font-weight: 600; */
}

.Footer_block2__39Z-e {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
}

.Footer_cont1__2nD6g {
  margin-top: 16px;
  display: flex;
  justify-content: start;
  align-items: center;
  color: var(--footerText);
  font-size: 16px;
  background-color: transparent;
  border: none;
}

.Footer_cont1__2nD6g:hover {
  cursor: pointer;
}

.Footer_cont2__SrsQh {
  margin-top: 16px;
  display: flex;
  justify-content: s;
  align-items: center;
  color: var(--footerText);
  font-size: 16px;
  background-color: transparent;
  border: none;
}

.Footer_cont1Icon__UDIqV {
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 50%;
}

.Footer_cont1Text__2FQCn {
  /* margin-left: 10px; */
  /* color: white; */
  font-size: 16Opx;
  cursor: pointer;
}

.Footer_block3__3c9Fo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Footer_block4__24NQl {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Footer_cont4__noGNV {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Footer_cont4Icon__fw95c {
  height: 20px;
  width: 20px;
  background-color: #185fb8;
  background: linear-gradient(#38adb5, white);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: white; */
  padding: 10px;
}

.Footer_cont4textContainer__3-Ij6 {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  /* color: white; */
}

.Footer_cont4Title1__2rbOG {
  font-size: 14px;
}

.Footer_cont4Title2__OwpL2 {
  margin-top: 5px;
  font-size: 14px;
}

.Footer_horiLine__xAGLX {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 1px;
  border-bottom: 1px solid #70a188;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Footer_bottomBar__byU4Z {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Footer_text1__2woX0 {
  /* color: white; */
}

.Footer_cmpDetail__ECJYf {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer_powerBy__WOLys {
  margin-right: 10px;
  /* color: white; */
}

.Footer_footerLogoBtn__3imWB {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
}

.Footer_footerLogoBtn__3imWB:hover {
  cursor: pointer;
}

.Footer_logo__1moVx {
  height: 35px;
  width: 35px;
  background-color: #185fb8;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  /* color: white; */
  font-size: 18px;
}
.Footer_websiteDetails__3kMsQ {
  margin-left: 10px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  /* color: white; */
  font-weight: 600;
  cursor: pointer;
}

.Home_homeParent__2q6-Q {
  width: auto;
}

.Home_topBar__2oT1w {
  width: 100%;
  height: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home_National__2rtmi {

  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.Home_State__3TGXJ {

  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.Home_newsBlock__2-M5K {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
}

.Home_latest__EKOQ- {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.Home_latestTitle__1xW46 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.Home_latestsubTitle__2m8AL {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.Home_panImageBkg__1K5F2 {
  background-color: #e7efeb;
  background-repeat: no-repeat;
  height: auto;
  background-image: url("https://images.unsplash.com/photo-1523192193543-6e7296d960e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}

.Home_newsBlock__2-M5K{
width: 100%;
}

.Home_stateNewsBlock__2aW-7{
  width: 100%;
}
.Home_belowFooter__2no2j{
  width: 100%;
  height: 180px;
  background-color: #0cc0df;
  margin-top: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.Home_contentTxt__1D2DN{
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-gap: 20px;
  gap: 20px;
  padding-top: 40px;

   
}
.Home_contentTxtTitle__26coF{
  font-size: 19px;
  font-weight: 600;
}

.Home_btnTxtContainer__16El4{
  width: 290px;
  height: 55px;
   
}

.Home_btnTxt__2m-Cu{
  width: 100%;
  background-color: #fff;
  /* color: black; */
  height: 100%;
  border-radius: 5px;
  border-style: none;
  cursor: pointer;
  /* color: white; */
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1300px) {
  .Home_latestTitle__1xW46 {
    font-size: 20px;
  }

  .Home_latestsubTitle__2m8AL {
    margin-top: 10px;
    font-size: 30px;
  }
 
}



@media (max-width: 500px){

.Home_belowFooter__2no2j{
  height: 270px;
}
}
.IntroductionBlock_IntroductionBlockParent__r_PRP {
  border-style: none;
}

.IntroductionBlock_websiteContent__3ZCdY {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 30px;
  column-gap: 30px;
  border-style: none;

  /* border: 1px solid red; */
}

.IntroductionBlock_detailContainer__1mz05 {
  display: grid;
  /* flex-direction: column; */
  /* align-items: flex-end; */
  /* justify-content: start; */
  order: 2;

}

.IntroductionBlock_HomeImageContainer1__2oBpT {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  
}

.IntroductionBlock_HomeImageContainer__1CWfF {
  display: flex;
  justify-content: start;
  align-items: center;
  object-fit: cover;
  border-style: none;
  width: 45%;
  height: 100%;
  margin-right: 100px;
  /* aspect-ratio: 1/1; */
}

.IntroductionBlock_btnContainer__2kYC1 {
  margin-right: 40px;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 20px;
}

.IntroductionBlock_btn1__2mzxe {
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.IntroductionBlock_btn1__2mzxe:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.IntroductionBlock_btn2__1GkLO {
  margin-top: 16px;
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.IntroductionBlock_btn2__1GkLO:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.IntroductionBlock_btn3__3VQXe {
  margin-top: 16px;
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
  white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.IntroductionBlock_btn3__3VQXe:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.IntroductionBlock_btn4__fX6zS {
  font-weight: bold;
  margin-top: 16px;
  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.IntroductionBlock_btn4__fX6zS:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.IntroductionBlock_detailContainer1__1jkgI {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: end; */
  /* position: absolute; */
}

.IntroductionBlock_odisaMap__2O2Dk {
  width: 100%;
}

.IntroductionBlock_textHeading1__2FY2H {
  padding-top: 90px;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 100px;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase; */
  background-color: transparent;
  fill: var(--themeColor);
}

.IntroductionBlock_textHeading2__2LVnd {
  width: 78%;
  /* color: var(--themeColor); */
  color: #544a4d;
  margin-top: 50px;
  /* font-weight: bold; */
  line-height: 55px;
  font-size: 35px;
  padding: 10px;
  border-radius: 10px;
  
  /* text-align: center; */
  /* margin-right: 100px; */
}
.IntroductionBlock_textHeadingTitle__SM2b-{
  /* font-size: 35px; */
  color: #0cc0df;
  font-size: 40px;
  font-weight: 700;
  
}
.IntroductionBlock_textDetails__1fWsO {
  visibility: hidden;
  margin-top: 30px;
  color: var(--darkText);
  font-size: 18px;
  text-align: justify;
  line-height: 34px;
  font-weight: bolder;
  background-color: #e7efeb;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media (max-width: 1300px) {
  .IntroductionBlock_websiteContent__3ZCdY{
    grid-template-rows: 1fr  ;
    /* gap: 100px; */
    /* padding-left: 60px;  */
  }
  
  .IntroductionBlock_IntroductionBlockParent__r_PRP {
    width: 100%;
  }
  .IntroductionBlock_textHeading1__2FY2H {
    font-size: 20;
    height: 80px;
    width: 80px;
  }
  .IntroductionBlock_textHeading2__2LVnd {
    width: 90%;
    /* margin-top: 200px; */
    font-size: 30px;
    line-height: 40px;
    margin-right: 0;
  }
  .IntroductionBlock_detailContainer__1mz05{
    /* margin-left: 80px; */
  }

  .IntroductionBlock_textDetails__1fWsO {
    margin-top: 10px;
    font-size: 18px;
    line-height: 30px;
  }
  .IntroductionBlock_HomeImageContainer1__2oBpT {
    /* margin-top: 200px; */
    /* padding-left: 40px; */
  }

  .IntroductionBlock_btnContainer__2kYC1 {
    width: 100%;
    margin: 0;
  }

  .IntroductionBlock_btn1__2mzxe {
    font-size: 12px;
  }
  .IntroductionBlock_btn2__1GkLO {
    font-size: 12px;
  }
  .IntroductionBlock_btn3__3VQXe {
    font-size: 12px;
  }
  .IntroductionBlock_btn4__fX6zS {
    font-size: 12px;
  }
}

@media (max-width: 700px){
  .IntroductionBlock_HomeImageContainer1__2oBpT {
    /* margin-top: 200px; */
    justify-content: center;
    /* padding-left: 20px; */
    
  }
  .IntroductionBlock_textHeading2__2LVnd {
    width: 90%;
    /* margin-top: 200px; */
    font-size: 30px;
    line-height: 40px;
  }
  .IntroductionBlock_websiteContent__3ZCdY{
    grid-template-rows: 1fr 1fr; 

  }
  .IntroductionBlock_websiteContent__3ZCdY > div:first-child {
    grid-row: 2; 
  }
  .IntroductionBlock_textHeading2__2LVnd{
    margin-top: 0;
  }
  .IntroductionBlock_detailContainer__1mz05{
    height: 30%;
    /* margin-top: 50px; */
    /* margin-left: 0; */
  }
  .IntroductionBlock_detailContainer1__1jkgI{
    justify-content: center;
    align-items: start;
    padding-left: 45px;
    margin-top: 30px;
  }
  .IntroductionBlock_websiteContent__3ZCdY{
    height: 700px;
    grid-gap: 0;
    gap: 0;
  }
  .IntroductionBlock_HomeImageContainer__1CWfF{
    margin-right: 0;
    /* width: 70%; */
    
  }
  
}


@media (max-width: 450px){

  .IntroductionBlock_HomeImageContainer__1CWfF{
    width: 70%;
  }
  .IntroductionBlock_websiteContent__3ZCdY{
    height: 750px;
  }

}
.CenterColumnLeftRightGrid_centerImageLeftRightGrid__3vOgo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CenterColumnLeftRightGrid_title__IJ0TB {
  padding-top: 3px;
  /* color: blue; */
  font-weight: bolder;
  color: #0cc0df;
  font-size: 30px;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  /* text-transform: uppercase; */
  display: flex;
  align-items: end;
}

.CenterColumnLeftRightGrid_subTitle__1mWsx {
  /* margin-top: 20px; */
  /* font-weight: bold; */
  color: #544a4d;
  /* line-height: 55px; */
  font-size: 30px;
  display: flex;
  align-items: end;
}

.CenterColumnLeftRightGrid_titleContainer__1T-tb{
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 55px;
}

.CenterColumnLeftRightGrid_details__bAdbE {
  margin-top: 40px;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  color: var(--lightText);
  text-align: justify;
  margin-bottom: 30px;
  padding-right: 10px;
  padding-left: 10px;

}
.CenterColumnLeftRightGrid_knowMoreBtn__YvDsc{
  color: #0cc0df;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.CenterColumnLeftRightGrid_knowMoreBtnoptions__3t9BA{
  color: #0cc0df;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.CenterColumnLeftRightGrid_serviceSection__1dfh9 {
  margin-top: 20px;
  padding-left: 10px;
  background-color: #eff6ff;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  grid-row-gap: 10px;
  
  row-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
  padding-bottom: 30px;
}

.CenterColumnLeftRightGrid_leftSideContent__2VgLy {
  width: 100%;

}
.CenterColumnLeftRightGrid_justifyTxt__34Jhr{
  text-align: justify;
  padding-right: 10px;
}

.CenterColumnLeftRightGrid_treatment__3117v{
  color: #0cc0df;
  font-size: 30px;
  font-weight: bolder;
}
.CenterColumnLeftRightGrid_treatmentHeading__3sGMV{
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
  color: #544a4d;
  margin-top: 20px;
}
.CenterColumnLeftRightGrid_imagesTextContainer__1QM-p{
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  width: 100%;
}
.CenterColumnLeftRightGrid_imagesContainer__5fyGH{
  width: 45%;
  padding-left: 15px;
}
.CenterColumnLeftRightGrid_txtContainer__1fHqQ{
  width: 50%;
}
.CenterColumnLeftRightGrid_topImg__h6sPK ,.CenterColumnLeftRightGrid_bottomImg__33O5C{
  width: 100%;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  /* flex-direction: column; */
}

.CenterColumnLeftRightGrid_firstImgDiv__2Rrmz ,.CenterColumnLeftRightGrid_secondImgDiv__387aZ ,.CenterColumnLeftRightGrid_thirdImgDiv__3-84X ,.CenterColumnLeftRightGrid_fourthImgDiv__kupfN{
  width: 50%;
}

.CenterColumnLeftRightGrid_HomeImageContainer__3MW7Q{
  width: 100%;
}






.CenterColumnLeftRightGrid_features__1dNIB {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--themeColor);
  font-weight: bold;
}

/* .a1box {
  max-height: 2.4em;
  overflow: hidden;
  transition: max-height 0.3s ease; 
}

.a1box:hover {
  max-height: none; 
} */

.CenterColumnLeftRightGrid_serviceImage__1FLJy {
  height: 60px;
  width: 60px;
  background-color: white;
  visibility: hidden;
}

.CenterColumnLeftRightGrid_a1box__3r_tH {
  height: auto;
  margin: 12px;
  padding: 8px;
  border: 1px solid var(--cardBorderColor);
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  overflow: hidden;
}

.CenterColumnLeftRightGrid_serviceHeading1__3qd08 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  font-weight: bold;
  color: #08203d;
}
.CenterColumnLeftRightGrid_serviceHeading3__3y1kJ{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  font-weight: bold;
  color: #08203d;

}

.CenterColumnLeftRightGrid_serviceHeading2__TdJ_d {
  margin-top: 6px;
  font-size: 14px;
  color: var(--lightText);
  line-height: 23px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  transition: max-height 0.3s ease;

}

.CenterColumnLeftRightGrid_serviceHeading2__TdJ_d:hover {
  height: auto;
  max-height: none;
  cursor: pointer;
}


.CenterColumnLeftRightGrid_a1box__3r_tH:hover .CenterColumnLeftRightGrid_serviceHeading2__TdJ_d{
  -webkit-line-clamp: 8;
  transition: max-height 0.3s ease;
}

.CenterColumnLeftRightGrid_centerImage__I9U9D {
  margin-top: 35px;
  width: 100%;
  height: 620px;
  min-height: 620px;
  max-height: 620px;
  background-color: white;
  object-fit: cover;
  border-style: none;
  border-radius: 10px;
  display: flex;
  margin-bottom: 40px;


}

.CenterColumnLeftRightGrid_rightSideContent__3Wwqw {
  width: 100%;

}
li{
  margin-bottom: 7px;
}
.CenterColumnLeftRightGrid_middleImgContainer__1s2p4{
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
@media (max-width: 1300px) {
  

  .CenterColumnLeftRightGrid_subTitle__1mWsx {
    margin-top: 10px;
    font-size: 30px;
  }
  .CenterColumnLeftRightGrid_a1box__3r_tH {
    width: auto;
    padding: 10px;
    margin-top: 10px;
  }

  .CenterColumnLeftRightGrid_centerImage__I9U9D {
    width: 96%;
    display: flex;
    justify-self: center;
    align-self: center;
    
  }

  .CenterColumnLeftRightGrid_serviceSection__1dfh9 {
    margin-top: 20px;
    width: auto;

    /* display: grid; */
    /* grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); */
    /* grid-auto-rows: auto; */
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 10px;
    column-gap: 10px;
  }
}

@media (max-width: 700px){
  .CenterColumnLeftRightGrid_centerImage__I9U9D{
    margin: 0;
    width: 50%;
  }
  .CenterColumnLeftRightGrid_imagesTextContainer__1QM-p{
    flex-direction: column;

  }
  .CenterColumnLeftRightGrid_imagesContainer__5fyGH ,.CenterColumnLeftRightGrid_txtContainer__1fHqQ{
    width: 98%;
  }
  .CenterColumnLeftRightGrid_imagesContainer__5fyGH{
    padding: 0;
  }
  .CenterColumnLeftRightGrid_txtContainer__1fHqQ{
    padding-left: 7px;
  }
  .CenterColumnLeftRightGrid_serviceSection__1dfh9{
    padding-left: 4px;
  }
}

@media (max-width: 530px){
    .CenterColumnLeftRightGrid_centerImage__I9U9D{
      width: 80%;
    }
}

.Testimonial_ourClientDetails__30Fm7 {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

}

.Testimonial_clientTitle__GbhQa {
  font-weight: bolder;
  color: #0cc0df;
  font-size: 30px;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  /* text-transform: uppercase; */
}
.Testimonial_titleContainer__3LH2X{
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.Testimonial_clientSubTitle__2ZGWY {
  /* margin-top: 20px; */
  font-weight: bolder;
  color: #08203d;
  /* line-height: 55px; */
  font-size: 30px;
}

.Testimonial_clientFeedbackContainer__3JIdY {
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(358px, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 30px;
  column-gap: 30px;


  /* display: flex;
  overflow-x: scroll;
  white-space: nowrap; */
}

.Testimonial_aboutClinetBox__3Jpjh {
  width: auto;
  background-color: white;
  /* margin: 1px; */
  padding: 20px;
  border-radius: 1px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* margin-right: 10px; */
}
.Testimonial_patientNameDiv__3LUzk{
  /* margin-bottom: 10px; */
}

.Testimonial_clientImg__1nZob {
  margin-top: -50px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  object-fit: cover;
}

.Testimonial_clientFeedback__KV150 {
  display: flex;
  justify-content: space-between;
}

.Testimonial_socialNetworkImage__25f4X {
  visibility: hidden;
  height: 30px;
  width: 30px;
  color: #00acee;
}

.Testimonial_clientDetails__1bdJL {
  font-size: 14px;
  margin-top: 10px;
  color: var(--lightText);
  line-height: 24px;
  text-align: justify;
}

.Testimonial_clientName__1W4U5 {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #08203d;
}

.Testimonial_clientStatus__1XuHk {
  margin-top: 10px;
  color: var(--themeColor);
  font-size: 14px;
}

@media (max-width: 500px) {
  
  /* .clientSubTitle {
    margin-top: 10px;
    font-size: 30px;
  } */

  .Testimonial_titleContainer__3LH2X{
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.Pricing_subscriptionDetails__7_aSD {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Pricing_PlanTextTitle__1fGCM {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.Pricing_PlanTextsubTitle__2CVGx {
  margin-top: 20px;
  font-weight: bold;
  color: #08203D;
  line-height: 55px;
  font-size: 45px;
  
}

.Pricing_planContainer__21CgL {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Pricing_basicPlan__19cHP {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
}

.Pricing_basicPlanContent__15tMi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Pricing_pic__1OaKU {
  margin-top: 20px;
  height: 60px;
  width: 60px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  justify-content: center;
  align-items: center;
}


.Pricing_rupees_icon__22ll4{
  height: 30px;
  width: 30px;
  color: var(--lightText);
}

.Pricing_planTitle__2PnLY {
  margin-top: 20px;
  font-size: 25px;
  color: var(--darkText);
  letter-spacing: 5px;
  font-weight: bold;
}

.Pricing_aboutPrice__2XCJj {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  align-items: end;
}

.Pricing_amount__3AJnu {
  font-size: 40px;
  font-weight: bold;
  color: var(--themeColor);
}

.Pricing_amountMonth__1Nndx {
  font-size: 20px;
  display: flex;
  align-items: end;
  font-weight: bold;
  color: var(--lightText);
}

.Pricing_horizontalLine__3kQAT {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  background-color: var(--darkText);
  border-bottom: 1px solid var(--cardBorderColor);
}

.Pricing_features__1ufAA {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.Pricing_checkIcon__1q-Vy {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: gray;
}

.Pricing_textplanDetails__3TTmt {
  margin-left: 10px;
  color: var(--lightText);
}

.Pricing_basicPlanBtn__tk6jM {
  height: 24px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
  color: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-radius: 30px;
  margin-bottom: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid var(--themeColor);
}

.Pricing_standardPlan__FswLz {
  width: 33.33%;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Pricing_unlimitedPlan__2zk0K {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.FAQ_faqContent__2e4kQ {
  margin-top: 40px;
  width: 100%;
  display: grid;
  justify-content: flex-end;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding-bottom: 40px;
  padding-top: 30px;
}

.FAQ_faqdetailContainer__FChNN {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.FAQ_faqImages__3ff5j {
  margin-left: 20px;
  margin-right: 20px;
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio:  calc(2729 / 2674 * 100%);;
  background-color: var(--themeColor);
  border-radius: 10px;
  background-color: red;
  
}

.FAQ_faqtextHeading1__1JEk1 {
  margin-left: 20px;

  display: flex;
  justify-content: start;
  align-items: start;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.FAQ_qaContainer__FDX2L {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  background-color: var(--bgColor);
  border: 1px solid var(--cardBorderColor);
  border-radius: 10px;
}

.FAQ_questionsText__HT8VF {
  margin-left: 10px;
}

.FAQ_questionsAnsContainer__ivyn5 {
  display: flex;
  justify-content: start;
  align-items: center;
}
.FAQ_imgarrowContainer__2vfhC {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--themeColor);
}

.FAQ_ansText__1rlR- {
  margin-left: 20px;

  margin-top: 10px;
  color: var(--lightText);
}

.TeamBlock_ourTeam__2nxBy {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TeamBlock_ourTeamTitle__3C7sd {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.TeamBlock_ourTeamSubTitle__QYOb6 {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.TeamBlock_allTeamMemberdetail__bsYYj {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, auto));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
}

.TeamBlock_ourTeamdetailContainer__1XBgH {
  width: auto;
  margin: 10px;
  padding: 10px;
  border: 1px solid var(--cardBorderColor);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.TeamBlock_developerPic1__io2p3 {
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;

  background-color: #207ef5;
}

.TeamBlock_developerPic2__2rsR5 {
  border-radius: 10px;

  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;

  background-color: #207ef5;
}

.TeamBlock_developerPic3__3-6hM {
  border-radius: 10px;

  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;

  /* aspect-ratio:calc(1600 * 1067 /100%); */
  background-color: #207ef5;
}

.TeamBlock_developerPic4__lk9r2 {
  border-radius: 10px;

  width: 100%;
  aspect-ratio: 5/5;
  object-fit: cover;
  background-color: #207ef5;
}

.TeamBlock_developerName__DMtxC {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 500;
  color: var(--darkText);
}

.TeamBlock_developerRole__3cr5z {
  font-size: 16px;
  margin-top: 10px;
  color: var(--themeColor);
}

.TeamBlock_socialNetworking__1l49J {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.TeamBlock_pic1__2jgcf {
  height: 20px;
  width: 20px;
  background-color: #e3effe;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
  color: var(--themeColor);
  padding: 8px;
  border-style: none;
}

.TeamBlock_pic2__14lyr {
  margin-left: 16px;
  margin-right: 16px;
  height: 20px;
  width: 20px;
  padding: 8px;
  background-color: #e3effe;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
  color: var(--themeColor);
  border-style: none;
}

.TeamBlock_pic3__3kDYd {
  height: 20px;
  width: 20px;
  padding: 8px;
  background-color: #e3effe;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
  color: var(--themeColor);
  border-style: none;
}

@media (max-width: 1300px) {
  .TeamBlock_ourTeamTitle__3C7sd {
    font-size: 20px;
  }

  .TeamBlock_ourTeamSubTitle__QYOb6 {
    margin-top: 10px;
    font-size: 30px;
  }
}

.Apointment_bookAppointment__1VfsX {
  margin-top: 40px;
  display: flex;
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

  background-color: var(--cardBorderColorLight);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Apointment_bookpic__-nNKg {
  width: 100%;
  background-color: white;

  image-resolution: from-image;
  height: 350px;
}

.Apointment_bookDetail__1MLjs {
  width: 100%;
  height: 350px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: start;
}

.Apointment_bookDetailContainer__1HDfb {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: center;
  padding-left: 30px;
}

.Apointment_bookTitle__2g33Y {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.Apointment_booksubTitle__3N40s {
  margin-top: 30px;
  width: 90%;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.Apointment_bookaptBtn__1XWl- {
  margin-top: 30px;
  padding: 10px;
  background-color: var(--themeColor);
  border-radius: 10px;
  color: white;
  border: none;
}
.Apointment_bookaptBtn__1XWl-:hover{
  cursor: pointer;

}

@media (max-width: 1300px) {
  .Apointment_bookTitle__2g33Y {
    margin-top: 10px;
    font-size: 20;
  }

  .Apointment_bookDetailContainer__1HDfb {
    padding-left: 20px;
  }
  .Apointment_booksubTitle__3N40s {
    margin-top: 20px;
    font-size: 24px;
    line-height: 35px;
  }

  .Apointment_textDetails__3-_cH {
    margin-top: 10px;
    font-size: 18px;
  }
}

.BlogAndNewsBlock_latest__3p3dt {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.BlogAndNewsBlock_latestTitle__1uGa3 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.BlogAndNewsBlock_latestsubTitle__2ZxBa {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}
.BlogAndNewsBlock_newsContainer__1Bnil {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, auto));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
}

.BlogAndNewsBlock_singleContainer__3-a5o {
  margin: 10px;
  height: 500px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.BlogAndNewsBlock_newsImage__293Bt {
  width: 100%;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  background-color: white;
}

.BlogAndNewsBlock_newsTitle__1b-16 {
  margin-top: 20px;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #08203d;
}

.BlogAndNewsBlock_newsDesc__29MgQ {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 15px;
  color: var(--lightText);
  text-align: justify;
  margin-right: 10px;
  line-height: 19px;
  height: 180px;
  max-height: 180px;
  min-height: 180px;
}

.BlogAndNewsBlock_readMoreBtn__1bwbl {
  margin-left: 10px;
  font-size: 16px;
  color: var(--lightText);
  margin-bottom: 20px;
  color: var(--themeColor);
  padding: 6px;
  border-radius: 2px;
}

@media (max-width: 1300px) {
  .BlogAndNewsBlock_latestTitle__1uGa3 {
    font-size: 20px;
  }

  .BlogAndNewsBlock_latestsubTitle__2ZxBa {
    margin-top: 10px;
    font-size: 30px;
  }
}


.SingleBlog_singleContainer__xTdk1 {
    margin: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  
  .SingleBlog_newsImage__3uSGB {
    width: 100%;
    height: 200px;
    max-height: 200px;
    min-height: 200px;
    background-color: white;
    object-fit: cover;
  }
  
  .SingleBlog_newsTitle__35Aw3 {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #08203d;
  }
  
  .SingleBlog_newsDesc__1k7mF {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 15px;
    color: var(--lightText);
    text-align: justify;
    margin-right: 10px;
    line-height: 19px;
    height: auto;
    /* max-height: 180px;
    min-height: 180px; */
  }
  
  .SingleBlog_readMoreBtn__1CDjO {
    margin-top: 20px;
    font-weight: bold;
    margin-left: 10px;
    font-size: 16px;
    color: var(--lightText);
    margin-bottom: 20px;
    color: var(--themeColor);
    padding: 6px;
    border-radius: 2px;
    border: none;
    background-color: transparent;
  }
  .SingleBlog_readMoreBtn__1CDjO:hover{
    cursor: pointer;
  }
  
.LeftImageRightText_websiteContent2__2E-_p {
  margin-top: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  column-gap: 20px;
}
.LeftImageRightText_titleBlog__23mvX{
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0cc0df;
  font-size: 24px;
  font-weight: bold;
  /* font-weight: 700; */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.LeftImageRightText_HomeImageContainer2__rLald {
  width: 100%;
  height: 100%;
  /* background-color: green; */
  border-radius: 10px;
  object-fit: cover;

}

.LeftImageRightText_detailContainer2__3YUG- {
  width: 100%;
}

.LeftImageRightText_aboutHeading1__3iREq {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.LeftImageRightText_aboutHeading2__1-hWe {
  /* margin-top: 20px; */
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.LeftImageRightText_aboutDetails__29Jbh {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}


.LeftImageRightText_rightImgLeftTxt__1K1G_{
  width: 100%;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}

.LeftImageRightText_txtDiv__2ijHR{
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  text-align: justify;
}
.LeftImageRightText_imageContainer__1WbZU{
  width: 30%;
  height: 640px;
}

.LeftImageRightText_heading__VQ1jq{
  font-weight: bold;
}

.LeftImageRightText_list__2CIfJ{
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.LeftImageRightText_title__1hBas{
  text-align: center;
  margin-top: 70px;
  color: #0cc0df;
  font-size: 30px;
  font-weight: bolder;
}
@media (max-width: 1300px){
  .LeftImageRightText_rightImgLeftTxt__1K1G_{
    flex-direction: column-reverse;
  }

  .LeftImageRightText_txtDiv__2ijHR{
    width: 96%;
    padding-left: 10px;
    /* padding-right: 10px; */
  }
  .LeftImageRightText_imageContainer__1WbZU{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .LeftImageRightText_HomeImageContainer2__rLald{
    width: 60%;
  }
}

@media (max-width: 1300px) {
  .LeftImageRightText_detailContainer2__3YUG- {
  }
  .LeftImageRightText_aboutHeading1__3iREq {
    font-size: 20;
  }
  .LeftImageRightText_aboutHeading2__1-hWe {
    margin-top: 10px;
    font-size: 30px;
    line-height: 40px;
  }

  .LeftImageRightText_aboutDetails__29Jbh {
    margin-top: 10px;
    font-size: 18px;
    line-height: 30px;
  }
  .LeftImageRightText_websiteContent2__2E-_p{
    /* padding: 15px; */
    
  }
}







@media (max-width: 700px){
  .LeftImageRightText_detailContainer2__3YUG-{
    width: 95%;
    padding-left: 15px;
  }
}


@media (max-width: 600px){
  .LeftImageRightText_HomeImageContainer2__rLald{
    width: 90%;
  }
}

@media (max-width: 600px){
  .LeftImageRightText_HomeImageContainer2__rLald{
    width: 100%;
  }
  .LeftImageRightText_imageContainer__1WbZU{
    width: 100%;
  }
}
.OneNewsBlock_parentNews__1NdPu {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.OneNewsBlock_latest__3ykIR {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.OneNewsBlock_latestTitle__gkyKG {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.OneNewsBlock_latestsubTitle__26aXd {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.OneNewsBlock_newsContainer__2uw0j {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OneNewsBlock_newsBlock1__3_r8q {
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.OneNewsBlock_imgAndHeading__Jc9lx {
  width: 40%;
}

.OneNewsBlock_mainImg__ZZWLH {
  width: 100%;
  height: 120px;
  background-color: aqua;
  border-radius: 10px;
  object-fit: cover;
}

.OneNewsBlock_mainTitle__2rM_B {
  margin-top: 10px;
  color: var(--darkText);
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.OneNewsBlock_contentDetails__3CjEf {
  margin-left: 16px;
  width: 60%;
}

.OneNewsBlock_newsDetails__awXnW {
  text-align: justify;
  line-height: 23px;
  font-size: 15px;
  color: var(--lightText);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
}

.OneNewsBlock_readMore__1hGoi {
  display: flex;
  justify-content: end;
  color: var(--themeColorText);
  margin-top: 10px;
  font-weight: 500;
  border: none;
  background-color: transparent;
}

.OneNewsBlock_readMore__1hGoi:hover {
  cursor: pointer;
}

/* .newsBlock2 {
  width: 100%;
  height: 210px;
  display: flex;
  margin-left: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
} */

@media (max-width: 1300px) {

  .OneNewsBlock_newsContainer__2uw0j{
    width: 96%;
  }


  .OneNewsBlock_newsDetails__awXnW {
    font-size: 14px;
    line-height: 21px;
  }
  .OneNewsBlock_latestTitle__gkyKG {
    margin-top: 10px;
    font-size: 20px;
  }

  .OneNewsBlock_latestsubTitle__26aXd {
    margin-top: 10px;
    font-size: 30px;
  }
}

.FirstBlock_websiteContent2__3RdCZ {
    margin-top: 15px;
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); */
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 20px;
    column-gap: 20px;
  }

  .FirstBlock_blogTitle__1zmkM{
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    color: #0cc0df;
    margin-top: 40px;

  }
  
  .FirstBlock_HomeImageContainer2__3-kOD {
    width: 100%;
    height: 600px;
    background-color: green;
    border-radius: 1px;
    object-fit: cover;
  
  }
  
  .FirstBlock_detailContainer2__3EFFv {
    width: 100%;
  }
  
  .FirstBlock_aboutHeading1__1UmVx {
    font-weight: bold;
    color: var(--themeColor);
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
  }
  
  .FirstBlock_aboutHeading2__1BeoP {
    /* margin-top: 20px; */
    font-weight: bold;
    color: #08203d;
    line-height: 55px;
    font-size: 45px;
  }
  
  .FirstBlock_aboutDetails__x0O7v {
    margin-top: 20px;
    font-size: 18px;
    color: var(--lightText);
    line-height: 32px;
    text-align: justify;
  }
  
  .FirstBlock_imgContainer2__20OnI{
    height: auto;
  }
  .FirstBlock_HomeImageContainer2__3-kOD{
    border-radius: 10px;
  }



  @media (max-width: 1300px) {
    .FirstBlock_detailContainer2__3EFFv {
    }
    .FirstBlock_aboutHeading1__1UmVx {
      font-size: 20;
    }
    .FirstBlock_aboutHeading2__1BeoP {
      margin-top: 10px;
      font-size: 30px;
      line-height: 40px;
    }
  
    .FirstBlock_aboutDetails__x0O7v {
      margin-top: 10px;
      font-size: 18px;
      line-height: 30px;
    }
  }
  @media (max-width: 700px) {
     .FirstBlock_detailContainer2__3EFFv{
      width: 95%;
      padding-left: 15px;
     }
  }



  .FirstBlock_newsBlock__rEfHX {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, auto));
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 25px;
    column-gap: 25px;
  }
  
  
  
  .FirstBlock_National__38HNz {
  
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--lightText);
  }
  
  .FirstBlock_stateNewsBlock__2bVMZ{
    width: 100%;
  }
  
  
  .FirstBlock_State__2WZuH {
  
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--lightText);
  }
  
  
.BlogAndNewsBlock_latest__1k8fC {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  
}

.BlogAndNewsBlock_latestTitle__2elTT {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.BlogAndNewsBlock_latestsubTitle__2Hh_8 {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.BlogAndNewsBlock_newsContainer__D8UAw {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BlogAndNewsBlock_singleContainer__34Qir {
  margin: 10px;
  height: 500px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.BlogAndNewsBlock_newsImage__3fP48 {
  width: 100%;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  background-color: white;
}

.BlogAndNewsBlock_newsTitle__Bz8U2 {
  margin-top: 20px;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #08203d;
}

.BlogAndNewsBlock_newsDesc__2cl5M {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 15px;
  color: var(--lightText);
  text-align: justify;
  margin-right: 10px;
  line-height: 19px;
  height: 180px;
  max-height: 180px;
  min-height: 180px;
}

.BlogAndNewsBlock_readMoreBtn__2OmK7 {
  margin-left: 10px;
  font-size: 16px;
  color: var(--lightText);
  margin-bottom: 20px;
  color: var(--themeColor);
  padding: 6px;
  border-radius: 2px;
}

.News_parentContainer__oaz8b {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px
}

.News_latest__2pe7B {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.News_latestTitle__1jOmD {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.News_latestsubTitle__ziEt_ {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.News_newsContainer__1Lg27 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
}

.News_natitonalNewsBlock__jvYkK {
  width: 100%;
}

.News_stateNewsBlock__1PP1b {
  width: 100%;
}

.News_National__1kFmi {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.News_State__1zXIL {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

@media (max-width: 1300px) {
  .News_latestTitle__1jOmD {
    font-size: 20;
  }
  .News_latestsubTitle__ziEt_ {
    margin-top: 10px;
    font-size: 30px;
  }

  .News_newsContainer__1Lg27 {
    grid-template-columns: repeat(auto-fit, minmax(350px, auto));
  }
}







/* changed css for blog page  */
.News_websiteContent2__1l39R {
  margin-top: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  column-gap: 20px;
}
.News_titleBlog__27RGM{
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0cc0df;
  font-size: 24px;
  font-weight: bold;
  /* font-weight: 700; */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.News_HomeImageContainer2__1NOyq {
  width: 100%;
  height: 670px;
  background-color: green;
  border-radius: 10px;
  object-fit: cover;

}

.News_detailContainer2__oKg2R {
  width: 100%;
}

.News_aboutHeading1__3QfcN {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.News_aboutHeading2__1qHOt {
  /* margin-top: 20px; */
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.News_aboutDetails__1uLE8 {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}

@media (max-width: 1300px) {
  .News_detailContainer2__oKg2R {
  }
  .News_aboutHeading1__3QfcN {
    font-size: 20;
  }
  .News_aboutHeading2__1qHOt {
    margin-top: 10px;
    font-size: 30px;
    line-height: 40px;
  }

  .News_aboutDetails__1uLE8 {
    margin-top: 10px;
    font-size: 18px;
    line-height: 30px;
  }
  .News_websiteContent2__1l39R{
    /* padding: 15px; */
    
  }
}
@media (max-width: 700px){
  .News_detailContainer2__oKg2R{
    width: 95%;
    padding-left: 15px;
  }
}
.SecondBlog_websiteContent2__3oMde {
    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 20px;
    column-gap: 20px;
  }
  
  .SecondBlog_HomeImageContainer2__tG9wD {
    width: 100%;
    height: 600px;
    background-color: green;
    border-radius: 1px;
    object-fit: cover;
  
  }
  
  .SecondBlog_detailContainer2__B8LDS {
    width: 100%;
  }
  
  .SecondBlog_aboutHeading1__2hpo- {
    font-weight: bold;
    color: var(--themeColor);
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
  }
  
  .SecondBlog_aboutHeading2__3AwMH {
    /* margin-top: 20px; */
    font-weight: bold;
    color: #08203d;
    line-height: 55px;
    font-size: 45px;
  }
  
  .SecondBlog_aboutDetails__1zXax {
    margin-top: 20px;
    font-size: 18px;
    color: var(--lightText);
    line-height: 32px;
    text-align: justify;
  }
  
  .SecondBlog_imgContainer2__38e6m{
    height: auto;
  }
  .SecondBlog_HomeImageContainer2__tG9wD{
    border-radius: 10px;
  }



  @media (max-width: 1300px) {
    .SecondBlog_detailContainer2__B8LDS {
    }
    .SecondBlog_aboutHeading1__2hpo- {
      font-size: 20;
    }
    .SecondBlog_aboutHeading2__3AwMH {
      margin-top: 10px;
      font-size: 30px;
      line-height: 40px;
    }
  
    .SecondBlog_aboutDetails__1zXax {
      margin-top: 10px;
      font-size: 18px;
      line-height: 30px;
    }
  }
  @media (max-width: 700px) {
     .SecondBlog_detailContainer2__B8LDS{
      width: 95%;
      padding-left: 15px;
     }
  }
.firstBlog_websiteContent2__pfyRo {
    margin-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 20px;
    column-gap: 20px;
  }
  .firstBlog_titleBlog__3wJOF{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: #0cc0df; */
    font-size: 24px;
    font-weight: bold;
    background-color: #eff6ff;
    margin-top: 40px;
    margin-bottom: 50px;
    
  }
  
  .firstBlog_HomeImageContainer2__2hTSS {
    width: 100%;
    height: 670px;
    background-color: green;
    border-radius: 10px;
    object-fit: cover;
  
  }
  
  .firstBlog_detailContainer2__MLVU_ {
    width: 100%;
  }
  
  .firstBlog_aboutHeading1__2AmOF {
    font-weight: bold;
    color: var(--themeColor);
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
  }
  
  .firstBlog_aboutHeading2__3WeTm {
    /* margin-top: 20px; */
    font-weight: bold;
    color: #08203d;
    line-height: 55px;
    font-size: 45px;
  }
  
  .firstBlog_aboutDetails__1W9VX {
    margin-top: 20px;
    font-size: 18px;
    color: var(--lightText);
    line-height: 32px;
    text-align: justify;
  }
  
  @media (max-width: 1300px) {
    .firstBlog_detailContainer2__MLVU_ {
    }
    .firstBlog_aboutHeading1__2AmOF {
      font-size: 20;
    }
    .firstBlog_aboutHeading2__3WeTm {
      margin-top: 10px;
      font-size: 30px;
      line-height: 40px;
    }
  
    .firstBlog_aboutDetails__1W9VX {
      margin-top: 10px;
      font-size: 18px;
      line-height: 30px;
    }
    .firstBlog_websiteContent2__pfyRo{
      /* padding: 15px; */
      
    }
  }
  @media (max-width: 700px){
    .firstBlog_detailContainer2__MLVU_{
      width: 95%;
      padding-left: 15px;
    }
  }
.ThirsBlog_websiteContent2__pKzBb {
    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 20px;
    column-gap: 20px;
  }
  
  .ThirsBlog_HomeImageContainer2__1NEpH {
    width: 100%;
    height: 500px;
    background-color: green;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .ThirsBlog_detailContainer2__1v5Hy {
    width: 100%;
  }
  
  .ThirsBlog_aboutHeading1__3KHmu {
    font-weight: bold;
    color: var(--themeColor);
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
  }
  
  .ThirsBlog_aboutHeading2__F8vtM {
    /* margin-top: 20px; */
    font-weight: bold;
    color: #08203d;
    line-height: 55px;
    font-size: 45px;
  }
  
  .ThirsBlog_aboutDetails__3KXNH {
    margin-top: 20px;
    font-size: 18px;
    color: var(--lightText);
    line-height: 32px;
    text-align: justify;
  }
  
  @media (max-width: 1300px) {
    .ThirsBlog_detailContainer2__1v5Hy {
    }
    .ThirsBlog_aboutHeading1__3KHmu {
      font-size: 20;
    }
    .ThirsBlog_aboutHeading2__F8vtM {
      margin-top: 10px;
      font-size: 30px;
      line-height: 40px;
    }
  
    .ThirsBlog_aboutDetails__3KXNH {
      margin-top: 10px;
      font-size: 18px;
      line-height: 30px;
    }
  }
  
  
  @media (max-width: 700px){
    .ThirsBlog_detailContainer2__1v5Hy{
      width: 95%;
      padding-left: 15px;
    }
  }
.FourthBlog_websiteContent2__CuYKL {
    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 20px;
    column-gap: 20px;
  }
  
  .FourthBlog_HomeImageContainer2__cReu6 {
    width: 100%;
    height: 680px;
    background-color: green;
    border-radius: 1px;
    object-fit: cover;
  
  }
  
  .FourthBlog_detailContainer2__1lAV- {
    width: 100%;
  }
  
  .FourthBlog_aboutHeading1__lBxan {
    font-weight: bold;
    color: var(--themeColor);
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
  }
  
  .FourthBlog_aboutHeading2__3twwK {
    /* margin-top: 20px; */
    font-weight: bold;
    color: #08203d;
    line-height: 55px;
    font-size: 45px;
  }
  
  .FourthBlog_aboutDetails__R1yQ3 {
    margin-top: 20px;
    font-size: 18px;
    color: var(--lightText);
    line-height: 32px;
    text-align: justify;
  }
  
  .FourthBlog_imgContainer2__q7sXE{
    height: auto;
  }
  .FourthBlog_HomeImageContainer2__cReu6{
    border-radius: 10px;
  }



  @media (max-width: 1300px) {
    .FourthBlog_detailContainer2__1lAV- {
    }
    .FourthBlog_aboutHeading1__lBxan {
      font-size: 20;
    }
    .FourthBlog_aboutHeading2__3twwK {
      margin-top: 10px;
      font-size: 30px;
      line-height: 40px;
    }
  
    .FourthBlog_aboutDetails__R1yQ3 {
      margin-top: 10px;
      font-size: 18px;
      line-height: 30px;
    }
  }
  @media (max-width: 700px) {
     .FourthBlog_detailContainer2__1lAV-{
      width: 95%;
      padding-left: 15px;
     }
  }
.FifthBlog_websiteContent2__3NAqA {
    margin-top: 90px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 20px;
    column-gap: 20px;
  }
  .FifthBlog_titleBlog__3leYY{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: #0cc0df; */
    font-size: 24px;
    font-weight: bold;
    background-color: #eff6ff;
    margin-top: 40px;
    margin-bottom: 50px;
    
  }
  
  .FifthBlog_HomeImageContainer2__Jl4V3 {
    width: 100%;
    height: 670px;
    background-color: green;
    border-radius: 10px;
    object-fit: cover;
  
  }
  
  .FifthBlog_detailContainer2__NnNjG {
    width: 100%;
  }
  
  .FifthBlog_aboutHeading1__1jmi- {
    font-weight: bold;
    color: var(--themeColor);
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
  }
  
  .FifthBlog_aboutHeading2__2KmKD {
    /* margin-top: 20px; */
    font-weight: bold;
    color: #08203d;
    line-height: 55px;
    font-size: 45px;
  }
  
  .FifthBlog_aboutDetails___TcsI {
    margin-top: 20px;
    font-size: 18px;
    color: var(--lightText);
    line-height: 32px;
    text-align: justify;
  }
  
  @media (max-width: 1300px) {
    .FifthBlog_detailContainer2__NnNjG {
    }
    .FifthBlog_aboutHeading1__1jmi- {
      font-size: 20;
    }
    .FifthBlog_aboutHeading2__2KmKD {
      margin-top: 10px;
      font-size: 30px;
      line-height: 40px;
    }
  
    .FifthBlog_aboutDetails___TcsI {
      margin-top: 10px;
      font-size: 18px;
      line-height: 30px;
    }
    .FifthBlog_websiteContent2__3NAqA{
      /* padding: 15px; */
      
    }
  }
  @media (max-width: 700px){
    .FifthBlog_detailContainer2__NnNjG{
      width: 95%;
      padding-left: 15px;
    }
  }
.SecondBlock_websiteContent2__3jXpv {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  column-gap: 20px;
}

.SecondBlock_HomeImageContainer2__1CK4B {
  width: 100%;
  height: 500px;
  background-color: green;
  border-radius: 10px;
  object-fit: cover;
}

.SecondBlock_detailContainer2__1vPvJ {
  width: 100%;
}

.SecondBlock_aboutHeading1__2MPE7 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.SecondBlock_aboutHeading2__1Uyr3 {
  /* margin-top: 20px; */
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.SecondBlock_aboutDetails__IvPCm {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}

@media (max-width: 1300px) {
  .SecondBlock_detailContainer2__1vPvJ {
  }
  .SecondBlock_aboutHeading1__2MPE7 {
    font-size: 20;
  }
  .SecondBlock_aboutHeading2__1Uyr3 {
    margin-top: 10px;
    font-size: 30px;
    line-height: 40px;
  }

  .SecondBlock_aboutDetails__IvPCm {
    margin-top: 10px;
    font-size: 18px;
    line-height: 30px;
  }
}


@media (max-width: 700px){
  .SecondBlock_detailContainer2__1vPvJ{
    width: 95%;
    padding-left: 15px;
  }
}
.About_about__24WKg {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  font-size: 30px;
}

.About_mainTitle__2KZeX {
  width: 100%;
  display: flex;
  height: 100px;
  background-color: #eff6ff;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  /* color: var(--themeColor); */
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* text-transform: uppercase; */
}

.About_topTitle__mcUmp {
  width: 100%;
  margin-top: 30px;
  line-height: 30px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 29px;
  font-weight: 800;
  color: var(--darkText);
  
}

.About_aboutbox__1xHEc {
  margin-top: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.About_aboutbox2__3BPtM {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
  background-color: #eff6ff;
  padding: 20px;
}

.About_leftBox__1z4ge {
  width: 100%;
  /* height: 600px; */
  display: flex;
    justify-content: center;
    align-items: flex-start;
}

.About_OfficeImageContainer__2rzsn {

  border-radius: 10px;
  object-fit: cover;
  width: 100%;
}

.About_container__19BS4{
  position: relative;
  display: inline-block; 
}
.About_OfficeImageContainer2__1ZJHw{
  width: 100%;
  /* height: 99%; */
  /* height: 490px; */
  border-radius: 10px;
  object-fit: cover;
}

.About_RightBox__3DPnl {
  width: 100%;
  padding-top: 50px;
}

.About_p1__384r6 {
  font-size: 16px;
  color: var(--lightText);
  line-height: 1.5;
  text-align: justify;
}

.About_p2__35mqI {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
  line-height: 1.5;
  text-align: justify;
}

.About_p3__3TiKN {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
  line-height: 1.5;
  text-align: justify;
}

.About_getintouchParentDiv__3wna2 {
  visibility: hidden;
  margin-top: 20px;
  border-radius: 6px;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: var(--themeColorText);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--themeColorText);
  background-color: white;
}

.About_getintouchParentDiv__3wna2:hover {
  background-color: var(--themeColorText);
  color: white;
  cursor: pointer;
}

.About_RightBox1__1NH1I {
  width: 100%;
  display: grid;
}
.About_objectiveImgContainer__3noKV{
  width: 50%;
  /* height: 800px; */
}
.About_objective__3f7lc{
  width: 100%;
  height: 100%;
  /* height: 800px; */
  /* aspect-ratio: 1/1; */
  border-radius: 13px;
  object-fit: cover;
}
.About_objectiveContent__2a7tZ{
  width: 70%;
  display: grid;
  grid-gap: 56px;
  gap: 56px;
}
.About_objectiveContentInner__1H8sz{
  /* height: 0%; */
}

.About_leftBox1__1Qgm9 {
  width: 100%;
  height: 100%;
}

.About_whyChooseUsTitle__3FkNr {
  width: 100%;
  margin-top: 100px;

  line-height: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;

  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.About_whyChooseUsDescription__1NAPq {
  width: 100%;
  margin-top: 20px;

  line-height: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: var(--lightText);
}

.About_whyChooseUsContainer__23ryh {
  margin-top: 40px;
  grid-gap: 55px;
  gap: 55px;
  height: 600px;
  display: flex;
  /* grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); */
  /* grid-auto-rows: auto; */
  /* column-gap: 20px; */
  /* row-gap: 20px; */
}

.About_titleObj__S8YvA{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 14px
}

.About_contentObj__3x749{
  font-size: 16px;
  text-align: justify;
}
.About_block1st__1TbdE {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.About_box1__2SY4Z {
  margin-top: 30px;
  background-color: var(--cardBorderColorLight);
  padding: 16px;
  border-radius: 10px;
  height: 200px;
}

.About_boxImg__27XEu {
  margin-top: -30px;
  height: 40px;
  width: 40px;
  padding: 6px;
  background-color: white;
  border: 2px solid var(--cardBorderColorLight);
  border-radius: 10px;
}

.About_boxTitle__33-Cu {
  margin-top: 10px;
  color: var(--darkText);
  font-size: 16px;
  font-weight: bold;
}

.About_boxDesc__3KbER {
  margin-top: 10px;
  color: var(--lightText);
  font-size: 16px;
  line-height: 22px;
}

.About_block2st__GwfGF {
  width: 100%;
}

.About_process__2VpLX {
  margin-top: 40px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.About_processBox1__2vSPF {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.About_processTitle__1gQZj {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.About_processDesc__Bz_C8 {
  width: 100%;
  margin-top: 20px;
  color: var(--lightText);
  line-height: 24px;
  text-align: justify;
}

.About_buttonProcess__1BSQ4 {
  visibility: hidden;
  margin-top: 20px;
  border-radius: 6px;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: var(--themeColorText);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--themeColorText);
  background-color: white;
}

.About_buttonProcess__1BSQ4:hover {
  background-color: var(--themeColorText);
  color: white;
  cursor: pointer;
}

.About_processImage__1EeiX {
  /* background-color: azure; */
  width: 100%;
  height: 690px;
  border-radius: 10px;
  object-fit: cover;
  /* aspect-ratio: calc(387 / 600 * 100%); */
}

.About_processBox2__u6ER6 {
  width: 100%;
}

.About_processCard__2U9mo {
  margin-top: 20px;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
}

.About_cardIcon__25Kkd {
  height: 50px;
  width: 50px;
  max-width: 50px;
  max-height: 50px;
  background-color: transparent;
  border-radius: 20%;
}

.About_processContent__157Vw {
  width: 90%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.About_title__1DaRN {
  font-size: 18px;
  color: var(--darkText);
  font-weight: bold;
}

.About_processDesc__Bz_C8 {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
}

.About_aboutLastSection__1bzOZ {
  width: 100%;
  margin-top: 100px;
  background-color: var(--cardBorderColorLight);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.About_lastSectionTitle1__1U2ra {
  margin-top: 40px;
  font-size: 30px;
  font-weight: bold;
  color: var(--darkText);
}

.About_lastSectionTitle2__1K-mq {
  margin-top: 10px;
  font-size: 30px;
  font-weight: bold;
  color: var(--darkText);
}

.About_lastSectiondesc1__1Hgma {
  width: 50%;
  margin-top: 40px;
  font-size: 16px;
  color: var(--lightText);
  text-align: center;
}

.About_lastSectionBtn__12ivK {
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 6px;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: var(--themeColorText);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid var(--themeColorText);
  background-color: white;
}

.About_lastSectionBtn__12ivK:hover {
  background-color: var(--themeColorText);
  color: white;
  cursor: pointer;
}



@media (max-width: 1300px) {
.About_about__24WKg{
 padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
}
@media (max-width: 1000px){
  .About_objectiveContent__2a7tZ{
    grid-gap: 0;
    gap: 0;
  }
  .About_whyChooseUsContainer__23ryh{
    grid-gap: 10px;
    gap: 10px;
    /* padding: 10px; */
  }
}


@media (max-width: 700px){
  .About_whyChooseUsContainer__23ryh{
    height: auto;
    flex-direction: column;
  }
  .About_objectiveContent__2a7tZ{
    width: 100%;
    grid-gap: 20px;
    gap: 20px;
  }
  .About_contentObj__3x749{
    margin-bottom: 15px;
  }
.About_objectiveImgContainer__3noKV{
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.About_objective__3f7lc{
  object-fit: cover;
  width: 80%;
}





}

@media (max-width: 540px){
  .About_objectiveImgContainer__3noKV{
    width: 100%;
  }
  .About_objective__3f7lc{
    width: 100%;
  }
}
.LeftImageRightText_websiteContent2__3ECgz {
    margin-top: 50px;
    width: 100%;
    display: flex;
  }
  
  .LeftImageRightText_HomeImageContainer2__zFxAw {
    width: 50%;
    background-color: green;
  }
  
  .LeftImageRightText_detailContainer2__3o6yt {
    margin-left: 30px;
    width: 50%;
  }
  
  .LeftImageRightText_aboutHeading1__1a8NZ {
    font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
  }
  
  .LeftImageRightText_aboutHeading2__3JI19 {
    margin-top: 20px;
    font-weight: bold;
    color: #08203D;
    line-height: 55px;
    font-size: 45px;
  }
  
  .LeftImageRightText_aboutDetails__1K4Ec {
    margin-top: 20px;
    font-size: 18px;
    color: var(--lightText);
    line-height: 32px;
    text-align: justify;
  }



.LeftImageRightText_aboutTitle__32kjP{
display: flex;
justify-content:center;
color: var(--themeColor);
font-weight: bold;

}




.ContactUs_contactUsParent__3qW_D {
  width: 100%;
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 10px;
  column-gap: 10px;
}

.ContactUs_content__3gbin {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.ContactUs_heading1__34Z6- {
  color: var(--darkText);
  font-size: 22px;
  font-weight: bold;
  width: 50%;
  line-height: 26px;
}

.ContactUs_headingDetails1__23dXA {
  width: 80%;
  margin-top: 20px;
  font-size: 15px;
  color: var(--lightText);
  line-height: 22px;
  text-align: justify;
}

.ContactUs_heading2__KB0oU {
  margin-top: 20px;
  color: var(--darkText);
  font-size: 16px;
  font-weight: bold;
}

.ContactUs_OfficeImageContainer__3XWAx {
  margin-top: 20px;
  width: 80%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.ContactUs_bottomDetailsContainer__3hkeF {
  margin-top: 20px;
  width: 100%;
  display: flex;
}

.ContactUs_firstContainer__2Vn-N {
  width: 50%;
}

.ContactUs_firstTitle__hUOYC {
  font-size: 16px;
  color: var(--darkText);
  font-weight: 500;
  visibility: hidden;
}

.ContactUs_subFIrstContainer__GhnQ9 {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.ContactUs_sub_FIrstContainer__3v_wm {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.ContactUs_locationIcon__1Wvhz {
  border: 1px solid var(--cardBorderColor);
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  color: var(--lightText);
  display: flex;
  justify-content: start;
  align-items: start;
  background-color: var(--cardBorderColorLight);
  padding: 8px;
  border-radius: 50%;
}

.ContactUs_locationIcon2__342JH {
  border: 1px solid var(--cardBorderColor);
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  color: var(--lightText);
  display: flex;
  justify-content: start;
  align-items: start;
  background-color: var(--cardBorderColorLight);
  padding: 8px;
  border-radius: 50%;
}

.ContactUs_firstAddressText__1eaXK {
  margin-right: 10px;
  margin-left: 6px;
  font-size: 14px;
  color: var(--lightText);
  line-height: 20px;
}

.ContactUs_secContainer__1ZEv8 {
  width: 100%;
}

.ContactUs_form__2s7QD {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  justify-self: center;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-bottom: 30px;
}

.ContactUs_formContainer__3l8Of {
  width: 90%;
}

.ContactUs_headingText__3B6j9 {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.ContactUs_horizontalLine__3mjWp {
  margin-top: 14px;
  margin-bottom: 6px;
  border-bottom: 1px solid var(--cardBorderColor);
}

.ContactUs_topContainer__2Z_df {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ContactUs_firstContainer__2Vn-N {
  width: 50%;
}

.ContactUs_firstNameText__2j3Rq {
  margin-top: 20px;
  color: var(--lightText);
}

.ContactUs_firstNameEditBox__2j1YQ {
  width: 90%;
  margin-right: 5px;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_firstNameEditBox__2j1YQ:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_secContainer__1ZEv8 {
  width: 50%;
}
.ContactUs_secContainer2__jyeTj {
  width: 84%;
  /* visibility: hidden; */
}

.ContactUs_lastNameText__1ZKg6 {
  margin-left: 10px;
  margin-top: 20px;
  color: var(--lightText);
}
.ContactUs_lastNameEditBox__2fNaa {
  width: 90%;
  margin-left: 10px;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_lastNameEditBox__2fNaa:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_emailContainer__1qT0I {
  margin-top: 20px;
  width: auto;
}

.ContactUs_textEmail__1Wdrq {
  margin-top: 10px;
  color: var(--lightText);
}

.ContactUs_emailEditBox__C1Hop {
  width: 97%;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_emailEditBox__C1Hop:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_companyContainer__2Ypal {
  margin-top: 20px;
  width: auto;
}

.ContactUs_textCompany__1P4FE {
  margin-top: 10px;
  color: var(--lightText);
}

.ContactUs_companyEditBox__10gvQ {
  width: 97%;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_companyEditBox__10gvQ:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_phoneContainer__1V9lG {
  margin-top: 20px;
  width: auto;
}

.ContactUs_textphone__3V_0h {
  margin-top: 10px;
  color: var(--lightText);
}

.ContactUs_phoneEditBox__12urW {
  width: 97%;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_phoneEditBox__12urW:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_messageContainer__GP5tj {
  margin-top: 20px;
  width: 97%;
}

.ContactUs_textMessage__rrVKi {
  margin-top: 10px;
  color: var(--lightText);
}

.ContactUs_messageEditBox__2Wuto {
  width: 100%;
  height: 100px;
  text-align: start;
  margin-top: 4px;
  background-color: white;
  padding-top: 0;
  padding-left: 0;
  line-height: 1em;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  justify-content: start;
  align-items: start;
}

.ContactUs_messageEditBox__2Wuto:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_sendBtnContainer__5gmHj {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}

.ContactUs_sendBtn__30XlK {
  margin-top: 40px;
  width: 60%;
  height: 40px;
  background-color: var(--themeColor);
  border-radius: 8px;
  color: white;
  font-size: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ContactUs_sendBtn__30XlK:hover {
  cursor: pointer;
}

@media (max-width: 1300px) {
  .ContactUs_contactUsParent__3qW_D {
    width: 98%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ContactUs_OfficeImageContainer__3XWAx {
    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ContactUs_form__2s7QD {
    margin-right: 10px;

    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.Notices_notices__19fhP {
}

.Notices_searchbar__3iWob {
  margin-top: 100px;
  margin-bottom: 34px;
  width: 700px;
  border: 1px solid var(--cardBorderColor);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Notices_serchIcon__37jII {
  margin-left: 10px;
  color: var(--lightText);
}

.Notices_searchInput__1rPS7 {
  width: 94%;
  margin-right: 5px;
  background-color: white;
  height: 30px;
  font-size: 14px;
  font-size: 18px;
  border: none;
}

@media (max-width: 1300px) {
  .Notices_searchbar__3iWob {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.Notices_mainContainer__2iz2n {
  margin-top: 20px;
  width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  column-gap: 20px;
}

.Notices_leftContainer__PTNWI {
}

.Notices_title__3Zeij {
  font-weight: bold;
  color: black;

  margin-left: 10px;
}

.Notices_topBar__2LoWX {
  width: 100%;
  height: 35px;
  background-color: var(--cardBorderColorLight);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  font-size: 18px;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid var(--cardBorderColor);
}

.Notices_mainImg__1P3Hg {
  width: 100%;
}

.Notices_pic__2h72B {
  width: 100%;
  height: 100%;
}

.Notices_docBox__2Fk33 {
  height: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.Notices_law__2VLnK {
  margin: 10px;
  line-height: 30px;
}

.Notices_uploadDocument__1qovi {
  margin-top: 10px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border: 1px solid var(--themeColor);
  border-radius: 6px;
}

.Notices_rightContainer__3se8q {
}

.Notices_changesContainer__2Eo7C {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Notices_circleContainer__1k7vz {
  width: 130px;
  height: 130px;
  margin-top: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: var(--themeColor);
  border: 2px solid var(--themeColor);
}

.Notices_changesTitle__23nxY {
  font-weight: bolder;
  font-size: 22px;
}

.Notices_arrowIcon__1yZpG {
  margin-top: 10px;
  color: var(--themeColor);
  height: 30px;
  width: 30px;
}

.Notices_mainChangesDetailsContainer__3qGn_ {
  margin-top: 50px;
  width: 45%;
  text-align: justify;
  height: auto;
  padding: 10px;
  line-height: 30px;
  border: 1px solid var(--themeColor);
  border-radius: 10px;
}


@media (max-width: 1300px) {

  .Notices_mainContainer__2iz2n{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Notices_leftContainer__PTNWI{
    width: 97%;
  }

  .Notices_rightContainer__3se8q{
    margin-top: 40px;
    width: 97%;
  }

  .Notices_mainChangesDetailsContainer__3qGn_{
    width: 85%;
  }
}
.OneNotice_oneNotice__dHsoH {
  height: auto;
  margin-top: 16px;
  padding: 20px;
  border-radius: 16px;
  display: flex;
  border-bottom: 1px solid var(--cardBorderColorLight);
}

.OneNotice_noticeBg__2WF_T {
  height: 30px;
  width: 30px;
  max-width: 30px;
  max-width: 30px;
  background-color: var(--cardBorderColorLight);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--cardBorderColor);
}

.OneNotice_noticeNumber__2fkl3 {
  color: var(--darkText);
}

.OneNotice_contentContainer__3gRi1 {
  margin-left: 20px;
  width: 80%;
}

.OneNotice_titleDiv__3v7QQ {
  font-size: 20px;
  font-weight: bold;
  color: var(--darkText);
}

.OneNotice_contentDiv__3x3Xs {
  margin-top: 10px;
  color: var(--lightText);
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  margin-bottom: 16px;
}

@media (max-width: 1300px) {
  .OneNotice_oneNotice__dHsoH {
    padding: 0px;
  }

}
.RulesAndRegulations_rulesAndRegulationsParent__8XfpV {
  margin-top: 40px;

  width: 100%;
}

.RulesAndRegulations_serchContainer__3JJhQ {
  background-color: var(--bgColor);
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
}

.RulesAndRegulations_serchbox__Nxs-_ {
  margin-left: 10px;
  width: 90%;

  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: none;
  border: 1px solid var(--cardBorderColorLight);
}

.RulesAndRegulations_serchbox__Nxs-_:focus {
  border-style: solid;
  background-color: white;
  border-width: 2px;
  outline: none !important;
  border-color: var(--themeColor);
}

.RulesAndRegulations_btnContainer__kI0Oo {
  width: 100px;
  margin-left: 20px;
  height: 28px;
  background-color: var(--themeColor);
  border: none;
  border-radius: 8px;
  color: white;
  margin-right: 10px;
}

.RulesAndRegulations_btnContainer__kI0Oo:hover {
  cursor: pointer;
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
}

.RulesAndRegulations_parentDiv__6Nxsr {
  margin-top: 20px;
  display: flex;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, auto));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  column-gap: 20px;
}

.RulesAndRegulations_leftBox__21Wvb {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.RulesAndRegulations_topHeadingSection__14s-5 {
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: var(--bgColor);
  border-bottom: 1px solid var(--cardBorderColor);
}

.RulesAndRegulations_iconContainer__3fvz7 {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
}

.RulesAndRegulations_title__3qa3m {
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
}

.RulesAndRegulations_rightBoxContainer__2oGyG {
}

.RulesAndRegulations_topBox__1VhpO {
  width: 100%;

  border: 1px solid var(--cardBorderColor);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.RulesAndRegulations_midBox__3vpAo {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.RulesAndRegulations_bottomBox__3MDOo {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.RulesAndRegulations_mainContainer__uzdSL {
}

.RulesAndRegulations_mainIcon__XzSt4 {
}

.RulesAndRegulations_noticeIcon__3YUgE {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RulesAndRegulations_regIcon__1xC3z {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.RulesAndRegulations_ruleIcon__3pETA {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RulesAndRegulations_actIcon__Lrgvd {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RulesAndRegulations_rulesAndRegulationsParent__8XfpV {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  column-gap: 20px;
}

.RulesAndRegulations_reraData__3sk8x {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  column-gap: 20px;
}

.RulesAndRegulations_Block1__DUKCf {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.RulesAndRegulations_Title__3_m9y {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--themeColor);
  background-color: var(--themeColor);
  font-weight: bold;
  color: white;
}

.RulesAndRegulations_mainContainer__uzdSL {
  padding-top: 10px;
  display: flex;
  padding-right: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--cardBorderColorLight);
}

.RulesAndRegulations_mainContainer__uzdSL:hover {
  background-color: var(--bgColor);
  cursor: pointer;
}

.RulesAndRegulations_nu__UOUaE {
  display: flex;
  justify-content: center;
  width: 15%;
  font-size: 14px;
}

.RulesAndRegulations_details__2kxDl {
  width: 85%;
  font-size: 14px;
  line-height: 24px;
}

.RulesAndRegulations_Block2__3XsBN {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.RulesAndRegulations_Block3__-tx5W {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.RulesAndRegulations_Block4__1yBk2 {
  height: 500px;
  border: 1px solid var(--themeColor);
}


.RulesAndRegulations_mainInnerDiv__2GnLd{
  width: 100%;
  margin-bottom: 30px;
   margin-top: 40px;
   
}
.RulesAndRegulations_mainSecndInnerDiv__282DV{
  width: 100%;
  display: flex;
  min-height: 700px;
  height: auto;
  grid-gap: 30px;
  gap: 30px;
  background-color: #eff6ff;
  /* padding: 15px; */
  /* padding-right: 20px; */
  padding-top: 0;
  padding-bottom: 0;
  
  /* grid-template-columns: 0.8fr 1fr;  */
  /* gap: 20px; */
}

.RulesAndRegulations_mainThirdDiv__2Qp97{
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;
  grid-gap: 10px;
  gap: 10px;
}
.RulesAndRegulations_mainFourthDiv__G7eo3{
  height: auto;
}
.RulesAndRegulations_bottomTxt__1ImkZ{
  height: 100px;
  background-color: #eff6ff;
  display: flex ;
  flex-direction: column;
  font-size: 21px;
  font-weight: 800;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.RulesAndRegulations_titleBottom__2ETOH{
  font-size: 22px;
  margin-bottom: 20px;
}
.RulesAndRegulations_bottomBtn__w7xes{
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: auto;
  margin-top: 10px;
  /* margin-bottom: 10px; */
  font-weight: bold;
}
.RulesAndRegulations_btnSchedule__9POes{
  height: 70px;
  border-style: none;
  color: white;
  width: 350px;
  border-radius: 10px;
  background-color: #0cc0df;
  font-size: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.RulesAndRegulations_btnSchedule__9POes:hover{
  background-color: #fff;
  border:1px solid #0cc0df;
  color: #0cc0df;
}
.RulesAndRegulations_titleBottomTxt__1r39H{
  font-size: 16px;
  font-weight: 500;
}

.RulesAndRegulations_firstDiv__1Vqie ,.RulesAndRegulations_secondDiv__aCXMR ,.RulesAndRegulations_thirdDiv__2PFbH ,.RulesAndRegulations_fourthDiv__2CWk8{
  /* height: 30%; */
  text-align: justify;
  display: grid;
  width: 96%;
  

  
}

.RulesAndRegulations_titleTxt__3zyn_{
  font-size: 20px;
  font-weight: bolder;
}

.RulesAndRegulations_rightDiv__1iBV1{
  width: 70%;
  display: grid;
 
}


.RulesAndRegulations_titleDiv__3hnt-{
  
  height: 100px;
  background-color: #eff6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold
}
.RulesAndRegulations_contentDiv__2jXF5{
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-gap: 20px;
  gap: 20px;
}
.RulesAndRegulations_contentDivText__2oMD4{
  font-size: 19px;
  font-weight: 900;
}
.RulesAndRegulations_contentDivTextBottom__1Onfq{
  font-size: 18px;
  font-weight: 500;
}

.RulesAndRegulations_surgeryImgContainer__2L1DZ{
  width: 40%;
  /* height: 100%; */
}
.RulesAndRegulations_surgeryImg__3YyxD{
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 13px; */
}
.RulesAndRegulations_titleTxt1__33t3u{
  font-size: 21px;
  font-weight: 600;
}
@media (max-width: 1300px) {
  .RulesAndRegulations_rightDiv__1iBV1{
    width: 60%;
  }
  .RulesAndRegulations_surgeryImgContainer__2L1DZ{
    margin-left: 10px;
  }

  .RulesAndRegulations_reraData__3sk8x{

    width: 100%;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  row-gap: 20px;
  column-gap: 20px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

  .RulesAndRegulations_Block1__DUKCf{
width: 94%;

  }

  .RulesAndRegulations_Block2__3XsBN{
    width: 94%;

  }

  .RulesAndRegulations_Block3__-tx5W{
    width: 94%;

  }

  .RulesAndRegulations_Block4__1yBk2{
    width: 94%;

  }
  .RulesAndRegulations_bottomBtn__w7xes{
    /* margin-top: 30px; */
    /* margin-bottom: 30px; */
  }
}


.RulesAndRegulations_inconstructionMessage__3kfOw{
margin-top: 40px;
color: red;
font-size: 25px;
border-style: solid;
padding: 20px;
border-radius: 10px;
}

@media (max-width: 1000px){
  .RulesAndRegulations_surgeryImgContainer5__3KbDl{
    width: 50%;
  }
  .RulesAndRegulations_bottomTxt__1ImkZ{
    padding-right: 20px;
    padding-left: 20px;
  }
}


@media (max-width: 700px){
  .RulesAndRegulations_mainSecndInnerDiv__282DV{
    flex-direction: column;
  }
  .RulesAndRegulations_surgeryImgContainer__2L1DZ{
    width: 100%;
    margin: 0;
  }
  .RulesAndRegulations_rightDiv__1iBV1{
    width: 97%;
    padding-left: 15px;
  }
  .RulesAndRegulations_contentDiv__2jXF5{
    height: auto;
    margin-bottom: 23px;
    margin-top: 30px;padding: 5px;
  }
  .RulesAndRegulations_mainThirdDiv__2Qp97{
    height: auto;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .RulesAndRegulations_bottomTxt__1ImkZ{
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .RulesAndRegulations_fourthDiv__2CWk8{
    margin-bottom: 20px;
  }

  .RulesAndRegulations_bottomBtn__w7xes{
    /* height: 200px; */
    /* margin-top: 20px; */
    /* margin-bottom: 20px; */
  }
  .RulesAndRegulations_btnSchedule__9POes{
    /* height: 20%; */
    /* margin-bottom: 20px; */
  }
}







.SingleReraAct_parent__15Xg7 {
  align-items: center;
  border-bottom: 1px solid var(--cardBorderColor);
}
.SingleReraAct_parent__15Xg7:hover{
  background-color: var(--cardBorderColorLight);
}
.SingleReraAct_parent__15Xg7:hover .SingleReraAct_downloadBtnContainer__2miX-{
  background-color: white;
}

.SingleReraAct_detailsContainer__24HyT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.SingleReraAct_rightSide__eLZjm {
  display: flex;
  justify-content: start;
  align-items: center;
}

.SingleReraAct_nu__3_3HK {
  width: 20px;
  font-size: 16px;
  font-weight: bold;
}

.SingleReraAct_title__2T6A1 {
  margin-left: 20px;
  width: 80%;
  color: var(--darkText);
  font-weight: bold;
}
.SingleReraAct_details__2vgyy {
  width: 50%;
  color: var(--lightText);
  line-height: 28px;
}

.SingleReraAct_downloadBtnContainer__2miX- {
  border: 1px solid var(--cardBorderColor);
  background-color: var(--cardBorderColorLight);
  width: 30px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SingleReraAct_downloadBtnContainer__2miX- :hover{
 cursor: pointer;
}

.SingleReraAct_downloadIcon__2ZSOI {
  height: 20px;
  width: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}


.ActSingleBlock_actButton___al-f {
  height: 170px;
  background-color: white;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.ActSingleBlock_actButton___al-f:hover {
  border: 1px solid var(--themeColor);
  background-color: var(--lightThemeColor1);
  cursor: pointer;
}

.ActSingleBlock_mainImg__2KA61 {
  visibility: hidden;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.ActSingleBlock_title__3yopt {
  margin: 10px;
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
}

.ActSingleBlock_des__17Q6E {
  font-size: 14px;
  margin: 10px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  text-align: center;
  -webkit-box-orient: vertical;
}

.OneProject_mainContainer__3MFZw {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 20px;
}

.OneProject_oneProject__13_GJ {
  margin: 16px;
}

.OneProject_imageProject__3SzZH {
  width: 100%;
  height: 300px;
  border-radius: 20px;
}

.OneProject_projectName__psoaG {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.OneProject_discription__1_RHt {
  margin-top: 10px;
  font-size: 16px;
  line-height: 22px;
  color: var(--lightText);
}

.OneProject_locationContainer__3ScqM {
  margin-top: 10px;

  display: flex;
}

.OneProject_locationTitle__2myoZ {
  color: var(--lightText);
  width: 100px;
}

.OneProject_location__1Whz0 {
  color: var(--darkText);
  font-weight: bold;
}

.OneProject_launchDateContainer__3qFYJ {
  margin-top: 10px;

  display: flex;
}

.OneProject_launchTitle__3zLEm {
  color: var(--lightText);
  width: 100px;
}

.OneProject_launchDate__21Mdl {
  font-weight: bold;
}

.OneProject_statusContainer__3Gk1O {
  margin-top: 10px;

  display: flex;
}

.OneProject_statusTitle__3KGyt {
  color: var(--lightText);
  width: 100px;
}

.OneProject_status__1UID0 {
  font-weight: bold;
  color: var(--darkText);
}

.OneProject_reraContainer__3qDIx {
  margin-top: 10px;

  display: flex;
}

.OneProject_rereTitle__IKtEn {
  color: var(--lightText);
  width: 100px;
}

.OneProject_rera__1WMx0 {
  font-weight: bold;
  color: var(--darkText);
}

.OneProject_bottomContnet__3uryA {
  margin-top: 10px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.OneProject_noOfUnitsContainer__VyEwi {
  display: flex;
}

.OneProject_noofUnitsTitle__3Hscu {
  color: var(--lightText);
  width: 100px;
}

.OneProject_no_fu_units__2PwNj {
  font-weight: bold;
  color: var(--darkText);
}

.OneProject_btnRedirect__2UYQZ {
  color: blue;
  background-color: transparent;
  border: none;
  font-size: 16px;
  padding-right: 10px;
}

.OneProject_btnRedirect__2UYQZ:hover {
  cursor: pointer;
}

.Projects_projectMain__10ciX{

    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    column-gap: 20px;
}


@media (max-width: 1300px) {
 
    .Projects_projectMain__10ciX{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

    }
  }
.ReraAct_rulesAndRegulationsParent__cqwZu {
  margin-top: 40px;

  width: 100%;
}

.ReraAct_serchContainer__2cFoy {
  background-color: var(--bgColor);
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_serchbox__2lIF9 {
  margin-left: 10px;
  width: 90%;

  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: none;
  border: 1px solid var(--cardBorderColorLight);
}

.ReraAct_serchbox__2lIF9:focus {
  border-style: solid;
  background-color: white;
  border-width: 2px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ReraAct_btnContainer__3cKy0 {
  width: 100px;
  margin-left: 20px;
  height: 28px;
  background-color: var(--themeColor);
  border: none;
  border-radius: 8px;
  color: white;
  margin-right: 10px;
}

.ReraAct_btnContainer__3cKy0:hover {
  cursor: pointer;
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
}

.ReraAct_parentDiv__2pD1M {
  margin-top: 20px;
  display: flex;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(480px, auto));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  column-gap: 20px;
}

.ReraAct_leftBox__1wVcz {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_topHeadingSection__1fFO0 {
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: var(--bgColor);
  border-bottom: 1px solid var(--cardBorderColor);
}

.ReraAct_iconContainer__2f9NP {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_title__1CeZP {
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
}

.ReraAct_rightBoxContainer__1pXKW {
}

.ReraAct_topBox__1eAYk {
  width: 100%;

  border: 1px solid var(--cardBorderColor);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.ReraAct_midBox__1YZw5 {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_bottomBox__1kBa5 {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_mainContainer__2keBT {
}

.ReraAct_mainIcon__2tTOF {
}

.ReraAct_noticeIcon__1iQRK {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReraAct_regIcon__3269M {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReraAct_ruleIcon__GqxHL {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReraAct_actIcon__RRUQh {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

